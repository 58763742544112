import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { HttpService } from './http.service';

// Models
import { ComponentAircraft } from './../models/class/component';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  constructor(
    private _httpService: HttpService
  ) { }


// Funciones Propias

  getAllComponents(): Observable<ComponentAircraft[]>{
    let url: string = 'Components?filter={"include":["systemType", "ataType"]}';
    return this._httpService.httpGet(url);
  }

  getAllComponentsBySystemAndAtaType(systemTypeID?:number,ataTypeID?:number): Observable<ComponentAircraft[]>{
    var filter=``;
    let url = `Components?filter=`;
    if (systemTypeID!=0 && ataTypeID!=0)
    filter=`{"where":{"systemTypeID": ${systemTypeID} , "ataTypeID": ${ataTypeID} }}`;
    else if(systemTypeID!=0 && ataTypeID==0)
    filter=`{"where":{"systemTypeID": ${systemTypeID}}}`;
    else if(systemTypeID==0 && ataTypeID!=0)
    filter=`{"where":{"ataTypeID": ${ataTypeID}  }}`;


    return this._httpService.httpGet(url+filter);
  }


  saveComponent(data): Observable<ComponentAircraft>{
    let url: string = 'Components/saveComponent';
    return this._httpService.httpPost(url, data);
  }

  updateComponent(id, data): Observable<ComponentAircraft>{
    let url: string = `Components/${id}`;
    return this._httpService.httpPatch(url, data);
  }


}
