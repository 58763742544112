<div class="content-list-select">
  <input type="text" class="input-filter-pernota" placeholder="Pernota" [(ngModel)]="textFilterPernota"
    (keyup)="filterListPernota()">
  <div class="content-list-hover scroll">


    <span class="title-filter" *ngIf="listShowDepartaments.length > 0"> <i class="fas fa-building icon-filter"></i>
      Ciudades</span>
    <div class="center select__item" *ngFor="let departament of listShowDepartaments; index as i"
      (click)="selectItemPernota(null, departament)">{{departament.regionName}}</div>

    <span class="title-filter mt-4" *ngIf="listShowAirposts.length > 0"> <i
        class="fas fa-plane-departure icon-filter"></i> Aeropuertos</span>
    <div class="center select__item" *ngFor="let airport of listShowAirposts; index as i"
      (click)="selectItemPernota(airport, null)">{{airport.nameAirport}}</div>

  </div>
</div>
<!-- <a class="center btn-select" (click)="changeStateModalPernota()">SELECCIONAR</a> -->

<!-- 
  <div class="content-modal">
    <div class="subcontent-modal">
      <div class="header-modal">
        <i class="fa fa-times ml-2 icon-close-modal" (click)="changeStateModalPernota()"></i>
        <img src="https://storage.googleapis.com/f2rt-assets/Iconos/Logo_F2RT-negro.svg" class="logo-f2rt">
        <a class="center text-header">Ubicación</a>
      </div>
      <div class="body-modal">
        <div class="content-list-select">
          <input type="text" class="input-filter-pernota" placeholder="Pernota" [(ngModel)]="textFilterPernota" (keyup)="filterListPernota()">
          <div class="content-list-hover scroll">
            <span class="title-filter" *ngIf="listShowDepartaments.length > 0"> <i class="fas fa-building icon-filter"></i>  Ciudades</span>
            <div class="center select__item" *ngFor="let departament of listShowDepartaments; index as i" (click)="selectItemPernota(null, departament)">{{departament.regionName}}</div>
            <span class="title-filter mt-4" *ngIf="listShowAirposts.length > 0"> <i class="fas fa-plane-departure icon-filter"></i> Aeropuertos</span>
            <div class="center select__item" *ngFor="let airport of listShowAirposts; index as i" (click)="selectItemPernota(airport, null)">{{airport.nameAirport}}</div>
          </div>
        </div>
        <a class="center btn-select" (click)="changeStateModalPernota()">SELECCIONAR</a>
      </div>
    </div>
  </div> -->