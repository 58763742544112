<div class="cardContent" [ngClass]="{'cardContent--lateralView': horizontalView}">
  <div class="cardContent--newWidh">
    <app-control-flight-hours [thisMachine]="machineItem"></app-control-flight-hours>
  </div>
	<div class="cardContent--newWidh">
    <app-work-order [thisMaintHistory]="machineItem?.maintHistories"></app-work-order>
  </div>
	<div class="cardContent--newWidh" [ngClass]="{'cardContent--verticalView': !horizontalView}">
    <div class="content-container__item">
      <div class="tabs-container">
        <div class="tabs-container__principal-tabs">
          <div class="principal-tab" [ngStyle]="{'color': infoTab ? '#0B1141' : '#ffffff'}" (click)="changeInfoTab(true)">
            <div class="principal-tab__icon-container">
              <div class="icon if2rt-description icon-tab" [ngStyle]="{'color': infoTab ? '#80BA27' : '#ffffff'}"></div>
            </div>
            <div class="principal-tab__title-tab">SERVICIOS</div>
          </div>
          <div class="principal-tab" [ngStyle]="{'color': !infoTab ? '#0B1141' : '#ffffff'}" (click)="changeInfoTab(false)">
            <div class="principal-tab__icon-container">
              <div class="icon if2rt-modal-alert icon-tab" [ngStyle]="{'color': !infoTab ? '#80BA27' : '#ffffff'}"></div>
            </div>
            <div class="principal-tab__title-tab">ANOTACIONES</div>
          </div>
        </div>
        <div class="tabs-container__primary-tab-display">
          <div *ngIf="infoTab; else imprevistosTab">
            <div class="tabs-container__secundary-tabs">
              <div class="secundary-tab" *ngFor="let tab of serviceTabs" (click)="changeServiceTab(tab.id)"
                [ngStyle]="{'color': serviceTab == tab.id ? '#0B1141' : '#5D5D5D'}">
                {{tab.name | uppercase}}
                <div class="tooltip-tab">
                  <div class="tooltip-tab__tail tooltip-tab__tail--secundary-tabs"></div>
                  <div class="tooltip-tab__text tooltip-tab__text--secundary-tabs">
                    {{tab.descript}}
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-container__secundary-tab-display">
              <table class="table tab-table-display">
                <thead class="tab-table-display__header">
                  <tr>
                    <th scope="col" colspan="3"></th>
                    <th scope="col">CUMPLIDAS</th>
                    <th scope="col">VENCE</th>
                  </tr>
                </thead>
                <tbody *ngIf=" filterServicesByServiceTypeID(serviceTab).length > 0; else noServices">
                  <tr *ngFor="let maintHistory of filterServicesByServiceTypeID(serviceTab) index as i"> 
                    <ng-container>
                      <td class="text" style="text-align: initial;" colspan="3">{{maintHistory.maintCmpActivity.maintCmpActName}}</td>
                      <td class="text">{{ maintHistory.hoursUseStart | f2rtHour: 'decimals' }}</td>
                      <td class="text">{{ maintHistory.hoursUseNext | f2rtHour: 'decimals' }}</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
              <div class="imprevisto__empty" *ngIf="filterServicesByServiceTypeID(serviceTab).length <= 0">No se encontraron servicios</div>
            </div>
          </div>
          <ng-template #imprevistosTab>
            <div class="imprevisto__item"
              *ngFor="let failReport of machineItem.maintHistories[0]?.failReports; index as i">
              <div class="imprevisto__separator" *ngIf="i != 0"></div>
              <div class="imprevisto__text">{{failReport.descript}}</div>
            </div>
            <div class="imprevisto__empty" *ngIf="machineItem.maintHistories[0]?.failReports.length == 0">No se
              encontraron anotaciones</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
	<div class="cardContent--newWidh" [ngClass]="{'cardContent--verticalView': !horizontalView}">
    <app-completion-percentage-average [WorkPackage]="machineItem.WorkPackages[0]"></app-completion-percentage-average>
  </div>
	<div class="cardContent--newWidh" [ngClass]="{'cardContent--verticalView': !horizontalView}">
		<app-status-annotations [thisUseHistoryAugmented]="machineItem.useHistoryAugmenteds[0]" [changeStyleTableView]="horizontalView" [changeStyle]="true"></app-status-annotations>
  </div>
</div>
