<div class="hrsMnto">
  <div class="hrsMnto__tittle">
    <i class="if2rt-clock"></i>
    <span>CONTROL ASIGNACIÓN DE HORAS</span>
  </div>
  <div class="hrsMnto__content">
    <div class="hrsMnto__content__item">
      <div class="center">{{ this.thisMachine.hoursAsignedMonth | f2rtHour: 'decimals' }}</div>
      <div class="hrsMnto__content__item__tittle">Hrs. asignadas</div>
    </div>
    <div class="hrsMnto__content__item">
      <div class="center">{{  this.thisMachine.hoursFlewMonth | f2rtHour: 'decimals'}}</div>
      <div class="hrsMnto__content__item__tittle">Hrs. voladas</div>
    </div>
    <div class="hrsMnto__content__item">
      <div class="center">{{ (this.thisMachine.hoursAsignedMonth - this.thisMachine.hoursFlewMonth) | f2rtHour: 'decimals'}}</div>
      <div class="hrsMnto__content__item__tittle">Hrs. disponibles</div>
    </div>
  </div>
  <div class="hrsMnto__hrsFlying">
    <span>HORAS VOLADAS HOY</span>
    <span>{{ this.thisMachine.hoursDays | f2rtHour: 'decimals' }}</span>
  </div>
</div>