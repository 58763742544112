import { ManualRev } from './manualRev';

export class Manual {

	/*1*/ 	public id: number;
	/*2*/ 	public manualName: string;
	/*3*/ 	public manualRef: string;
	/*4*/ 	public manualDescript: string;
	/*5*/ 	public manualLink: string;
	/*6*/ 	public roleMachineID: number;
	/*7*/ 	public fleetID: number;

	/*8*/ 	public selectedAmount: number;
	/*9*/ 	public resourceID: number;
	/*10*/ 	public edit: boolean;

	/*11*/  public manualRevs?: ManualRev[];

	constructor(m ?: any) {
		this.id = m ? m.id : null;
		this.manualName = m ? m.manualName : null;
		this.manualRef = m ? m.manualRef : null;
		this.manualDescript = m ? m.manualDescript : null;
		this.manualLink = m ? m.manualLink : null;
		this.roleMachineID = m ? m.roleMachineID : null;
		this.fleetID = m ? m.fleetID : null;

		this.selectedAmount = 1;
		this.resourceID = 0;
	}

}
