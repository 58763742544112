import { SubUnit } from './subUnit';
// import { Point } from '@angular/cdk/drag-drop/typings/drag-ref';
import { Formation } from './formation';
import { TypeUnit } from './type-unit';
import { UnitSubUnit } from './unit-sub-unit';

export class Unit {

	/*1*/ 	public id: number;
	/*2*/ 	public unitName: string;
	/*3*/ 	public unitAcronim: string;
	/*4*/ 	public formationID: number;
	/*5*/	//public subUnits: Array<SubUnit>;
	/*6*/	public geopoint?: { lat: number, lng: number };
	/*7*/	public kml?: string;
	/*8*/   public formation?: Formation;
	/*9*/   public isMaintenance?: boolean;
	/*10*/  public urlDailyReport?: string;

	public totalmachines?:number;
	public totalHours?:number;
	public totalmachines2?:number;
	public totalHours2?:number;
	public CurrentHours?:number;

	public typeunitid?: number 
	public typeunit?: TypeUnit 
	public filename?: string
	public unitIco?: string

	public unitsubunit?: UnitSubUnit[] = []

	constructor(u?: any) {
		this.id = u ? u.id : null;
		this.unitName = u ? u.unitName : null;
		this.unitAcronim = u ? u.unitAcronim : null;
		this.formationID = u ? u.formationID : null;
		//this.subUnits = u ? new Array<SubUnit>(u.SubUnit) : null;
		this.geopoint = u ? u.geopoint : null;
		this.kml = u ? u.kml : null;
		this.urlDailyReport = u ? u.urlDailyReport : null;
		this.isMaintenance = u ? u.isMaintenance : false;
		this.typeunit = u ? u.typeunit : null;
		this.typeunitid = u ? u.typeunitid : null;
		this.filename = u ? u.filename : null;
		this.unitIco = u ? u.unitIco : null;
	}

}
