<ng-container *ngIf="!viewFullInfo">
  <div class="machine-info-container" [ngClass]="{'machine-info-container-middle': modifyWitdh}">
    <div class="card-machine" [ngClass]="{'card-machine--horizontalView': horizontalView}">
      <app-card-hearder 
        [thisMachine]="data"
        [hideData]="hideData"
        [viewOnlyHeaders]="viewOnlyHeaders" 
        [typeView]="typeView" 
        (statusModalMaintenance)="activateModalMaintenance($event)"
        (showUseHistoryData)="showHideHistiy($event)"
        (showInfoCards)="showHideCards($event)"
      ></app-card-hearder>
      <ng-container *ngIf="!hideData">
        <div class="card-machine__card-container" >
          <div class="card-machine__card-info" *ngIf="!viewOnlyHeaders" [ngClass]="{'card-machine__card-info--scroll': scrollCard}">
            <app-green-card-machine  [data]="data" [horizontalView]="horizontalView" *ngIf="data.useHistoryAugmenteds[0].typeState.colorGroup == '1'"></app-green-card-machine>
            <app-blue-card-machine   [data]="data" [horizontalView]="horizontalView" *ngIf="data.useHistoryAugmenteds[0].typeState.colorGroup == '2'"></app-blue-card-machine>
            <app-yellow-card-machine [data]="data" [horizontalView]="horizontalView" *ngIf="data.useHistoryAugmenteds[0].typeState.colorGroup == '3'"></app-yellow-card-machine>
            <app-red-card-machine    [data]="data" [horizontalView]="horizontalView" *ngIf="data.useHistoryAugmenteds[0].typeState.colorGroup == '4'"></app-red-card-machine>
          </div>
        </div>
      </ng-container>
    </div>
    
    <!-- Historicos -->
    <div class="historyCard" *ngIf="showHistory">
      <app-hour-use-history [thisMachine]="data"></app-hour-use-history>
    </div>
  </div>
</ng-container>

<!-- Mantenimiento -->
<ng-container *ngIf="viewFullInfo">
  <app-full-info-machine [data]="data" (statusModalMaintenance)="activateModalMaintenance($event)"></app-full-info-machine>
</ng-container>
