import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin, from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { SharedService } from './../../../services/shared.service';
import { ServiceService } from './../../../services/service.service';

// Models
import { Service } from './../../../models/class/service';
import { MaintResource } from '../../../models/class/maintResource';
import { MaintTask } from './../../../models/class/maintTask';
import { Manual } from './../../../models/class/manual';
import { StaffSkill } from './../../../models/class/staffSkill';
import { Disposal } from './../../../models/class/disposal';
import { Infraestructure } from './../../../models/class/infrastructure';
import { ToolType } from './../../../models/class/toolType';
import { SparePart } from './../../../models/class/sparePart';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.sass']
})
export class ResumeComponent implements OnInit {

  public urlHttp: string = environment.baseUrl // environment.production ==0 ? environment.baseUrlProduction : environment.production ==1 ? environment.baseUrlTest : environment.baseUrlLocalhost;

  @Input() idService: number;
  @Input() numberItem: number;
  @Input() colorItem: number;
  @Input() onlyRead: boolean;
  public service: Service;

  public listResourceService: MaintResource[] = [];
  public listMaintTask: MaintTask[] = [];

  public loaderData: boolean = true;
  public loaderDataResources: boolean = true;
  private limit: number = 0;

  public listSummary:
    { disposals: Disposal[], manuals: Manual[], infraestructureTypes: Infraestructure[], staffSkill: StaffSkill[], sparePart: SparePart[], toolType: ToolType[] } =
    { disposals: [], manuals: [], infraestructureTypes: [], staffSkill: [], sparePart: [], toolType: [] };

  constructor(
    private _sharedService: SharedService,
    private _serviceService: ServiceService
  ) { }


  ngOnInit() {
    if (this.idService) {
      // this.getDataService();
      if (this.numberItem == 1) this.getAllResourcePoli();
      else if (this.numberItem == 2) this.getAllResourceService();
    } else this._sharedService.logOutUser();

    if (this.onlyRead == null)
      this.onlyRead = false;
  }

  /*************************************** Funciones Propias ****************************************/

  getDataService(): void {
    this._serviceService.getDataServices(this.idService).pipe(take(1)).subscribe(
      data => {
        this.service = data;
        if (this.numberItem == 1) this.getAllResourcePoli();
        else if (this.numberItem == 2) this.getAllResourceService();
      },
      err => this._sharedService.logOutUser()
    );
  }

  getAllResourceService(): void {
    this._serviceService.getResourceService(this.idService).pipe(take(1)).subscribe(data => {
      this.getAllMaintTask();
      this.listResourceService = data;
    });
  }

  getAllResourcePoli(): void {
    this._serviceService.getResourceServicePoli(this.idService).pipe(take(1)).subscribe(
      data => {
        this.listResourceService = data;
        this.filterResource(data);
      }
    );
  }

  filterResource(list: any[]): void {
    list.forEach(r => {
      let elem: any;
      switch (r.typeResourceID) {
        case 'Disposal':
          elem = new Disposal(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.disposals.push(elem);
          break;
        case 'Manual':
          elem = new Manual(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.manuals.push(elem);
          break;
        case 'InfrastructureType':
          elem = new Infraestructure(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.infraestructureTypes.push(elem);
          break;
        case 'StaffSkill':
          elem = new StaffSkill(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.staffSkill.push(elem);
          break;
        case 'SparePart':
          elem = new SparePart(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.sparePart.push(elem);
          break;
        case 'ToolType':
          elem = new ToolType(r.resource);
          elem.selectedAmount = r.resourceTotalQ;
          this.listSummary.toolType.push(elem);
          break;
      }
    });
    this.loaderData = false;
    this.loaderDataResources = false;
  }

  getAllMaintTask(): void {
    this._serviceService.getAllMaintTask(this.idService).pipe(take(1)).subscribe(
      data => {
        this.listMaintTask = data;
        this.getAllResourcesOfTask();
      },
      err => { }
    );
  }


  getAllResourcesOfTask(): void {
    if (this.listMaintTask.length > 0) {
      from(this.listMaintTask).subscribe(
        (m: MaintTask) => {
          forkJoin(
            this._serviceService.getAllDisposalOfMaintTask(m.id).pipe(take(1)),
            this._serviceService.getAllManualOfMaintTask(m.id).pipe(take(1)),
            this._serviceService.getAllSparePartOfMaintTask(m.id).pipe(take(1)),
            this._serviceService.getAllInfraestructureOfMaintTask(m.id).pipe(take(1)),
            this._serviceService.getAllStaffSkillOfMaintTask(m.id).pipe(take(1)),
            this._serviceService.getAllToolTypeOfMaintTask(m.id).pipe(take(1))
          ).subscribe(
            data => {
              m.disposals = data[0];
              m.manuals = data[1];
              m.spareParts = data[2];
              m.infrastructureTypes = data[3];
              m.staffSkills = data[4];
              m.toolTypes = data[5];
              this.filterData(m);
            },
            err => { console.log('Error al traer todas los recuros de la tarea ', m.id); },
            () => { this.checkEndOfList(); }
          );
        },
        err => { console.log('Error al traer todas la tareas'); },
        () => { }
      );
    } else {
      this.loaderData = false;
      this.loaderDataResources = false;
    }
  }


  filterData(t: MaintTask): void {
    t.disposals.forEach(d => { d.selectedAmount = 1; });
    t.manuals.forEach(m => { m.selectedAmount = 1; });
    t.infrastructureTypes.forEach(i => { i.selectedAmount = 1; });
    t.staffSkills.forEach(i => { i.selectedAmount = 1; });
    t.spareParts.forEach(i => { i.selectedAmount = 1; });
    t.toolTypes.forEach(i => { i.selectedAmount = 1; });
  }

  checkEndOfList(): void {
    this.limit++;
    if (this.limit == this.listMaintTask.length) {
      this.loaderData = false;
      this.loaderDataResources = false;
      this.filterSummary();
    }
  }

  filterSummary(): void {
    this.listMaintTask.forEach((m, i) => {

      m.disposals.forEach((d, j) => {
        let result: boolean = false;
        this.listSummary.disposals.forEach(di => {
          if (d.id == di.id) {
            di.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let disposal: Disposal = JSON.parse(JSON.stringify(d));
          disposal.selectedAmount = 1;
          disposal.edit = false;
          this.listSummary.disposals.push(disposal);
        }
      });

      m.manuals.forEach((ma, j) => {
        let result: boolean = false;
        this.listSummary.manuals.forEach(man => {
          if (ma.id == man.id) {
            man.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let manual: Manual = JSON.parse(JSON.stringify(ma));
          manual.selectedAmount = 1;
          manual.edit = false;
          this.listSummary.manuals.push(manual);
        }
      });

      m.infrastructureTypes.forEach((n, j) => {
        let result: boolean = false;
        this.listSummary.infraestructureTypes.forEach(inf => {
          if (n.id == inf.id) {
            inf.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let infraestructure: Infraestructure = JSON.parse(JSON.stringify(n));
          infraestructure.selectedAmount = 1;
          infraestructure.edit = false;
          this.listSummary.infraestructureTypes.push(infraestructure);
        }
      });

      m.staffSkills.forEach((s, j) => {
        let result: boolean = false;
        this.listSummary.staffSkill.forEach(st => {
          if (s.id == st.id) {
            st.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let staffskill: StaffSkill = JSON.parse(JSON.stringify(s));
          staffskill.selectedAmount = 1;
          staffskill.edit = false;
          this.listSummary.staffSkill.push(staffskill);
        }
      });

      m.spareParts.forEach((s, j) => {
        let result: boolean = false;
        this.listSummary.sparePart.forEach(sp => {
          if (s.id == sp.id) {
            sp.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let sparePart: SparePart = JSON.parse(JSON.stringify(s));
          sparePart.selectedAmount = 1;
          sparePart.edit = false;
          this.listSummary.sparePart.push(sparePart);
        }
      });

      m.toolTypes.forEach((t, j) => {
        let result: boolean = false;
        this.listSummary.toolType.forEach(to => {
          if (t.id == to.id) {
            to.selectedAmount++;
            result = true;
          }
        });
        if (!result) {
          let toolType: ToolType = JSON.parse(JSON.stringify(t));
          toolType.selectedAmount = 1;
          toolType.edit = false;
          this.listSummary.toolType.push(toolType);
        }
      });

    });
    this.verifySummaryResource();
  }


  verifySummaryResource(): void {
    this.listResourceService.forEach(r => {
      this.listSummary.disposals.forEach(item => { if (r.typeResourceID == 'Disposal' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
      this.listSummary.manuals.forEach(item => { if (r.typeResourceID == 'Manual' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
      this.listSummary.manuals.forEach(item => { if (r.typeResourceID == 'InfrastructureType' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
      this.listSummary.manuals.forEach(item => { if (r.typeResourceID == 'StaffSkill' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
      this.listSummary.manuals.forEach(item => { if (r.typeResourceID == 'SparePart' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
      this.listSummary.manuals.forEach(item => { if (r.typeResourceID == 'ToolType' && r.resourceID == item.id) item.selectedAmount = r.resourceTotalQ; });
    });
  }

  saveResourceSummary(type: string, id: number, amount: number): void {
    this._sharedService.changeStateModalLoader(true);
    let info: {
      typeResourceID: string,
      maintCmpActivityID: number,
      resourceTotalQ: number,
      typeMeasureID: number,
      resourceID: number
    } = {
      typeResourceID: type,
      maintCmpActivityID: this.idService,
      resourceTotalQ: amount,
      typeMeasureID: 1,
      resourceID: id
    };
    let idResource: number = this.verifyResourceSaveOrEdit(info);
    if (idResource != -1) {  // Editando Nuevo resumen
      this._serviceService.editResourceService(idResource, info).pipe(take(1)).subscribe(
        data => {
          this._sharedService.changeStateModalLoader(false);
        },
        err => {
          alert('Error al editar el recurso');
          this._sharedService.changeStateModalLoader(false);
        }
      );
    } else {  // Creando Nuevo resumen
      this._serviceService.saveResourceService(info).pipe(take(1)).subscribe(
        data => {
          this.listResourceService.push(data);
          this._sharedService.changeStateModalLoader(false);
        },
        err => {
          alert('Error al crear el recurso');
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  verifyResourceSaveOrEdit(data: any): number {
    let result: number = -1;
    for (let item of this.listResourceService) {
      if (
        item.typeResourceID == data.typeResourceID &&
        item.maintCmpActivityID == data.maintCmpActivityID &&
        item.resourceID == data.resourceID
      ) {
        result = item.id;
        break;
      }
    }
    return result;
  }

}
