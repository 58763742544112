import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';


// Models
import { Machine } from 'src/app/models/class/machine';


// Services
import { MachineService } from 'src/app/services/machine.service';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DailyReportService } from 'src/app/services/daily-report.service';
import { TypeState } from 'src/app/models/class/typeState';
import { DailyReport } from 'src/app/models/class/dailyReport';
import { textChangeRangeIsUnchanged } from 'typescript';
import { UseHistoryService } from 'src/app/services/use-history.service';
import { Airport } from 'src/app/models/class/airport';
import { Departament } from 'src/app/models/class/departament';
import { Unit } from 'src/app/models/class/unit';
import { CommandService } from 'src/app/services/command.service';
import { Command } from 'src/app/models/class/command';


declare var $: any;
declare var Taggd: any;

var async = require('async');

@Component({
  selector: 'app-machine-info',
  templateUrl: './machine-info.component.html',
  styleUrls: ['./machine-info.component.sass']
})
export class MachineInfoComponent implements OnInit, OnChanges {

  @Input() data: Machine;
  @Input() viewFullInfo: boolean;
  @Input() hideData: boolean = true;
  @Input() scrollCard: boolean = false;
  @Output() statusModalMaintenance: EventEmitter<number> = new EventEmitter<number>();
  @Input() typeView: number;
  @Input() set validateUpdate(isUpdated: boolean) {
    if (isUpdated) {
      this.hideData = false;
      this.showHistory = true;
      //this._sharedService.nextIdMachineUpdated(-1);
    }
  };

  @Input() viewOnlyHeaders: boolean = false
  @Input() horizontalView: boolean = false;


  private subscriptionMachines: Subscription;
  private subscriptionUpdateWidtCard: Subscription;
  private subscriptionTypeView: Subscription;
  private machines: Machine[] = [];
  public pendingSave: boolean = false;
  public confirmUpdate: { updated: boolean, dateTime: Date } = { updated: false, dateTime: new Date() };
  // public typeStates: TypeState[] = []
  public limitHour: number = 23;
  public showHistory: boolean = false;
  public modifyWitdh: boolean = false;

  public myCommand: Command;
  public myUnits: number[];

  constructor(
    public _sharedService: SharedService,
    private _router: Router,
    private _machineService: MachineService,
    private _useHistoryService: UseHistoryService,
    private _dailyReportService: DailyReportService,

    private _commandService: CommandService,

  ) {
    this.myCommand = this._sharedService.getCommand();
    this.myUnits = this._sharedService.getUnits();
  }

  ngOnInit() {
    this.viewOnlyHeaders == true ? this.hideData = false : ''
    this.subscriptionMachines = this._sharedService.sharedMultiDailyHist.subscribe(data => {
      if (data.machines) {
        this.machines = data.machines
      }
    });
    this.subscriptionUpdateWidtCard = this._sharedService.sharedWidthMachineCards.subscribe(data => {
      this.modifyWitdh = data;
    });
  }

  showHideHistiy(data: boolean): void {
    this.showHistory = !this.showHistory
    this._sharedService.validateWidthMachineCards(this.showHistory, this.data.id);
  }
  showHideCards(data: boolean): void {
    this.hideData = data;
  }
  ngOnChanges() { 
  }

  ngOnDestroy() {
    this.subscriptionMachines.unsubscribe()
    this.subscriptionUpdateWidtCard.unsubscribe()
  }

  // TODO: check date
  showUpdateTime() {
    let show;
    if (this.data.useHistoryAugmenteds != null) {
      if (this.data.useHistoryAugmenteds[0].dateReport != null) {
        var timestamp = new Date(this.data.useHistoryAugmenteds[0].dateReport);
        var hours = timestamp.getHours();
        if (hours === 0) show = false;
        else show = true;
      } else show = false;
    } else show = false;
    return show;
  }

  checkCard(machineId): boolean {
    if (this.machines.find(x => x.id == machineId)) return true;
    else return false;
  }

  toggleChange() {
    this._sharedService.nextBSmultiCardsSelected(this.data)
  }
  /*-------------------------------Fin de Funciones Propias-------------------*/
  activateModalMaintenance(machineID: number): void {
    this.statusModalMaintenance.emit(machineID);
  }

  redirectHistory(machineID: number) {
    this._router.navigate([`home/historical/${machineID}`]);
  }

  redirectHelicopter(machineID: number) {
    this._router.navigate([`home/systems/${machineID}`]);
  }

  saveNewData(event) {
    if (event.edited) {
      this.data = event.data
      this.pendingSave = true
    } else this.pendingSave = false
  }

  updateReport(): void {
    let machineAttributesToUpdate: Machine = {
      currentHoursUse: this.data.currentHoursUse,
      currentCycleUse: this.data.currentCycleUse,
      currentLandingUse: this.data.currentLandingUse,
      totalCycleUse: this.data.totalCycleUse,
      totalLandingUse: this.data.totalLandingUse,
      departamentID: this.data.departamentID,
      descript: this.data.descript,
      airportID: this.data.airportID,
      isUpdate: true
    };
    this._machineService.updateMachine(this.data.id, machineAttributesToUpdate).pipe(take(1)).subscribe(
      data => {
        let updateUnitHist = {
          id: this.data.unitHistories[0].id,
          hoursAsigned: this.data.unitHistories[0].hoursAsigned
        }
        this._machineService.updateUnitHistory(JSON.stringify(updateUnitHist)).pipe(take(1)).subscribe(data => {
          this.data.unitHistories[0] = data
          this.confirmUpdate = { updated: true, dateTime: new Date() };
          this.pendingSave = false;

          this._sharedService.changeStateModalMessage({
            typeMsm: 0,
            titleMsm: "",
            textMsm: "¡Datos Actualizados!",
            textFooter: "",
          });
        })
      },
      err => { console.log('Error al actulizar el estado del reporte'); }
    );
  }
}
