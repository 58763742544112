export class MachineHistory {
    	/*1*/ 	public id: number;

			public componentID: number;
			public cycleEnd: number;
			public cycleLandingEnd: number;
			public cycleLandingStart: number;
			public cycleLandingUseTotal: number;
			public cycleStart: number;
			public cycleUseTotal: number;
			public dateEnd: Date;
			public dateStart: Date;
			public description: String;
			public hoursEnd: number;
			public hoursStart: number;
			public hoursUseTotal: number;
			public machineID: number;
			public maintCmpActivityID: number;

	constructor(u ?: any) {
		this.id = u ? u.id : null;
		this.componentID = u ? u.componentID : null;
		this.cycleEnd = u ? u.cycleEnd : null;
		this.cycleLandingEnd = u ? u.cycleLandingEnd : null;
		this.cycleLandingStart = u ? u.cycleLandingStart : null;
		this.cycleLandingUseTotal = u ? u.cycleLandingUseTotal : null;
		this.cycleStart = u ? u.cycleStart : null;
		this.cycleUseTotal = u ? u.cycleUseTotal : null;
		this.dateEnd = u ? u.dateEnd : null;
		this.dateStart = u ? u.dateStart : null;
		this.description = u ? u.description : null;
		this.hoursEnd = u ? u.hoursEnd : null;
		this.hoursStart = u ? u.hoursStart : null;
		this.hoursUseTotal = u ? u.hoursUseTotal : null;
		this.machineID = u ? u.machineID : null;
		this.maintCmpActivityID = u ? u.maintCmpActivityID : null;
	}
}
