<div class="cardContent" [ngClass]="{'cardContent--lateralView': horizontalView}">
  <div class="cardContent--newWidh">
    <app-new-control-flight-hours [thisMachine]="data"></app-new-control-flight-hours>
  </div>
  <div class="cardContent--newWidh">
    <new-app-status-annotations [thisUseHistoryAugmented]="data.controlleruse" [changeStyleTableView]="horizontalView" [changeStyle]="true"></new-app-status-annotations>
  </div>
  <!-- <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[1]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[2]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[3]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div> -->
</div>

<!-- <div class="content-container">
  <div class="content-container__item content-container__item--status-traking">
    <div class="content-container__item-container">
      <div class="content-container__item-title">
        <i class="icon if2rt-search content-container__item-title-icon "></i>
        <div class="title text-bold">SEGUIMIENTO DE ESTADO</div>
      </div>
      <div class="status-tracking">
        <div class="status-chart">
          <div #pieChart></div>
        </div>
      </div>
      <div class="status-tracking-container">
        <div class="status-tracking-container__item" *ngFor="let status of statusTrackingInfo; index as i">
          <div class="status-tracking-container__item-title">
            <i class="icon if2rt-check-circle-solid status-tracking-container__icon" [ngStyle]="{'color': chartColor[i] }"></i>{{status.title}}
          </div>
          <div class="status-tracking__data">
            <div class="status-tracking-container__item-date">
              <i class="icon if2rt-date status-tracking-container__icon"></i>{{status.date}}
            </div>
            <div class="status-tracking-container__item-hour">
              <i class="icon if2rt-clock status-tracking-container__icon"></i>{{status.hour}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-container__item">
    <div class="content-container__item-container">
      <div class="content-container__item-title">
        <i class="icon if2rt-alert-circle content-container__item-title-icon  "></i>
        <div class="title text-bold">OBSERVACIONES</div>
      </div>
      <div class="observation">
        <div class="observation__item" >
          <div class="split-item__history"></div>
          {{data.descript }}
          <br>
        </div>
        <div class="observation__item" *ngFor="let useHistoryAugmented of data.useHistoryAugmenteds.slice(0, 5)">
          <div class="observation__comment" *ngIf="useHistoryAugmented.comentReport">  {{useHistoryAugmented.comentReport | lowerCaseFirstCapital }}  </div>
          <div class="observation__comment" *ngIf="!useHistoryAugmented.comentReport">  ---  </div>
          <br>
          <div class="observation__item-date"> {{useHistoryAugmented.dateReport | date: 'short'}}</div>
        </div>
			</div>
    </div>
  </div>
  <ng-container *ngIf="showUpdateTime()">
    <div class="content-container__split-item"></div>
    <div class="content-container__footer-update-date">Última modificación el {{data.useHistoryAugmenteds[0].dateReport | date:'medium'}}</div>
  </ng-container>
</div> -->