import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs-compat';
import { take } from 'rxjs/operators';
import { Airport } from 'src/app/models/class/airport';
import { Command } from 'src/app/models/class/command';
import { Departament } from 'src/app/models/class/departament';
import { Machine } from 'src/app/models/class/machine';
import { TypeState } from 'src/app/models/class/typeState';
import { Unit } from 'src/app/models/class/unit';
import { UseHistory } from 'src/app/models/class/useHistory';
import { TypeMsm } from 'src/app/models/Interfaces/modalMessage';
import { CommandService } from 'src/app/services/command.service';
import { MachineService } from 'src/app/services/machine.service';
import { ServiceService } from 'src/app/services/service.service';
import { SharedService } from 'src/app/services/shared.service';
import { UseHistoryService } from 'src/app/services/use-history.service';

@Component({
  selector: 'app-hour-use-history',
  templateUrl: './hour-use-history.component.html',
  styleUrls: ['./hour-use-history.component.sass']
})
export class HourUseHistoryComponent implements OnInit {

  @Input() thisMachine: Machine;

  public myCommand: Command;
  public myUnits: number[];
  public typeStates: TypeState[] = [];
  public units: Unit[] = [];
  public newUseHistory: UseHistory = new UseHistory();
  public allDataToUpdate = [];
  public listenerDelete: Subscription;
  public airports: Airport[] = [];

  constructor(
    public _sharedService: SharedService,
    private _commandService: CommandService,
    private _useHistoryService: UseHistoryService,
    private _machineService: MachineService,
    private _serviceService: ServiceService,
  ) {
    this.typeStates = this._sharedService.getAllTypeStates();
    this.myCommand = this._sharedService.getCommand();
    this.myUnits = this._sharedService.getUnits();
    this.getMyUnits();
  }

  ngOnInit(): void {
    this.prepareDataToUpdate();
    this.initNewUseHistoryData();
  
    this._machineService.getAllAirport().pipe(take(1)).subscribe(data => {
      this.airports = data;
    })
    console.log('received thisMachine: ', this.thisMachine);
    console.log('newUseHistory: ', this.newUseHistory);
  }

  getMyUnits(): void {
    let filter = { where: { id: { inq: this.myUnits } } };
    this._machineService.getAllUnits(JSON.stringify(filter)).pipe(take(1)).subscribe(units => {
      this.units = units;
    });
  }
  // loadAllUseHistory(): void {
  //   let myId = this.thisMachine.id
  //   const filterMAchines = {
  //     where: {"id": myId}, 
  //     include: [
  //       {
  //         relation: "dailyUseHistories", scope: {
  //           order: "id DESC", "limit": 3, include: { relation: "typeState", scope: { include: { relation: "stateName" } } }
  //         }
  //       },
  //       {
  //         relation: "useHistoryAugmenteds", scope: {
  //           order: "datereport DESC", "limit": 10, include: { relation: "typeState", scope: { include: { relation: "stateName" } } }
  //         }
  //       },
  //       {
  //         relation: "maintHistories", scope: {
  //           order: ["hoursUseNext ASC", "id DESC"], "limit": 10, include: ["maintCmpActivity", "unit", "user", "failReports"]
  //         }
  //       },
  //       {
  //         relation: "typeState", scope: {
  //           include: { relation: "stateName" }
  //         }
  //       },
  //       {
  //         relation: "components", scope: {
  //           order: "id DESC", "limit": 5, include: [
  //             "systemType", { relation: "maintHistories", scope: { order: "id DESC", "limit": 1, include: ["maintCmpActivity"] } }
  //           ]
  //         }
  //       },
  //       { relation: "unitHistories", scope: { "limit": 1, order: "id DESC" } },
  //       "fleet", "unit", "airport", "departament"]
  //   };
  //   this._commandService.getAllMachines(this.myCommand.id, JSON.stringify(filterMAchines)).subscribe((data) => {
  //     let machineListFull = data.map(m => {
  //       m["nameFormation"]=m.unit.formationID.toString()
  //       m["nameUnit"]=m.unitID.toString()
  //       m["machineType"]=m.fleet.roleMachineID.toString()
  //       m["maker"]=m.fleet.manufactureID.toString()
  //       m["family"]=m.fleet.fleetFamilyID.toString()
  //       m["nameFleet"]=m.fleetID.toString()
  //       m["nameState"]=m.typeState.colorGroup
  //       m["nameStateGroup"]=m.typeStateID.toString()
  //       m["nameUnitManto"]=m.fixedUnitID.toString()
  //       return m
  //     });
  //     this.thisMachine = machineListFull[0];
  //     this._sharedService.updateMachieInMachineList(this.thisMachine);
  //   });
  //   this.prepareDataToUpdate();
  // }
  prepareDataToUpdate(): void {
    this.allDataToUpdate = [];
    this.thisMachine.useHistoryAugmenteds.forEach(element => {
      let dataToUpdate = {
        id: element.id,
        hoursUse: element.hoursUse,
        cycleUse: element.cycleUse,
        cycleLandingUse: element.cycleLandingUse,
        editData: false,
      }
      this.allDataToUpdate.push(dataToUpdate);
    });
  }
  initNewUseHistoryData(): void {
    this.newUseHistory.machineID = this.thisMachine.id;
    this.newUseHistory.hoursUse = 0;
    this.newUseHistory.cycleUse = 0;
    this.newUseHistory.cycleLandingUse = 0;
    if (this.thisMachine.useHistoryAugmenteds.length > 0) this.newUseHistory.comentReport = this.thisMachine.useHistoryAugmenteds[0].comentReport;
    else this.newUseHistory.comentReport = "";
    this.newUseHistory.typeStateID = this.thisMachine.typeStateID;
    this.newUseHistory.unitID = this.thisMachine.unitID;
    this.newUseHistory.departamentID = this.thisMachine.departamentID;
    this.newUseHistory.airportID = this.thisMachine.airportID;
    this.newUseHistory.commandID = this.thisMachine.commandID;
  }
  saveNewUseHistoryData(): void {
    this.newUseHistory.dateReport = new Date().toISOString();
    this._useHistoryService.postUseHistory(this.newUseHistory).pipe(take(1)).subscribe(
      data => {
        if (data) {
          let text: string = 'Registro guardado exitosamente';
          this.showModal(TypeMsm.success, 'Ok', text, '');
          this.initNewUseHistoryData();
          //this.loadAllUseHistory();
        }
      }, err => {
        // console.log('saveNewUseHistoryData error: ', err);
        let text: string = 'No se pudo guardar el registro';
        let footerText: string = 'Contacte a su administrador';
        this.showModal(TypeMsm.Error, 'Error', text, footerText);
      }
    );
  }
  updateUseHistoryData(index: number): void {
    let modelID = this.allDataToUpdate[index]['id'];
    let dataToSend = {
      hoursUse: this.allDataToUpdate[index]['hoursUse'],
      cycleUse: this.allDataToUpdate[index]['cycleUse'],
      cycleLandingUse: this.allDataToUpdate[index]['cycleLandingUse']
    }
    this._useHistoryService.patchUseHistoryByID(modelID, dataToSend).pipe(take(1)).subscribe(
      data => {
        if (data) {
          let text: string = 'Registro actualizado exitosamente';
          this.showModal(TypeMsm.success, 'Ok', text, '');
          this.initNewUseHistoryData();
          //this.loadAllUseHistory();
        }
      }, err => {
        // console.log('updateUseHistoryData error: ', err);
        let text: string = 'No se pudo actualizar el registro';
        let footerText: string = 'Contacte a su administrador';
        this.showModal(TypeMsm.Error, 'Error', text, footerText);
      }
    );
  }
  confirmDeleteUseHistory(index: number): void {
    this.listenerDelete = SharedService.changeDataModalMessageEmitter.subscribe(data => {
      if (data.result == 'confirmed') this.deleteUseHistoryData(index); // cancelled
      this.listenerDelete.unsubscribe();
    });
    this._sharedService.changeStateModalMessage({
      typeMsm: TypeMsm.confirm,
      titleMsm: "Atención!",
      textMsm: "¿Esta seguro de eliminar este registro?",
      textFooter: "Una vez eliminado no se podra recuperar.",
    });
  }
  deleteUseHistoryData(index: number): void {
    let modelID = this.allDataToUpdate[index]['id'];
    this._useHistoryService.deleteUseHistoryByID(modelID).pipe(take(1)).subscribe(
      data => {
        let text: string = 'Registro eliminado exitosamente';
        this.showModal(TypeMsm.success, 'Ok', text, '');
        this.initNewUseHistoryData();
        //this.loadAllUseHistory();
      }, err => {
        // console.log('deleteUseHistoryData error: ', err);
        let text: string = 'No se pudo borrar el registro';
        let footerText: string = 'Contacte a su administrador';
        this.showModal(TypeMsm.Error, 'Error', text, footerText);
      }
    );
  }
  setFilterOptionPernocta(pernocta: { airport: Airport; departament: Departament; }) {
    if (pernocta.airport && pernocta.airport.id >= 0) {
      this.newUseHistory.airportID = pernocta.airport.id;
      // Siguiente linea comentada por el cambio de relación de los aeropuertos region y no con departamentos 
      //this.newUseHistory.departamentID= this.airports.find(x=>x.id==pernocta.airport.id).departamentID 
    }
    else this.newUseHistory.airportID = null;
    if (pernocta.departament && pernocta.departament.id >= 0) this.newUseHistory.departamentID = pernocta.departament.id;
   

  }


  showModal(typeModal: number, tittleText: string, text: string, footerText: string): void {
    this._sharedService.changeStateModalMessage({
      typeMsm: typeModal,
      titleMsm: tittleText,
      textMsm: text,
      textFooter: footerText
    });
  }

  public aditionalFile: {
    file: File,
    link: string
  } = {
      file: null,
      link: ''
    }

  setFile(e: any): void {
    if (e.target && e.target.files[0]) {
      let ext: any = this._sharedService.getExtensionFile(e.target.files[0].name);
      if (ext && ext[0] == 'pdf') {
        this.aditionalFile.file = e.target.files[0];
        this.aditionalFile.link = e.target.files[0].name;
      } else {
        this._sharedService.changeStateModalMessage({ typeMsm: TypeMsm.Error, titleMsm: 'Error!', textMsm: 'Archivo no valido.', textFooter: 'Para obtener más información, comuníquese con su administrador de sistema.' });
      }
    }
  }

  saveFile(): void {
    this._sharedService.changeStateModalLoader(true);
    this._serviceService.uploadFile(this.aditionalFile.file, 'attachments/dailyattachment/upload').then((data: any) => {
      if (data && data.result && data.result.files && data.result.files.file && data.result.files.file[0]) {
        this.newUseHistory.attachment = 'attachments/dailyattachment/download/' + data.result.files.file[0].name;
        this.saveNewUseHistoryData();
      }
      this._sharedService.changeStateModalLoader(false);
    });
  }

  isFileToUpload(): void {
    if (this.aditionalFile.link.trim() != '') this.saveFile();
    else this.saveNewUseHistoryData();
  }

  resetDataManual(): void {
    this.aditionalFile = {
      file: null,
      link: ''
    }
  }
}
