import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowerCaseFirstCapital'
})
export class LowerCaseFirstCapital implements PipeTransform {

  constructor() {}

  transform(text: string): any {

    var newText: string = '';
    if(!text) {
      newText = '';
    } else if ( text.charAt(0) !== '') {
      if (text.charAt(0) === '-') {
        if(text.substr(1).toLowerCase())
          newText = text[1].toUpperCase() + text.substr(2).toLowerCase();
      } else {
        newText = text[0].toUpperCase() + text.substr(1).toLowerCase();
      }
    }
    return newText;
  }

}