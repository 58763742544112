import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})

/**
 * This pipe filter an array of any elements, with other array of properties (ONLY STRING TYPES)
 * And a search query
 */
export class FilterPipe implements PipeTransform {
  transform(items: any[], properties: string[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!properties || properties.length == 0) {
      return [];
    }
    if (!searchText || searchText === "") {
      return items;
    }

    searchText = searchText.toLocaleLowerCase().normalize('NFKD').replace(/[^\w]/g, '');

    return items.filter((it) => {
      let propertyQuery = "";
      properties.forEach((propertie) => {
        if (it[propertie] && typeof it[propertie] === "string")
          propertyQuery += it[propertie].toLocaleLowerCase() + " ";
      });

      if (!propertyQuery || propertyQuery === "") return [];
      return propertyQuery.toLocaleLowerCase().normalize('NFKD').replace(/[^\w]/g, '').includes(searchText);
    });
  }
}
