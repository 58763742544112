import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CircularMenuService {

// Varibales
private actionCircularMenu$ = new Subject<any>();
private receiverCircularMenu$ = new Subject<any>();

  constructor() { }


/********************************* Funciones Propias Emitter *********************************/
  emitterActionCircularMenu(value: any): void{
    this.actionCircularMenu$.next(value);
  }

  getValueCircularMenu(): Observable<any>{
    return this.actionCircularMenu$.asObservable();
  }

/********************************* Funciones Propias Emitter *********************************/
  emitterReceiverCircularMenu(value: any): void{
    this.receiverCircularMenu$.next(value);
  }

  getValueReceiverCircularMenu(): Observable<any>{
    return this.receiverCircularMenu$.asObservable();
  }

}
