import { Component, Input, OnInit } from '@angular/core';
import { WorkPackage } from 'src/app/models/class/workPackage';

@Component({
  selector: 'app-completion-percentage-average',
  templateUrl: './completion-percentage-average.component.html',
  styleUrls: ['./completion-percentage-average.component.sass']
})
export class CompletionPercentageAverageComponent implements OnInit {

  @Input() WorkPackage: WorkPackage;

  public progressTime = 0;  //Tareas
  public progress = 0; //Horas Hombre
  public progressDateTotalDays = 0; // Horas Fecha
  public progressDateDays = 0; // Horas Fecha
  public progressDatePercentage = 0; // Horas Fecha

  constructor() { }

  ngOnInit(): void {
    this.WorkPackage?.MaintHistories?.forEach(element => {
      this.progressTime += element.progressTime
      this.progress += element.progress
    })
    this.progressTime = this.progressTime / this.WorkPackage?.MaintHistories?.length
    this.progress = this.progress / this.WorkPackage?.MaintHistories?.length
    this.progressDateTotalDays += this.getTime(this.WorkPackage?.dateInit, this.WorkPackage?.dateFinish).totalDays
    this.progressDateDays += this.getTime(this.WorkPackage?.dateInit, this.WorkPackage?.dateFinish).days
    this.progressDatePercentage += this.getTime(this.WorkPackage?.dateInit, this.WorkPackage?.dateFinish).percentage
  }

  getTime(init, finish) {
    var days;
    var totalDays;
    var daysNumber;
    var percentage;
    if (init != null || finish != null) {
      let dateFinish = new Date(finish).getTime();
      let dateInit = new Date(init).getTime();
      let currentDate = new Date().getTime();
      if (currentDate >= dateFinish) {
        totalDays = 100;
        days = 100;
        daysNumber = (dateFinish - dateInit) / (1000 * 60 * 60 * 24);
        percentage = 100;
      } else {
        var timeBetween = dateFinish - dateInit;
        var timeBetweenCurrent = currentDate - dateInit;
        totalDays = timeBetween / (1000 * 60 * 60 * 24);
        days = timeBetweenCurrent / (1000 * 60 * 60 * 24);
        percentage = (days * 100) / totalDays;
        daysNumber = totalDays
      }
    }
    return { days: days, percentage: percentage, totalDays: totalDays, daysNumber: daysNumber };
  }

}
