export class Disposal {

	/*1*/ 	public id: number;
	/*2*/ 	public nameDisposal: string;
	/*3*/ 	public descript: string;
	/*4*/ 	public roleMachineID: number;

	/*5*/ 	public selectedAmount: number;
	/*6*/ 	public resourceID: number;
	/*7*/ 	public edit: boolean;

	constructor(d ?: any) {
		this.id = d ? d.id : null;
		this.nameDisposal = d ? d.nameDisposal : null;
		this.descript = d ? d.descript : null;
		this.roleMachineID = d ? d.roleMachineID : null;
		this.selectedAmount = 1;
		this.resourceID = 0;
	}

}
