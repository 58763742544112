import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnitHistory } from '../models/class/unitHistory';
import { UseHistoryAugmented } from '../models/class/useHistoryAugmented';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UseHistoryService {

  private urlModelName_CUD = "UseHistories";

  constructor(
    private _httpService: HttpService
  ) { }

  postUseHistory(data): Observable<any>{
    let url: string = `${this.urlModelName_CUD}`;
    return this._httpService.httpPost(url, data);
  }

  patchUseHistoryByID(modelID, data): Observable<any>{
    let url: string = `${this.urlModelName_CUD}/${modelID}`;
    return this._httpService.httpPatch(url, data);
  }

  deleteUseHistoryByID(modelID): Observable<any>{
    let url: string = `${this.urlModelName_CUD}/${modelID}`;
    return this._httpService.httpDelete(url);
  }

  getAllUseHistoryByID(machineID,filter?:string): Observable<UseHistoryAugmented[]>{
    let url: string = `Machines/${machineID}/UseHistoryAugmenteds`;
    if (filter)
    url+=`?filter=${filter}`
    return this._httpService.httpGet(url);
  }
 

  getLastUseHistoryByID(machineID): Observable<any>{
    let url: string = `Machines/${machineID}/UseHistoryAugmenteds?filter={"limit":10}`;
    return this._httpService.httpGet(url);
  }

  patchUnitHistoryById(unitHistoryID:number,data): Observable<UnitHistory>{
    
    let url: string = `UnitHistories/${unitHistoryID}`;
    return this._httpService.httpPatch(url,data);
  }


}
