import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { Command } from 'src/app/models/class/command';
import { ComponentAircraft } from 'src/app/models/class/component';
import { Machine } from 'src/app/models/class/machine';
import { CommandService } from 'src/app/services/command.service';
import { ComponentsService } from 'src/app/services/components.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-component-card',
  templateUrl: './component-card.component.html',
  styleUrls: ['./component-card.component.sass']
})
export class ComponentCardComponent implements OnInit {

  @Input() cmpData: ComponentAircraft;
  @Input() machineData: Machine;
  @Output() statusModalMaintenanceCmp: EventEmitter<ComponentAircraft> = new EventEmitter<ComponentAircraft>();

  public editCard: boolean;
  public machines: Machine[];
  public componentsList: ComponentAircraft[];
  public editedComponent: any;
  public myCommand: Command;
  public holas: String = "154235, 4513254, 5412130, 5465745132, 57487546, 6484153";
  public today: Date;

  /****************************** Variables para Modal de Manteniento ***************************/

  constructor(
    private _componentsService: ComponentsService,
    private _commandService: CommandService,
    private _sharedService: SharedService
  ) {
    this.editCard = false;
    this.myCommand  = this._sharedService.getCommand();
    this.today = new Date(Date.now());
  }

  ngOnInit(): void {
    console.log(this.cmpData, "desde la carta" );
    this.getMachinesList();
    this.editedComponent = JSON.parse(JSON.stringify(this.cmpData));
    this.componentsList = this.machineData.components;
  }

  splitText(cmp, symbol){
    console.log(cmp, symbol);
    // return cmp.split(symbol)[0];
  }

  hola(){
    console.log("cmpData", this.cmpData);
    console.log("editedCmp", this.editedComponent);
    console.log("machines", this.machines);
    console.log("components", this.componentsList);
  }

  editCardChange(){
    if(this.editCard) this.editedComponent = JSON.parse(JSON.stringify(this.cmpData));
    this.editCard = !this.editCard;
  }

  getMachinesList(){
    const filterMachines = {
      where:{ fleetID: this.machineData.fleetID},
    };
    this._commandService.getAllMachines(this.myCommand.id, JSON.stringify(filterMachines)).pipe(take(1)).subscribe(
      (data) => {
        this.machines = data;
      },
      err => {
        console.log("no se pudieron cargar las maquinad", err);
      }
    );
  }

  saveCmpChanges(){
    this._componentsService.updateComponent(this.cmpData.id, this.editedComponent).pipe(take(1))
    .subscribe(
      (data) => {
        this.editCard = !this.editCard;
        this.cmpData = JSON.parse(JSON.stringify(this.editedComponent));
        console.log(data, "llego esta data");
      },
      (err) =>{
        console.log("No se pudo actualizar el componente", err);
        this.editCard = !this.editCard;
      }
    )
  }

  getCountDays(initialDate, finalDate) {
    let iDate = new Date(finalDate);
    let fDate = new Date(initialDate);
    if(initialDate && finalDate){
      const oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(( fDate.getTime() - iDate.getTime() ) / oneDay));
    } else {
      return '—';
    }
  }

  getSumValues(value1, value2) {
    if(value1 && value2) {
      return value1 + value2;
    } else if(value1 && !value2) {
      return value1;
    } else if(!value1 && value2) {
      return value2;
    } else {
      return 0;
    }
  }

  getSubstract(value1, value2) {
    if(value1 && value2){
      return value1 - value2;
    } else {
      return '—';
    }
  }

  getNameById(list, idValue, listName) {
    if(idValue && list) {
      let result = list.filter(x => x.id == idValue);
      return listName == 'machine' ? result[0].callSing : result[0].serialNumber;
    } else {
      return 'N/A';
    }
  }

  activateModalMaintenance(): void {
    console.log(this.machineData.id);
    this.statusModalMaintenanceCmp.emit(this.cmpData);
  }

}
