export class Manufacture {

	/*1*/ 	public id: number;
	public manufactureName:string;
	public manufactureCode:string;
	public manufactureIco: string;


	constructor(u ?: any) {
		this.id = u ? u.id : null;
		this.manufactureCode = u ? u.id : null;
		this.manufactureIco = u ? u.manufactureIco : null;
	}

}
