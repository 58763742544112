<div class="cardContent">
  <div class="percent">
    <div class="percent__tittle">
      <i class="if2rt-porcent"></i>
      <span>PORCENTAJE DE AVANCE PROMEDIO</span>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>Programado</span></div>
        <progress class="percent__progres__progress-bar" id="file" max="100" value={{progressTime}}> 100% </progress>
        <div class="percent__progres__text__value">
          <span>{{ progressTime | f2rtHour: 'decimals' }}%</span>
        </div>
      </div>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>No programado</span></div>
        <progress class="percent__progres__progress-bar" id="file" max="100" value={{progress}}>{{progress}}% </progress>
        <div class="percent__progres__text__value">
          <span>{{ progress | f2rtHour: 'decimals' }}%</span>
        </div>
      </div>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>Tiempo</span></div>
        <progress class="percent__progres__progress-bar" id="file"
          max="{{progressDateTotalDays}}"
          value="{{progressDateDays}}"
        >
          {{progressDateTotalDays}}%
        </progress>
        <div class="percent__progres__text__value">
          <span>{{ progressDatePercentage| f2rtHour: 'decimals'}}%</span>
        </div>
      </div>
    </div>
  </div>
</div>