export interface ModalMessage {
    typeMsm: TypeMsm;
    titleMsm?: string;
    textMsm?: string;
    textFooter?: string;
  }
  
  export enum TypeMsm {
    success = 0,
    Error = 1,
    warning = 2,
    info = 3,
    confirm = 4,
}

export enum TypResult {
  confirmed = 0,
  canceled = 1,
}