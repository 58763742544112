<div class="cardContent">
  <div class="percent">
    <div class="percent__tittle">
      <i class="if2rt-porcent"></i>
      <span>PORCENTAJE DE AVANCE</span>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>Tareas</span></div>
        <progress class="percent__progres__progress-bar" id="file" max="100" value={{thisMaintHistory?.progressTime}}> 100% </progress>
        <div class="percent__progres__text__value">
          <span>{{ thisMaintHistory?.progressTime ? thisMaintHistory.progressTime : 0 | f2rtHour: 'decimals' }}%</span>
        </div>
      </div>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>Horas hombre</span></div>
        <progress class="percent__progres__progress-bar" id="file" max="100" value={{thisMaintHistory?.progress}}>{{thisMaintHistory?.progress}}% </progress>
        <div class="percent__progres__text__value">
          <span>{{ thisMaintHistory?.progress ? thisMaintHistory.progress : 0 | f2rtHour: 'decimals' }}%</span>
        </div>
      </div>
    </div>
    <div class="percent__progres">
      <div class="percent__progres__text">
        <div class="percent__progres__text__name"><span>Tiempo</span></div>
        <progress class="percent__progres__progress-bar" id="file"
          max="{{getTime(thisMaintHistory?.dateInit, thisMaintHistory?.dateFinish).totalDays}}"
          value="{{getTime(thisMaintHistory?.dateInit, thisMaintHistory?.dateFinish).days}}"
        >
          {{getTime(thisMaintHistory?.dateInit, thisMaintHistory?.dateFinish).totalDays}}%
        </progress>
        <div class="percent__progres__text__value">
          <span>{{ thisMaintHistory ? getTime(thisMaintHistory?.dateInit, thisMaintHistory?.dateFinish).percentage : 0 | f2rtHour: 'decimals'}}%</span>
        </div>
      </div>
    </div>
  </div>
</div>