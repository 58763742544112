import { Component, OnInit, Input } from '@angular/core';
import { Machine } from 'src/app/models/class/machine'

@Component({
  selector: 'app-new-blue-card-machine',
  templateUrl: './new-blue-card-machine.component.html',
  styleUrls: ['./../new-machine-info.component.sass', './new-blue-card-machine.component.sass']
})
export class NewBlueCardMachineComponent implements OnInit {

  @Input() data: Machine;
  @Input() horizontalView: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
