import { Component, Input, OnInit } from '@angular/core';
import { Machine } from 'src/app/models/class/machine';
import { MaintHistory } from 'src/app/models/class/maintHistory';

@Component({
  selector: 'app-completion-percentage',
  templateUrl: './completion-percentage.component.html',
  styleUrls: ['./completion-percentage.component.sass']
})
export class CompletionPercentageComponent implements OnInit {

  //@Input() thisMachine: Machine;
  @Input() thisMaintHistory: MaintHistory;

  constructor() { }

  ngOnInit(): void {
  }

  getTime(init, finish) {
    var days;
    var totalDays;
    var daysNumber;
    var percentage;
    if (init != null || finish != null) {
      let dateFinish = new Date(finish).getTime();
      let dateInit = new Date(init).getTime();
      let currentDate = new Date().getTime();
      if (currentDate >= dateFinish) {
        totalDays = 100;
        days = 100;
        daysNumber = (dateFinish - dateInit)/(1000 * 60 * 60 * 24);
        percentage = 100;
      } else {
        var timeBetween = dateFinish - dateInit;
        var timeBetweenCurrent = currentDate - dateInit;
        totalDays = timeBetween/(1000 * 60 * 60 * 24);
        days = timeBetweenCurrent/(1000 * 60 * 60 * 24);
        percentage = (days * 100) / totalDays;
        daysNumber = totalDays
      }
    }
    return {days: days, percentage: percentage, totalDays: totalDays, daysNumber:daysNumber};
  }

}
