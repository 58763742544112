import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class MaintcmpActivitiesService { 

  constructor(private _servHttp: HttpService) { }

  // Listar 
  get(filters?: string): Observable<any>{
    let url: string = 'maintcmpactivities?filter='+(filters== undefined ? "" : filters); 
    return this._servHttp.httpGet(url);
  }

  // Registrar
  post(item:any): Observable<any>{
    let url: string = 'maintcmpactivities';
    return this._servHttp.httpPost(url,item);
  }

  // Actualizar 
  path(id: number,item:any): Observable<any>{
    let url: string = 'maintcmpactivities/'+id;
    return this._servHttp.httpPut(url, item);
  }

  // Eliminar
  delete(item:any): Observable<any>{
    let url: string = 'maintcmpactivities/'+item.id;
    return this._servHttp.httpDelete(url);
  }
}
