import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "f2rtHour" })
/**
 * This pipe receives a double and return a string with 2 decimals
 * the string can be returned from 2 ways:
 * 1st way is in percent -> 'decimals'
 * 2nd way is in hours-(dot)-mins. -> 'hours'
 */
export class HourPipe implements PipeTransform {
  transform(hourReceived: number, output: 'hours' | 'decimals'): string {
    //console.log(typeof hourReceived);
    if (hourReceived == null || hourReceived == undefined || typeof hourReceived != 'number') return '';
    let response = '';
    response = hourReceived.toFixed(1);
    if (output == 'decimals') return response;
    else {
      let splited = response.split('.');
      response = `${splited[0]}h.${(0+(+splited[1]*60/100).toFixed()).slice(-2)}m`;
      return response;
    }
  }
}
