import { Airport } from "./airport";
import { Departament } from "./departament";
import { SpecialLocation } from "./speciallocations";
import { Unit } from "./unit";


export class UseHistory{

	/*1*/ 	public id?: number;
	public 	machineID?: number;
	public  hoursUse?: number;
	public  cycleUse?: number;
	public cycleLandingUse?: number;
	public dateReport?: string;
	public comentReport?: string;
	public typeStateID?: number;
	public unitID?: number;
	public typeFailID?: number;
	public typeMelID?: number;
	public failDescript?: string
	public departamentID?: number;
	public airportID?: number;
	public commandID?: number;
	public attachment?: string;
	public userId?: number
	public timestamp?: string
	public speciallocationid?: number;

	public airport?:Airport;
	public departament?: Departament;
	public specialLocation?: SpecialLocation;
	public unit?: Unit;

	constructor(m ?: any) {
		this.id = m ? m.id : null;
		this.machineID = m ? m.machineID : null;
		this.hoursUse = m ? m.hoursUse : 0;
		this.cycleUse = m ? m.cycleUse : 0;
		this.cycleLandingUse = m ? m.cycleLandingUse : 0;
		this.dateReport = m ? m.dateReport : null;
		this.comentReport = m ? m.comentReport : null;
		this.typeStateID = m ? m.typeStateID : null;
		this.unitID = m ? m.unitID : null;
		this.typeFailID = m ? m.typeFailID : null;
		this.typeMelID = m ? m.typeMelID : null;
		this.failDescript = m ? m.failDescript:null;
		this.departamentID = m ? m.departamentID : null;
		this.airportID = m ? m.airtportID : null;
		this.commandID = m ? m.commandID : null;
		this.attachment = m ? m.attachment : null;
		this.userId = m ? m.userId : null;
		this.timestamp = m ? m.timestamp : null;
		this.speciallocationid = m ? m.speciallocationid : null;
		this.specialLocation = (m && m.specialLocation) ? new SpecialLocation(m.specialLocation) : null;
	}

}
