import { MaintTask } from './maintTask';
import { Ata } from './ata';
import { SystemType } from './systemType';
import { MaintResource } from './maintResource';
import { Fleet } from './fleet';
import { ServiceType } from './ServiceType';
// import { MaintHistory } from './maintHistory';

export class Service {

	/*1*/ 	public id?: number;
	/*2*/ 	public fleetID: number;
	/*3*/ 	public systemTypeID: number;
	/*4*/ 	public ataTypeID?: number;
	/*5*/ 	public maintCmpActName: string; 
	/*6*/ 	public maintCmpActDescrip: string;
	/*7*/ 	public totalTime: number;
	/*8*/ 	public frequencyHoursUse?: number;
	/*9*/ 	public frecuencyCycleUse?: number;
	/*10*/	public frecuencyDaysUse?: number;
	/*10*/	public frequencyLandingUse?: number;

	/*11*/ 	public maintLevel: number;
	/*11*/ 	public subAtaID?: number;
	/*14*/ 	public serviceTypeID: number;
	/*11*/ 	public maintGroup: string;
	/*11*/ 	public partNumbers?: String;


	/*12*/ 	public ataType?: Ata;
	/*13*/ 	public systemType?: SystemType;

	/*15*/ 	public activated?: boolean;
	/*16*/	public maintHistories?:any;
	/*17*/ 	public listTask?: MaintTask[];
	/*18*/  public maintResources?: MaintResource[];
  			public fleet?: Fleet;

			public ServiceType?: ServiceType;

			public commandID?: number;

	constructor(s ?: any) {
		this.id = s ? s.id : null;
		this.fleetID = s ? s.fleetID : null;
		this.systemTypeID = s ? s.systemTypeID : null;
		this.ataTypeID = s ? s.ataTypeID : null;
		this.maintCmpActName = s ? s.maintCmpActName : null;
		this.maintCmpActDescrip = s ? s.maintCmpActDescrip : null;
		this.totalTime = s ? s.totalTime : null;
		this.frequencyHoursUse = s ? s.frequencyHoursUse : null;
		this.frecuencyCycleUse = s ? s.frecuencyCycleUse : null;
		this.frecuencyDaysUse = s ? s.frecuencyDaysUse : null;
		this.maintLevel = s ? s.maintLevel : null;
		this.ataType = s && s.ataType ? new Ata(s.ataType) : null;
		this.systemType = s && s.systemType ? new SystemType(s.systemType) : null;
		this.serviceTypeID = s ? s.serviceTypeID : null;
		this.maintHistories = s && s.maintHistories ? s.maintHistories : null;
		this.activated = false;
	}

}
