import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { forkJoin, from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { SharedService } from './../../../services/shared.service';
import { ServiceService } from './../../../services/service.service';

// Models
import { Service } from './../../../models/class/service';
import { MaintResource } from '../../../models/class/maintResource';
import { MaintTask } from './../../../models/class/maintTask';
import { Manual } from './../../../models/class/manual';
import { StaffSkill } from './../../../models/class/staffSkill';
import { Disposal } from './../../../models/class/disposal';
import { Infraestructure } from './../../../models/class/infrastructure';
import { ToolType } from './../../../models/class/toolType';
import { SparePart } from './../../../models/class/sparePart';
import { environment } from 'src/environments/environment';
import { MaintHistory } from 'src/app/models/class/maintHistory';

@Component({
  selector: 'app-info-service',
  templateUrl: './info-service.component.html',
  styleUrls: ['./info-service.component.sass']
})
export class InfoServiceComponent implements OnInit {


  @Input() machineID: number;
  @Input() maintCmpActivityID: number;
  @Input() numberRows?: number = 4;

  public itemSelected:string = 'hour'
  public maintHistories: MaintHistory[]=[]
  public loadingData:boolean=true
  constructor(
    private _sharedService: SharedService,
    private _serviceService: ServiceService
  ) { }


  ngOnInit() {
    let filter={
      order: "id DESC",
      limit: this.numberRows,
      include: ["maintCmpActivity","machine"],
      where: {and: [ { machineID: this.machineID},{maintCmpActivityID:this.maintCmpActivityID}, {componentID:null} ]}
    }
    this._serviceService.getMaintHistories(JSON.stringify(filter)).pipe(take(1)).subscribe(data=>{
      this.maintHistories=data
      this.loadingData=false
    })

 
  }

}
