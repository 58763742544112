import { Component, OnInit, Input, AfterContentChecked, OnChanges, Output, EventEmitter } from '@angular/core';
import { Build } from 'src/app/models/class/build';
import { MachineService } from 'src/app/services/machine.service';
import { SharedService } from 'src/app/services/shared.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Machine } from 'src/app/models/class/machine'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare var $: any;
declare var Taggd: any;
@Component({
  selector: 'app-full-info-machine',
  templateUrl: './full-info-machine.component.html',
  styleUrls: ['./../machine-info.component.sass', './full-info-machine.component.sass']
})
export class FullInfoMachineComponent implements OnInit, OnChanges {

  @Input() data: Machine;
  @Output() statusModalMaintenance: EventEmitter<any> = new EventEmitter<any>();


  public typeView = "tripulante"  // tripulante, mantenimiento , operativo
  private machines: Machine[] = [];

  private subscriptionTypeView: Subscription
  private subscriptionMachines: Subscription
  public hideData: boolean = true;
  constructor(
    private _machineService: MachineService,
    public sharedService: SharedService,
    private _router: Router,
  ) { }

  ngOnInit() {

    // Suscription para cuando se cambia el tipo de vista
    this.subscriptionTypeView = this.sharedService.sharedtypeViewCards.subscribe(data => {
      if (data.typeView)
        this.typeView = data.typeView
      else {
        let tempTypeView = this.sharedService.getTypeView()
        if (tempTypeView) {
          this.typeView = tempTypeView
        }
      }
    });

    //Suscription para cuando se selecciona más de una maqina

    this.subscriptionMachines = this.sharedService.sharedMultiDailyHist.subscribe(data => {
      if (data.machines) {
       // console.log('llego machines', data.machines)
        this.machines = data.machines
      }
    });




  }

  ngOnDestroy() {
    this.subscriptionTypeView.unsubscribe()
    this.subscriptionMachines.unsubscribe()
  }

  ngOnChanges() {

  }



  /*-------------------------------Fin de Funciones Propias-------------------*/

  checkCard(machineId): boolean {
    if (this.machines.find(x => x.id == machineId))
      return true;
    else
      return false
  }

  toggleChange() {
    this.sharedService.nextBSmultiCardsSelected(this.data)
  }

  activateModalMaintenance(type: boolean, machineID: number): void  {
    this.statusModalMaintenance.emit({type: type, machineID: machineID});
  }

  redirectHistory(machineID: number){
    this._router.navigate([`home/historical/${machineID}`]);
  }

  showUpdateTime(){
    var timestamp = new Date('December 25, 1995 00:00:00');
    var hours = timestamp.getHours();
    var mins = timestamp.getMinutes();
    var secs = timestamp.getSeconds();
    let show;
    if( hours === 0 && mins === 0 && secs === 0) {
      show = false;
    } else {
      show = true;
    }
    return show;
  }

}
