import { Ata } from "./ata";

export class SystemType {

	/*1*/ 	public id: number;
	/*2*/ 	public systemName: string;
	/*3*/ 	public systemNumber: number;
	/*4*/ 	public systemIco: string;
	/*5*/   public showSystem?: boolean;
	public ataTypes: Ata[];

	constructor(s ?:any) {
		this.id = s ? s.id : null;
		this.systemName = s ? s.systemName : null;
		this.systemNumber = s ? s.systemNumber : null;
		this.systemIco = s ? s.systemIco : null;
	}

}
