import { PartNumber } from './partNumber';

export class SparePart {

	/*1*/ 	public id: number;
	/*2*/ 	public partNumber?: string;
	/*3*/ 	public partName: string;
  /*4*/ 	public partDescript: string;
	/*5*/ 	public fleetID: number;
	/*6*/ 	public systemTypeID: number;
	/*7*/ 	public roleMachineID: number;
	/*8*/ 	public ataTypeID: number;

	/*9*/ 	public selectedAmount: number;
	/*10*/ 	public resourceID: number;
	/*11*/ 	public edit: boolean;
	public partNumbers?: Array <PartNumber>;

	constructor(s?: any) {
		this.id = s ? s.id : null;
		this.partNumber = s ? s.partNumber : null;
		this.partName = s ? s.partName : null;
		this.partDescript = s ? s.partDescript : null;
		this.fleetID = s ? s.fleetID : null;
		this.systemTypeID = s ? s.systemTypeID : null;
		this.roleMachineID = s ? s.roleMachineID : null;
		this.ataTypeID = s ? s.ataTypeID : null;
		this.selectedAmount = 1;
		this.resourceID = 0;
		this.partNumbers = s?  new Array <PartNumber>(s.partNumbers) : null;
	}

}
