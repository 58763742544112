import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Models
import { Machine } from 'src/app/models/class/machine';
// Services
import { SharedService } from 'src/app/services/shared.service';

// Enviroment
import { Router } from '@angular/router';

// declare var google: any;

@Component({
  selector: 'app-red-card-machine',
  templateUrl: './red-card-machine.component.html',
  styleUrls: ['./../machine-info.component.sass','./red-card-machine.component.sass']
})
export class RedCardMachineComponent implements AfterViewInit, OnInit {

  @Input() data: Machine;
  @Input() horizontalView: boolean = false;
  // public hideData:boolean = true;
  // @ViewChild('pieChart') pieChart: ElementRef;
  // public chartColor = ['#80BA27', '#FF0000', '#E6CC00', '#548DD4'];
  // public statusTrackingInfo = [
  //   {title: 'Resolvió', date:'24-10-20', hour:'10:30', number: 20},
  //   {title: 'Reportó', date:'30-11-20', hour:'14:30', number: 20},
  //   {title: 'Reparación', date:'12-09-20', hour:'15:00', number: 20},
  //   {title: 'Leído', date:'26-01-20', hour:'18:50', number: 20}
  // ];
  // public chartInfo = [['Estado', 'Porcetaje']];

  constructor(
    public _sharedService: SharedService,
  ) { }

  async ngOnInit(){
    // await this.buildInfo();
    // await this.renderChart();
  }

  // drawChart = () => {
  //   var data = google.visualization.arrayToDataTable(this.chartInfo);
  //   var options = {
  //     legend: {position: 'labeled'},
  //     pieHole: 0.7,
  //     pieSliceText: 'none',
  //     colors: this.chartColor,
  //     cssClass: 'status-chart'
  //   };
  //   var chart = new google.visualization.PieChart(this.pieChart.nativeElement);
  //   chart.draw(data, options);
  // }

    ngAfterViewInit() {
  //   this.renderChart();
    }

  // changehideData(){
  //   this.hideData = !this.hideData;
  //   this.renderChart();
  // }

  // renderChart() {
  //   if (this.hideData) {
  //     setTimeout(()=>{
  //       google.charts.load('current', { 'packages': ['corechart'] });
  //       google.charts.setOnLoadCallback(this.drawChart);
  //     },100);
  //   }
  // }

  // buildInfo(){
  //   this.statusTrackingInfo.forEach(element => {
  //     let tempArray = [];
  //     tempArray.push(element.title);
  //     tempArray.push(element.number);
  //     this.chartInfo.push(tempArray);
  //   });
  // }
  // /*-------------------------------Fin de Funciones Propias-------------------*/
  // showUpdateTime(){
  //   let show;
  //   if(this.data.useHistoryAugmenteds != null) {
  //     if(this.data.useHistoryAugmenteds[0].dateReport != null) {
  //       var timestamp = new Date(this.data.useHistoryAugmenteds[0].dateReport);
  //       var hours = timestamp.getHours();
  //       var mins = timestamp.getMinutes();
  //       var secs = timestamp.getSeconds();
  //       if( hours === 0 && mins === 0 && secs === 0) {
  //         show = false;
  //       } else {
  //         show = true;
  //       }
  //     } else {
  //       show = false;
  //     }
  //   } else {
  //     show = false;
  //   }
  //   return show;
  // }

}
