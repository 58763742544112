<div class="buildMap" *ngIf="showContent">
    
    <div class="image-container" id="label_icons_building">
        <img class="image__item" id="areasBuild_building" [src]="urlHttp+building.buildMap"
            width="">
    </div>
    <div class="states">
        <div class="item_state"> <i class="state avaliable"></i>Disponible</div>
        <div class="item_state" > <i class="state unavaliable"></i>No Disponible</div>
        
    </div>
</div>