<div class="row mt-3">
  <div class="col-12 form-group">
    <div class="content_select">
      <span class="text-select" *ngIf="listItemsSelect.length == 0">{{data.title}}</span>
      <div class="list-select" *ngIf="listItemsSelect.length > 0"> <a class="specialty" *ngFor="let itemSelect of listItemsSelect" (click)="removeItem(itemSelect.id)">{{itemSelect.value}}</a> </div>
      <i class="fa fa-chevron-down icon-down" ></i>
    </div>
    <div class="container-hover">
      <div class="content-hover scroll">
        <div class="center select__item" *ngFor="let item of listItemsShow" (click)="selectItem(item)"> <span class="text__item">{{item.value}}</span> <i class="fas fa-check icon__item" *ngIf="verifySelectItem(item.id)"></i> </div>
      </div>
    </div>
  </div>             
</div>