import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { finalize } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

	private _activeRequest = 0;

	constructor(private _ngxUiLoaderService: NgxUiLoaderService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this._activeRequest === 0) {
			this._ngxUiLoaderService.startBackground('do-background-things');
			//this._ngxUiLoaderService.start();
		}
		this._activeRequest++;
		return next.handle(request).pipe(finalize(() => this._stopLoader()));
	}

	private _stopLoader() {
		this._activeRequest--;
		if (this._activeRequest === 0) {
			this._ngxUiLoaderService.stopBackground('do-background-things');
			//this._ngxUiLoaderService.stop();
		}
	}
}
