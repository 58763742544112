export class Infraestructure {

	/*1*/ 	public id: number;
	/*2*/ 	public infrastructureName: string;
	/*3*/ 	public infrastructureDescript: string;
	/*4*/ 	public roleMachineID: any;

	/*6*/ 	public selectedAmount: number;
	/*7*/ 	public resourceID: number;

	/*8*/ 	public activated: boolean;
	/*9*/ 	public edit: boolean;

	constructor(i ?:any) {
		this.id = i ? i.id : null;
		this.infrastructureName = i ? i.infrastructureName : null;
		this.infrastructureDescript = i ? i.infrastructureDescript : null;
		this.roleMachineID = i ? i.roleMachineID : null;
		this.selectedAmount = 1;
		this.resourceID = 0;
		this.activated = false;
	}

}
