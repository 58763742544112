<div class="header center" [ngClass]="{
  'successColor': messageData.typeMsm == TypeMsm.success, 
  'dangerColor': messageData.typeMsm == TypeMsm.Error, 
  'warningColor': messageData.typeMsm == TypeMsm.warning || messageData.typeMsm == TypeMsm.confirm , 
  'primaryColor': messageData.typeMsm == TypeMsm.info
}">

  <i class="icon if2rt-modal-check iconoHeader" *ngIf="messageData.typeMsm == TypeMsm.success"></i>
  <i class="icon if2rt-modal-error iconoHeader" *ngIf="messageData.typeMsm == TypeMsm.Error"></i>
  <i class="icon if2rt-modal-alert iconoHeader" *ngIf="messageData.typeMsm == TypeMsm.warning || messageData.typeMsm == TypeMsm.confirm"></i>
  <i class="icon if2rt-filters iconoHeader" *ngIf="messageData.typeMsm == TypeMsm.info"></i>

</div>
<div class="alert show-front update center"
  [ngClass]="{
    'successColor': messageData.typeMsm == TypeMsm.success, 
    'dangerColor': messageData.typeMsm == TypeMsm.Error, 
    'warningColor': messageData.typeMsm == TypeMsm.warning || messageData.typeMsm == TypeMsm.confirm , 
    'primaryColor': messageData.typeMsm == TypeMsm.info
  }"
  role="alert">
  <div>
    <p class="center ptext">{{messageData.textMsm}}</p>
    <p class="center ptext footer">{{messageData.textFooter}}</p>
  </div>

  <div class="actions" *ngIf="messageData.typeMsm == TypeMsm.confirm">
    <button class="btn cancel" (click)="cancel()">Cerrar</button>
    <button class="btn primaryColor"
    [ngClass]="{
      'successColor': messageData.typeMsm == TypeMsm.success, 
      'dangerColor': messageData.typeMsm == TypeMsm.Error, 
      'warningColor': messageData.typeMsm == TypeMsm.warning || messageData.typeMsm == TypeMsm.confirm , 
      'primaryColor': messageData.typeMsm == TypeMsm.info
    }"
     (click)="continue()">Continuar</button>
  </div>
</div>