export class ToolType {

	/*1*/ 	public id: number;
	/*2*/ 	public toolName: string;
	/*3*/ 	public toolDescript: string;

	/*4*/ 	public selectedAmount: number;
	/*5*/ 	public resourceID: number;
	/*6*/ 	public edit: boolean;

	constructor(s ?: any) {
		this.id = s ? s.id : null;
		this.toolName = s ? s.toolName : null;
		this.toolDescript = s ? s.toolDescript : null;
		this.selectedAmount = 1;
		this.resourceID = 0;
	}

}
