<div class="card-machine__tab-card">
  <div class="tab-card" [ngStyle]="{
      'background-color': thisMachine.typeState.colorState
        ? '#' + thisMachine.typeState.colorState
        : '#548DD4',
      'border-top-right-radius': viewOnlyHeaders ? '0px' : '5px'
    }" [ngClass]="{ machineHoverSelected: styleOnRemoteHover }">
    <div class="tab-card__info" (click)="showHideCards()">
      <div class="tab-card__text">{{ thisMachine.callSing }}</div>
      <div class="tab-card__split-text"></div>
      <div class="tab-card__text">{{ thisMachine.fleet.fleetAcronim }}</div>
      <div class="icon if2rt-check-circle-solid tab-card__icon"
        *ngIf="isUpToDate(thisMachine.useHistoryAugmenteds[0]?.dateReport)">
        <div class="tooltip-tab">
          <div class="tooltip-tab__tail"></div>
          <div class="tooltip-tab__text">
            Última modificación el
            {{
            thisMachine.useHistoryAugmenteds[0]?.dateReport | date : "medium"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="tab-card__status">
      <div class="tab-card__iconContainer" [ngClass]="{'tab-card__iconContainer--active': showUseHistory}" *ngIf="typeView == 0" (click)="showHideUseHistory()">
        <i class="if2rt-flight-history"></i>
      </div>
      <div class="tab-card__status-text">
        {{ thisMachine.typeState.typeStateName }}
      </div>
      <div class="tab-card__status-checkbox" *ngIf="checkCard(thisMachine.id)" (click)="toggleChange()">
        <i class="icon if2rt-check-alone tab-card__status-check-icon"></i>
      </div>
      <div class="tab-card__status-checkbox" *ngIf="!checkCard(thisMachine.id)" (click)="toggleChange()"></div>
      <i class="icon if2rt-date icon-title-modal" (click)="updateReport()" *ngIf="pendingSave"></i>
    </div>
  </div>
</div>
<ng-container *ngIf="!hideData">
  <div class="card-machine__card-container">
    <div class="card-machine__header">
      <div class="header-card">
        <div class="header-card__img" (click)="redirectHelicopter(thisMachine.id)" [ngStyle]="{
            border: thisMachine.typeState
              ? '2px solid #' + thisMachine.typeState.colorState
              : '#548DD4'
          }">
          <i class="icon if2rt-{{
              thisMachine.fleet.fleetIco
            }} header-card__img-icon" (click)="redirectHelicopter(thisMachine.id)"
            [ngStyle]="{ color: '#' + thisMachine.typeState.colorState }"></i>
        </div>
        <div class="header-card__info-content">
          <div class="header-card__info-top">
            <div class="header-card__info-title">Ubicación:</div>
            <div class="header-card__info-airport">
              {{ thisMachine?.airport?.nameAirport }}
            </div>
            <div class="header-card__info-airport">
              {{ thisMachine?.departament?.regionName }}
            </div>
          </div>
          <div class="header-card__info-bottom">
            <div class="header-card__info-bottom-container">
              <div class="header-card__info-title">Unidad:</div>
              <div class="header-card__info-grey-box">
                {{ thisMachine.unit.unitAcronim }}
              </div>
            </div>
            <div class="header-card__info-bottom-container">
              <div class="header-card__info-title">Horas actuales:</div>
              <div class="header-card__info-grey-box">
                <i class="icon if2rt-clock header-card__info-grey-box-icon"></i>
                {{ _sharedService.toNumberFixed(thisMachine.totalHoursUse) }}
              </div>
            </div>
            <div class="header-card__info-bottom-container">
              <button class="header-card__green-button" (click)="redirectHistory(thisMachine.id)">
                Histórico
              </button>
            </div>
            <div class="header-card__info-bottom-container">
              <button class="header-card__green-button" (click)="activateModalMaintenance(thisMachine.id)">
                Ver más
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>