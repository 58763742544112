import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FailReport } from 'src/app/models/class/failReport';
import { Machine } from 'src/app/models/class/machine';
import { MaintHistory } from 'src/app/models/class/maintHistory';
import { MachineService } from 'src/app/services/machine.service';
import { MaintHistoryService } from 'src/app/services/mainthistory.service';
import { SharedService } from 'src/app/services/shared.service';


declare var $: any;

@Component({
  selector: 'progress-maint-history',
  templateUrl: './progress-maint-history.component.html',
  styleUrls: ['./progress-maint-history.component.sass']
})
export class ProgressMaintHistoryMenuComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: number

  public maintHistory: MaintHistory = new MaintHistory()
  failReportTasksDone: FailReport[] =[];
  totalProgressFailTaskDone: string = '0';

  constructor(
    private _maintHistoryService: MaintHistoryService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit() {
   // console.log("llego el id", this.id)
    this.loadData()
  }

  ngOnDestroy() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["id"]) {
      if (changes["id"].currentValue) {
        this.loadData()

      }
    }
  }
  loadData() {

    let filter = {
      include: [
        { relation: "machine", scope: { include: ["typeState", "fleet", "unit", "airport", "departament"] } },
        "maintCmpActivity",
        "user",
        "inspector",
        {relation: "failReports", scope: {   include: [ "failReportTasks"] }},
        {
          relation: "maintTaskHistories", scope: {
            include: [
              { relation: "maintTask", scope: { include: ["zoneMachine"] } },
              "userExec",
              "userTest"
            ]
          }
        }
      ]
    }

    this._maintHistoryService.getMaintHistoryById(this.id, JSON.stringify(filter)).pipe(take(1)).subscribe(
      data => {
        this.maintHistory = data;
        this.failReportTasksDone =  this.maintHistory.failReports.filter(x=> x.failReportTasks.length>0 &&  x.failReportTasks[0].dateEnd)
        this.totalProgressFailTaskDone = Number(  ( this.maintHistory.progress +    ((this.failReportTasksDone.length *100)/ this.maintHistory.failReports.length))/2).toFixed(2)

      

    
      },
      err => {

      }
    );
  }


  
  getTime(init, finish){

    var days;
    var totalDays;
    var percentage;
  
    if(init != null || finish != null){
        let dateFinish = new Date(finish).getTime();
        let dateInit = new Date(init).getTime();
        let currentDate = new Date().getTime();
  
        if(currentDate >= dateFinish) {
  
          totalDays = 100;
          days = 100;
          percentage = 100;
  
        } else {
          var timeBetween = dateFinish - dateInit;
          var timeBetweenCurrent = currentDate - dateInit;
          totalDays = timeBetween/(1000 * 60 * 60 * 24);
          days = timeBetweenCurrent/(1000 * 60 * 60 * 24);
          percentage = (days * 100) / totalDays;
          
          
          days <100 ? days= Number(days).toFixed(4): ''
          percentage <100 ? percentage= Number(percentage).toFixed(4): ''
          totalDays <100 ? totalDays= Number(totalDays).toFixed(4): ''


        }
      }

  
      return {days:days, percentage:percentage, totalDays:totalDays};
  
    }


}
