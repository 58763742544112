
<div class="progress__item"> <span class="text-progress">Tareas:</span>
  <div class="content-progress">
    <div class="__progress" [ngStyle]="{'width': maintHistory?.progressTime + '%'}"></div>
  </div>
  <span class="number-progress">{{maintHistory?.progressTime}}%</span>
</div>
<div class="progress__item"> <span class="text-progress">Hora Hombre:</span>
  <div class="content-progress">
    <div class="__progress" [ngStyle]="{'width': maintHistory?.progress + '%'}"></div>
  </div>
  <span class="number-progress">{{maintHistory?.progress}}%</span>
</div>
<div class="progress__item"> <span class="text-progress">Tiempo:</span>
  <div class="content-progress">
    <div class="__progress" [ngStyle]="{'width': getTime(maintHistory.dateInit,
    maintHistory.dateFinish).percentage + '%'}"></div>
  </div>
  <span class="number-progress">{{getTime(maintHistory?.dateInit,
    maintHistory?.dateFinish).percentage}}%</span>
</div>
<div class="progress__item"> <span class="text-progress">General:</span>
  <div class="content-progress">
    <div class="__progress" [ngStyle]="{'width': totalProgressFailTaskDone + '%'}"></div>
  </div>
  <span class="number-progress">{{totalProgressFailTaskDone}}%</span>
</div>