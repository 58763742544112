<div class="cardContent" [ngClass]="{'cardContent--lateralView': horizontalView}">
  <div class="cardContent--newWidh">
    <app-control-flight-hours [thisMachine]="data"></app-control-flight-hours>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[0]" [changeStyleTableView]="horizontalView" [changeStyle]="true"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[1]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[2]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[3]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
</div>
