import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const APP_ROUTES: Routes = [

  { path: "", pathMatch: "full", redirectTo: "public" },
  {
    path: "public",
    data: { breadcrumb: null, },
    loadChildren: () =>
      import("./pages/public/public.module").then(
        (m) => m.PublicModule
      ),
  },
  {
    path: "home",
    data: { breadcrumb: null, },
    loadChildren: () =>
      import("./pages/private/private.module").then(
        (m) => m.PrivateModule
      ),
  },
  { path: "**", redirectTo: "public/error" }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
