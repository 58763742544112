<div class="center task__item-loader" [ngClass]="{'gray': (colorItem % 2) == 0}" *ngIf="loaderData">
  <app-loader></app-loader>
</div>


<div class="task__item" [ngClass]="{'gray': (colorItem % 2) == 0}" *ngIf="!loaderData">
  <div class="content-top center">
    <span class="title-task color-borderHeader" *ngIf="service">{{service.maintCmpActName}}</span>
  </div>
  <div class="content-bottom">

    <div class="sub-container scroll-h">

      <div class="info-disposal center" *ngFor="let disposal of listSummary.disposals">
        <div class="center content-img-disposal">
          <i class="icon if2rt-disposal icon-disposal"></i>
        </div>
        <div class="data-disposal">
          <span class="name-disposal">Disposal Resumen <a class="content-summary">{{disposal.selectedAmount}} Und.</a> </span>
          <p class="description-disposal" [ngStyle]="{'-webkit-box-orient': 'vertical'}">{{disposal.nameDisposal}}</p>
          <small class="text-edit-resource" *ngIf="!disposal.edit && onlyRead==false" (click)="disposal.edit = true">Editar</small>
          <div class="content-edit-resource" *ngIf="disposal.edit">
            <input type="number" class="input-edit-resource" [(ngModel)]="disposal.selectedAmount">
            <a class="btn-edit-resource" (click)="saveResourceSummary('Disposal', disposal.id, disposal.selectedAmount); disposal.edit = false">Guardar</a>
          </div>                
        </div>
      </div>

      <div class="info-staffSkill center" *ngFor="let staffskill of listSummary.staffSkill">
        <div class="center content-img-staffSkill">
          <i class="icon if2rt-staff-skill icon-staffskill"></i>
        </div>
        <div class="data-staffSkill">
          <span class="name-staffSkill">{{staffskill.staffSkillName}} <a class="content-summary">{{staffskill.selectedAmount}} Und.</a> </span>
          <small class="description-staffSkill" [ngStyle]="{'-webkit-box-orient': 'vertical'}">{{staffskill.staffSkillDescript}}</small>
          <small class="text-edit-resource" *ngIf="!staffskill.edit && onlyRead==false" (click)="staffskill.edit = true">Editar</small>
          <div class="content-edit-resource" *ngIf="staffskill.edit">
            <input type="number" class="input-edit-resource" [(ngModel)]="staffskill.selectedAmount">
            <a class="btn-edit-resource" (click)="saveResourceSummary('StaffSkill', staffskill.id, staffskill.selectedAmount); staffskill.edit = false">Guardar</a>
          </div>                   
        </div>
      </div>

      <div class="info-manual center" *ngFor="let manual of listSummary.manuals">
        <div class="center content-img-manual">
          <i class="icon if2rt-manual icon-manual"></i>
        </div>
        <div class="data-manual">
          <span class="name-manual"> <a href="{{urlHttp}}/{{manual.manualLink}}" target="_blank">{{manual.manualName}}</a>   <a class="content-summary">{{manual.selectedAmount}} Und.</a> </span>
          <small class="description-manual" [ngStyle]="{'-webkit-box-orient': 'vertical'}">{{manual.manualDescript}}</small>
          <small class="text-edit-resource" *ngIf="!manual.edit && onlyRead==false" (click)="manual.edit = true">Editar</small>
          <div class="content-edit-resource" *ngIf="manual.edit">
            <input type="number" class="input-edit-resource" [(ngModel)]="manual.selectedAmount">
            <a class="btn-edit-resource" (click)="saveResourceSummary('Manual',manual.id, manual.selectedAmount); manual.edit = false">Guardar</a>
          </div>
        </div>
      </div>

      <div class="info-sparePart center" *ngFor="let spare of listSummary.sparePart">
        <div class="center content-img-sparePart">
          <i class="icon if2rt-spare-part icon-spare-part"></i>
        </div>
        <div class="data-sparePart">
          <span class="name-sparePart">{{spare.partName}} <a class="content-summary">{{spare.selectedAmount}} Und.</a> </span>
          <small class="description-sparePart" [ngStyle]="{'-webkit-box-orient': 'vertical'}">{{spare.partDescript}}</small>
          <small class="text-edit-resource" *ngIf="!spare.edit && onlyRead==false" (click)="spare.edit = true">Editar</small>
          <div class="content-edit-resource" *ngIf="spare.edit">
            <input type="number" class="input-edit-resource" [(ngModel)]="spare.selectedAmount">
            <a class="btn-edit-resource" (click)="saveResourceSummary('SparePart', spare.id, spare.selectedAmount); spare.edit = false">Guardar</a>
          </div>                  
        </div>
      </div>

      <div class="info-toolType center" *ngFor="let tooltype of listSummary.toolType">
        <div class="center content-img-toolType">
          <i class="icon if2rt-tool-type icon-tool-type"></i>
        </div>
        <div class="data-toolType">
          <span class="name-toolType">{{tooltype.toolName}} <a class="content-summary">{{tooltype.selectedAmount}} Und.</a> </span>
          <small class="description-toolType" [ngStyle]="{'-webkit-box-orient': 'vertical'}">{{tooltype.toolDescript}}</small>
          <small class="text-edit-resource" *ngIf="!tooltype.edit && onlyRead==false" (click)="tooltype.edit = true">Editar</small>
          <div class="content-edit-resource" *ngIf="tooltype.edit">
            <input type="number" class="input-edit-resource" [(ngModel)]="tooltype.selectedAmount">
            <a class="btn-edit-resource" (click)="saveResourceSummary('ToolType', tooltype.id, tooltype.selectedAmount); tooltype.edit = false">Guardar</a>
          </div>                  
        </div>
      </div>
    </div>
  </div>
</div>