<div class="cardContent" [ngClass]="{'cardContent--lateralView': horizontalView}">
  <div class="cardContent--newWidh">
    <app-new-control-flight-hours [thisMachine]="data"></app-new-control-flight-hours>
  </div>
  <div class="cardContent--newWidh">
    <new-app-status-annotations [thisUseHistoryAugmented]="data.controlleruse" [changeStyleTableView]="horizontalView" [changeStyle]="true"></new-app-status-annotations>
  </div>
  <!-- <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[1]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[2]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div>
  <div class="cardContent--newWidh">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[3]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div> -->
</div>
