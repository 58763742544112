<div class="large">
  <app-header-modal (isClose)="closeModal()" title="MANTENIMIENTO">
  </app-header-modal>

  <div class="subcontent-modal-maintenance scroll">
    <div class="content-info">
      <div class="center content-helicopter">
        <i
          class="icon if2rt-{{ machineModal?.fleet?.fleetIco }} icon-helicopter"
          [ngStyle]="{ color: '#' + machineModal?.controlleruse?.typeState?.colorState }"
        ></i>

        <div class="center content-data">
          <span class="text-title">{{ machineModal?.callSing }}</span>
          <span class="text-subtitle">{{
            machineModal?.fleet?.fleetAcronim
          }}</span>
        </div>
      </div>
      <span class="tetx-info"
        >Ubicación:
        <span class="text-data" *ngIf="machineModal?.controlleruse.length > 0">{{
          machineModal?.controlleruse[0]?.departament != undefined ? machineModal?.controlleruse[0]?.departament?.regionName : 
          machineModal?.controlleruse[0]?.airport != undefined ? machineModal?.controlleruse[0]?.airport?.nameAirport : 
          machineModal?.controlleruse[0]?.speciallocation != undefined ? machineModal?.controlleruse[0]?.speciallocation?.namelocation : ""
        }}</span></span
      >
      <span class="tetx-info"
        >Horas Voladas:
        <span class="text-data">{{
         this.totalHours | number
        }}</span></span
      >
      <span class="tetx-info"
        >Último registro:
        <span class="text-data" *ngIf="machineModal?.controlleruse.length > 0">{{
          machineModal?.controlleruse[0]?.datereport | date
        }}</span></span
      >
      <a *ngIf="!isToEditService"
        class="center btn-add-service"
        (click)="checkMaintCmpActivities('/home/services/assign')"
        >ASIGNAR SERVICIO</a
      >
      <a *ngIf="isToEditService"
        class="center btn-add-service"
        (click)="checkEditMaintCmpActivities()"
        >EDITAR SERVICIO</a
      >
      <!-- TEST DAVID -->
      <!-- <a class="center btn-add-service" (click)="checkMaintCmpActivities('/home/services/assignV2')">V2</a> -->
    </div>

    <div class="content-table">
      <div class="table-responsive scroll-h">
        <div class="center" *ngIf="loader">
          <app-loader class="table-loader"></app-loader>
        </div>
        <div *ngIf="!loader">
          <div class="services-tabs">
            <div class="services-tab" *ngFor="let tab of serviceTabs" (click)="changeServiceTab(tab.id)" [ngStyle]="{'color': serviceTab == tab.id ? '#0B1141' : '', 'background-color': serviceTab == tab.id ? '#D9E6D8' : ''}">{{tab.name | uppercase}}</div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="th-title">SERVICIO</th>
                <th scope="col" class="th-title">ÚLTIMO MANTENIMIENTO</th>
                <ng-container *ngFor="let element of this.listaTotalController">
                  <th scope="col" class="th-title th-title-btn">
                    {{ element.controllername | uppercase }}
                  </th>
                  <th scope="col" class="th-title">{{ element.controllername | uppercase }} VENCE</th>
                  <th scope="col" class="th-title">{{ element.controllername | uppercase }} CUMPLIDAS</th>
                  <th scope="col" class="th-title">{{ element.controllername | uppercase }} REMANENTE</th>
                </ng-container>

                <!-- <th scope="col" colspan="{{this.listaTotalController.length}}" class="multy-colums">
                  <div class="center container-colum">
                    <a class="title th-title">FRECUENCIA</a>
                  </div>
                  <div class="center container-colum split container-colum--frecuency">
                    <a *ngFor="let element of this.listaTotalController"
                      class="title th-title-btn"
                      >{{ element.controllername }}
                    </a>
                  </div>
                </th> -->
                <!-- <th scope="col" colspan="3" class="multy-colums">
                  <div class="center container-colum">
                    <a class="title th-title">FRECUENCIA</a>
                  </div>
                  <div class="container-colum split container-colum--frecuency">
                    <a
                      (click)="unitTBOSelected = unitTBO[1]"
                      class="title th-title-btn"
                      >DÍAS</a
                    >
                    <a
                      (click)="unitTBOSelected = unitTBO[0]"
                      class="title th-title-btn"
                      >HORAS
                    </a>
                    <a
                      (click)="unitTBOSelected = unitTBO[2]"
                      class="title th-title-btn"
                      >CICLOS</a
                    >
                  </div>
                </th> -->
                <th scope="col" class="th-title">ESTADO</th>
                <th scope="col" class="th-title">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.services">
                <td>
                  <div class="circle-check-fase activated" 
                    (click)="selectMaintCmpActivity(machineModal.id,item.id,item.systemTypeID)"
                    *ngIf="checkMaintCmpActivitySelected(machineModal.id, item.id)"></div>
                  <div class="circle-check-fase" 
                    (click)="selectMaintCmpActivity(machineModal.id,item.id,item.systemTypeID)"
                    *ngIf="!checkMaintCmpActivitySelected(machineModal.id, item.id)"></div>
                    <span class="text-name-fase">{{item.maintCmpActName}}</span>
                </td>
                <td>
                  {{
                    getLastMaintHistory(machineModal.id, item.id)?.dateFinish
                      | date
                  }}
                </td>
                <!-- Columnas dinamica -->
                <ng-container *ngFor="let element of this.listaTotalController">
                  <td style="width: 2%; text-align: center">
                    {{ this.selectCountController(element,item?.frecuences) }}
                  </td>  
                  <td style="text-align: center">{{ this.sumAsignadas(element,item?.frecuences) | number }}</td>
                  <td style="text-align: center">{{ this.sumCumplidas(element,item?.frecuences) | number }}</td>
                  <td style="text-align: center"></td>                  
                </ng-container>
                <!-- Columnas dinamica -->
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <!-- <tbody *ngIf="filterServicesByServiceTypeID(serviceTab).length > 0">
              <tr *ngFor="let service of filterServicesByServiceTypeID(serviceTab)">
                  <td>
                    <div
                      class="circle-check-fase activated"
                      *ngIf="
                        checkMaintCmpActivitySelected(machineModal.id, service.id)
                      "
                      (click)="
                        selectMaintCmpActivity(
                          machineModal.id,
                          service.id,
                          service.systemTypeID
                        )
                      "
                    ></div>
                    <div
                      class="circle-check-fase"
                      *ngIf="
                        !checkMaintCmpActivitySelected(machineModal.id, service.id)
                      "
                      (click)="
                        selectMaintCmpActivity(
                          machineModal.id,
                          service.id,
                          service.systemTypeID
                        )
                      "
                    ></div>
                    <span class="text-name-fase"
                      >{{ service.maintCmpActName }}
                    </span>
                  </td>
                  <td class="text-center">
                    {{
                      getLastMaintHistory(machineModal.id, service.id)?.dateFinish
                        | date
                    }}
                  </td>
                  <td class="text-center">{{ service.frequencyDaysUse }}</td>
                  <td class="text-center">{{ service.frequencyHoursUse }}</td>
                  <td class="text-center">{{ service.frequencyCycleUse }}</td>
                  <td class="text-center">
                    {{
                      getLastMaintHistory(machineModal.id, service.id)[
                        unitTBOSelected.machineName
                      ] *
                        1 +
                        service[unitTBOSelected.modelName] * 1 | number
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      getLastMaintHistory(machineModal.id, service.id)[
                        unitTBOSelected.machineName
                      ] | number
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      getLastMaintHistory(machineModal.id, service.id)[
                        unitTBOSelected.machineName
                      ] *
                        1 +
                        service[unitTBOSelected.modelName] * 1 -
                        machineModal[unitTBOSelected.currentName] * 1 | number
                    }}
                  </td>
                  <td>
                    <div
                      class="circle-state-fase"
                      *ngIf="
                        getLastMaintHistory(machineModal.id, service.id)[
                          unitTBOSelected.machineName
                        ] *
                          1 +
                          service[unitTBOSelected.modelName] * 1 -
                          machineModal[unitTBOSelected.currentName] * 1 <=
                        5
                      "
                    ></div>
                    <div
                      class="circle-state-fase yellow"
                      *ngIf="
                        getLastMaintHistory(machineModal.id, service.id)[
                          unitTBOSelected.machineName
                        ] *
                          1 +
                          service[unitTBOSelected.modelName] * 1 -
                          machineModal[unitTBOSelected.currentName] * 1 >
                          5 &&
                        getLastMaintHistory(machineModal.id, service.id)[
                          unitTBOSelected.machineName
                        ] *
                          1 +
                          service[unitTBOSelected.modelName] * 1 -
                          machineModal[unitTBOSelected.currentName] * 1 <=
                          10
                      "
                    ></div>
                    <div
                      class="circle-state-fase green"
                      *ngIf="
                        getLastMaintHistory(machineModal.id, service.id)[
                          unitTBOSelected.machineName
                        ] *
                          1 +
                          service[unitTBOSelected.modelName] * 1 -
                          machineModal[unitTBOSelected.currentName] * 1 >
                        10
                      "
                    ></div>
                  </td>
                  <td> -->
                    <!-- <a
                      placement="left"
                      ngbTooltip="Liberar Recursos"
                      *ngIf="getLastMaintHistory(machineModal.id, service.id)?.id"
                      class="center tbo-release"
                      (click)="
                        showModalResources(
                          getLastMaintHistory(machineModal.id, service.id)
                        )
                      "
                      ><i class="icon if2rt-compare"></i
                    ></a> -->
                  <!--</td>
              </tr>
            </tbody>-->
          </table>
          <!-- <div class="no-service-data" *ngIf="filterServicesByServiceTypeID(serviceTab).length <= 0">NO SE ENCONTRARON SERVICIOS</div> -->

          <div class="no-service-data" *ngIf="this.services.length <= 0">NO SE ENCONTRARON SERVICIOS</div>
        </div>
      </div>
    </div>

    <!--  Tabla Imprevistos -->
    <!-- <div class="content-table">
          <div class="table-responsive scroll-h">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="th-title">ANOTACIONES</th>
                  <th scope="col" class="th-title">FECHA & HORA <br> REPORTE</th>
                  <th scope="col" class="th-title">POSICIÓN</th>
                  <th scope="col" class="th-title">ATA</th>
                  <th scope="col" class="th-title">RESTRICCIÓN</th>
                  <th scope="col" class="th-title">RESPONSABLE</th>
                  <th scope="col" class="th-title">DIFIERE</th>
                  <th scope="col" class="th-title">ESTADO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="circle-check-fase"></div>
                    <span class="text-name-fase fz-9">ENGINE side LH; FWD trunnion; support P/N 205-060-020-007</span>
                  </td>
                  <td class="text-center fz-9">25/03/19 10:45 am</td>
                  <td class="text-center fz-9">LH / FWD</td>
                  <td class="text-center fz-9">71-00</td>
                  <td class="text-center fz-9">Carry foward 13-1</td>
                  <td class="text-center fz-9">José Luis Ávila</td>
                  <td class="text-center fz-9">22/04/2019</td>
                  <td class="text-center fz-9">Grave</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->

    <!-- Tabla Componentes -->
    <div class="container-info-table" *ngFor="let systemType of systemTypes">
      <span
        class="center text-hidden"
        (click)="systemType.showSystem = !systemType.showSystem"
      >
        <i class="fas fa-plus icon-more"></i> COMPONENTE -
        {{ systemType.systemName | uppercase }}</span
      >
      <div class="table-responsive scroll-h" *ngIf="systemType.showSystem">
        <table class="table table--component">
          <thead>
            <tr>
              <th scope="col" class="th-title">
                COMPONENTE - {{ systemType.systemName | uppercase }}
              </th>
              <th scope="col" class="th-title">
                Fecha último <br />
                mantenimiento
              </th>
              <th scope="col" colspan="3" class="multy-colums">
                <div class="center container-colum">
                  <a class="title th-title"> FRECUENCIA</a>
                </div>
                <div class="container-colum split">
                  <a class="title th-title">DÍAS</a>
                  <a class="title th-title">HORAS</a>
                  <a class="title th-title">CICLOS</a>
                </div>
              </th>
              <th scope="col" class="th-title">HORAS VENCE</th>
              <th scope="col" class="th-title">HORAS CUMPLIDAS</th>
              <th scope="col" class="th-title">HORAS REMANENTE</th>
              <th scope="col" class="th-title">ESTADO</th>
            </tr>
          </thead>
          <tbody *ngIf="filterServicesBySystemTypeID(systemType.id).length > 0; else noComponentServices">
            <tr *ngFor="let service of filterServicesBySystemTypeID(systemType.id)">
              <ng-container *ngIf="service.serviceTypeID == serviceTab">
                <td>
                  <div
                    class="circle-check-fase activated"
                    *ngIf="
                      checkMaintCmpActivitySelected(machineModal.id, service.id)
                    "
                    (click)="
                      selectMaintCmpActivity(
                        machineModal.id,
                        service.id,
                        service.systemTypeID
                      )
                    "
                  ></div>
                  <div
                    class="circle-check-fase"
                    *ngIf="
                      !checkMaintCmpActivitySelected(machineModal.id, service.id)
                    "
                    (click)="
                      selectMaintCmpActivity(
                        machineModal.id,
                        service.id,
                        service.systemTypeID
                      )
                    "
                  ></div>

                  <span class="text-name-fase">{{
                    service.maintCmpActName | uppercase
                  }}</span>
                </td>
                <td class="text-center">
                  {{
                    getLastMaintHistory(machineModal.id, service.id)?.dateFinish
                      | date
                  }}
                </td>
                <td class="text-center">{{ service?.frequencyDaysUse }}</td>
                <td class="text-center">{{ service?.frequencyHoursUse }}</td>
                <td class="text-center">{{ service?.frequencyCycleUse }}</td>
                <td class="text-center">
                  {{
                    getLastMaintHistory(machineModal.id, service.id)
                      ?.hoursUseStart *
                      1 +
                      service?.frequencyHoursUse * 1 | number
                  }}
                </td>
                <td class="text-center">
                  {{
                    getLastMaintHistory(machineModal.id, service.id)
                      ?.hoursUseStart | number
                  }}
                </td>
                <td class="text-center">
                  {{
                    getLastMaintHistory(machineModal.id, service.id)
                      ?.hoursUseStart *
                      1 +
                      service?.frequencyHoursUse * 1 -
                      machineModal?.totalHoursUse * 1 | number
                  }}
                </td>
                <td>
                  <div
                    class="circle-state-fase"
                    *ngIf="
                      getLastMaintHistory(machineModal.id, service.id)
                        ?.hoursUseStart *
                        1 +
                        service?.frequencyHoursUse * 1 -
                        machineModal?.totalHoursUse * 1 <=
                      5
                    "
                  ></div>
                  <div
                    class="circle-state-fase yellow"
                    *ngIf="
                      getLastMaintHistory(machineModal.id, service.id)
                        ?.hoursUseStart *
                        1 +
                        service?.frequencyHoursUse * 1 -
                        machineModal?.totalHoursUse * 1 >
                        5 &&
                      getLastMaintHistory(machineModal.id, service.id)
                        ?.hoursUseStart *
                        1 +
                        service?.frequencyHoursUse * 1 -
                        machineModal?.totalHoursUse * 1 <=
                        10
                    "
                  ></div>
                  <div
                    class="circle-state-fase green"
                    *ngIf="
                      getLastMaintHistory(machineModal.id, service.id)
                        ?.hoursUseStart *
                        1 +
                        service?.frequencyHoursUse * 1 -
                        machineModal?.totalHoursUse * 1 >
                      10
                    "
                  ></div>
                </td>
                <td>
                  <!-- <a
                    *ngIf="getLastMaintHistory(machineModal.id, service.id)?.id"
                    class="center tbo-release"
                    (click)="
                      showModalResources(
                        getLastMaintHistory(machineModal.id, service.id)
                      )
                    "
                    >Liberar Recursos</a
                  > -->
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        <!-- <div class="no-service-data" #noComponentServices>NO SE ENCONTRARON SERVICIOS EN ESTE COMPONENTE</div> -->
      </div>
    </div>

    <!-- <div class="container-info-table">
          <span class="center text-hidden"> <i class="fas fa-plus icon-more"></i> COMPONENTE - SISTEMA</span>
        </div> -->
  </div>
</div>
