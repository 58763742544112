import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs-compat';
import { Machine } from 'src/app/models/class/machine';
import { SharedService } from 'src/app/services/shared.service';
const today = new Date();
@Component({
  selector: 'app-card-hearder',
  templateUrl: './card-hearder.component.html',
  styleUrls: ['./card-hearder.component.sass']
})
export class CardHearderComponent implements OnInit {

  @Output() statusModalMaintenance: EventEmitter<number> = new EventEmitter<number>();
  @Output() showUseHistoryData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showInfoCards: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() thisMachine: Machine;
  @Input() hideData: boolean = true;
  @Input() viewOnlyHeaders: boolean = false;
  @Input() typeView: number;

  private machines: Machine[] = [];
  private subscriptionMachines: Subscription;
  public pendingSave: boolean = false;
  public showUseHistory: boolean = false;
  private subscriptionOnHoverRemote: Subscription;
  private subscriptionCloseAllHistories: Subscription;
  public callSingOnHoverSelected: string = '';
  public styleOnRemoteHover: boolean = false;

  constructor(
    private _router: Router,
    public _sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    // this.viewOnlyHeaders == true ? this.hideData = false : ''
    this.subscriptionMachines = this._sharedService.sharedMultiDailyHist.subscribe(data => {
      if (data.machines) {
        this.machines = data.machines
      }
    });
    if (this.typeView == 6) {
      this.subscriptionOnHoverRemote = this._sharedService.sharedStyleByRemoteHover.subscribe(data => {
        this.callSingOnHoverSelected = data;
        if (this.thisMachine.callSing == this.callSingOnHoverSelected) {
          this.styleOnRemoteHover = true;
          this.hideData = false;
        } else this.hideData = true;
        this.showInfoCards.emit(this.hideData);
      });
      this.subscriptionCloseAllHistories = this._sharedService.sharedCloseAllHistories.subscribe(data => {
        this.closeAllHistories();
      });
    }

    console.log(this.thisMachine)
  }
  ngOnDestroy() {
    this.subscriptionMachines.unsubscribe();
    if (this.typeView == 6) {
      this.subscriptionOnHoverRemote.unsubscribe();
      this.subscriptionCloseAllHistories.unsubscribe();
    }
  }

  redirectHelicopter(machineID: number) {
    this._router.navigate([`home/systems/${machineID}`]);
  }
  activateModalMaintenance(machineID: number): void {
    this.statusModalMaintenance.emit(machineID);
  }
  showHideUseHistory(): void {
    this.showUseHistory = !this.showUseHistory;
    this.showUseHistoryData.emit(true);
  }
  closeAllHistories(): void {
    if (this.showUseHistory) this.showHideUseHistory();
  }
  showHideCards(): void {
    this.hideData = !this.hideData;
    this.showInfoCards.emit(this.hideData);
  }
  redirectHistory(machineID: number) {
    this._router.navigate([`home/historical/${machineID}`]);
  }
  checkCard(machineId): boolean {
    if (this.machines.find(x => x.id == machineId)) return true;
    else return false;
  }
  toggleChange() {
    this._sharedService.nextBSmultiCardsSelected(this.thisMachine)
  }

  public isUpToDate(register:string){
    
    const lastregister = new Date(register)
    if(today.getFullYear() == lastregister.getFullYear())
      if(today.getMonth() == lastregister.getMonth())
        if( today.getDate()==lastregister.getDate())
          return true;
    return false;
  }

}
