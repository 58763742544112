<div class="filters">
  <div class="filters__item">
    <h5 class="title">FILTROS</h5>
  </div>
  <div class="filters__items">
      <div class="input-icon">
        <i class="fa fa-search input-icon__icon"></i
        ><input
          type="text"
          placeholder="Busqueda rápida..."
          class="input-icon__input"
          [(ngModel)]="searchMainTextFilter"
          (ngModelChange)="onFilterByMainSearcher()"
        />
      </div>
      <div>
        <button class="input-btn" (click)="clearFilter()">limpiar</button>
      </div>
  </div>
  <ng-container *ngIf="filteredIconsList.length > 0 || filtersToRender.length > 0">
    <div class="filters__descript">
      <small class="filters__descript-text">
      Si lo prefiere, realice una selección para iniciar la busqueda:
      </small>
    </div>
  </ng-container>

  <div class="filters__columns">
    <div class="column column--left">
      <div class="column__item"
        *ngFor="let filter of filtersToRender"
        (click)="fillCircles(filter.options, filter.id)">
        <label [for]="filter.label" class="column__item-title">
          {{ filter.label | titlecase }} </label>
            <!-- BUSCADOR -->
          <div class="input-icon --hover">
            <div *ngIf="filter.sort == 'asc'"  (click)="toggleSort(filter.id,'desc')" class="fa fa-sort-up input-icon__icon  input-icon__icon--hover"></div>
            <div *ngIf="filter.sort == 'desc'"   (click)="toggleSort(filter.id,'')" class="fa fa-sort-down input-icon__icon input-icon__icon--hover"></div>
            <div *ngIf="filter.sort == ''" (click)="toggleSort(filter.id,'asc')" class="fa fa-sort input-icon__icon input-icon__icon--hover"></div>

            <ng-container *ngFor="let val of filter.options" >
                <div 
                *ngIf="val.isSelected" class="input-icon__tag"   
                (click)="toggleSelected(val.key,filter.id)">{{val.value}}</div>
              </ng-container>
            <input
              type="text"
              placeholder="Buscar"
              class="input-icon__input"
              (keydown)="filterByIconOptionsList($event)"
            />
          </div>
      </div>
    </div>
    <!-- OPTIONS ICONS LIST -->
    <div class="column column--right">
      <div class="column__box-container">
        <ng-container *ngFor="let iconItem of filteredIconsList; let i = index">
          <div class="box-item"  
          [ngStyle]="{'animation':'slideInLeft 0.5s ease ' + (i*0.1) + 's'}" 
          [ngClass]="{'box-item--selected': iconItem.isSelected}" 
          (click)="toggleSelected(iconItem.key,filterIdSelected)">

            <div class="box-item__circle center">
              <i  *ngIf="!!iconItem.iconClass; else elseBlock" [class]="'if2rt-' + iconItem.iconClass + ' box-item__icon'"></i>
              <ng-template #elseBlock>
                <div class="box-item__word ">{{ iconItem.value | slice:0:3 | uppercase }}</div>
              </ng-template>
            </div>

            <span class="box-item__text">
              {{ iconItem.value | uppercase }}
            </span>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
