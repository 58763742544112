import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/Rx';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { SortPipe } from 'src/app/pipes/sort.pipe';
import { SharedService } from 'src/app/services/shared.service';

import { FilterType, IconFilter } from './filterType.model';

@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.sass'],
})
export class SideFilterComponent implements OnInit {

  searchMainTextFilter: string = '';
  private subscriptionRemoteFIlterByCallSing: Subscription;
  // private subscriptionRemoteFIlterByYellowCard: Subscription;

  filterIdSelected = '';
  filtersToRender: Array<FilterType> = [];

  _fulliconsList: Array<IconFilter> = [];
  filteredIconsList: Array<IconFilter> = [];

  _fullitemsList: any[] = [];
  filteredItemsList: any[] = [];


  columnsNumberIconsList: 1 | 2 = 1;

  @Input() propertiesToQuery: Array<string> = [];

  @Input()
  set dataToFilter(items: Array<any>) {
    this._fullitemsList = items;
    this.filteredItemsList = this._fullitemsList;
  }

  @Input()
  set filters(filters: Array<FilterType>) {
    this.filtersToRender = Object.assign([], filters);
    this.filtersToRender = this.sortBy(this.filtersToRender, 'asc', 'order');
  }

  @Output() onFiltered: EventEmitter<any[]> = new EventEmitter();

  constructor(
    private filterPipe: FilterPipe,
    private sortPipe: SortPipe,
    private _sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.subscriptionRemoteFIlterByCallSing = this._sharedService.sharedFilterByCallSing.subscribe(data => {
      if (data == '') this.clearFilter();
      else {
        this.searchMainTextFilter = data;
        this.onFilterByMainSearcher();
      }
    });
    // this.subscriptionRemoteFIlterByYellowCard = this._sharedService.sharedFilterByYellowCard.subscribe(data => {
    //   if (data) {
    //     let id = this.filtersToRender.find(x => x.label == 'Clasificación de Estado');
    //     let key = id.options.find(x => x.value == 'No Operativas');
    //     this.toggleSelected(key.key, id.id);
    //   }
    // });
  }

  ngOnDestroy() {
    this.subscriptionRemoteFIlterByCallSing.unsubscribe();
    // this.subscriptionRemoteFIlterByYellowCard.unsubscribe();
  }

  public fillCircles(filterOptions: Array<IconFilter>, filterId: string) {
    this.filterIdSelected = filterId;
    this._fulliconsList = filterOptions;
    this.filteredIconsList = filterOptions;
    if (this.filteredIconsList.length > 8) this.columnsNumberIconsList = 2;
  }
  public toggleSort(id: string, opt: 'asc' | 'desc'){

    const idxFilter = this.filtersToRender.findIndex(itm => {
      return itm.id == id
    })

    this.filtersToRender[idxFilter].sort = opt;

    this.filteredItemsList = this.sortBy(this.filteredItemsList , opt, this.filtersToRender[idxFilter].queryBy)
    this.onFiltered.emit(this.filteredItemsList);

  }

  public toggleSelected(key: number, id: string) {
    console.log('key: ',key);
    console.log('id: ',id);
    
    const idxFilter = this.filtersToRender.findIndex(itm => {
      return itm.id == id
    })

    const idxOption = this.filtersToRender[idxFilter].options.findIndex(itm => {
      return itm.key == key
    })

    this.filtersToRender[idxFilter].options[idxOption].isSelected =
      !this.filtersToRender[idxFilter].options[idxOption].isSelected;

    this.filtersToRender[idxFilter].selections = this.filtersToRender[idxFilter].options.filter(f => f.isSelected)

    this._fulliconsList = this.filtersToRender[idxFilter].options;
    this.filteredIconsList = this.filtersToRender[idxFilter].options;

    this.filterChanged();
  }


  public filterByIconOptionsList(event) {
    let queryText = event.target.value;
    if (queryText === '') {
      this.filteredIconsList = this._fulliconsList;
      return;
    }
    this.filteredIconsList = this.queryByString(
      this._fulliconsList,
      ['value'],
      queryText
    );
  }


  public onFilterByMainSearcher() {
    //console.log('filtro: ',this.searchMainTextFilter);
    if (this.searchMainTextFilter === '' || this._fullitemsList.length === 0) {
      this.filteredItemsList = this._fullitemsList;
      return;
    }
    if (this.propertiesToQuery.length === 0) return this._fullitemsList;
    this.filteredItemsList = this.queryByString(
      this._fullitemsList,
      this.propertiesToQuery,
      this.searchMainTextFilter
    );
    this.onFiltered.emit(this.filteredItemsList);
  }

  /**
   * Query by text filter
   */
  private queryByString(
    list: any[],
    propertiesQuery: string[],
    searchParameter: string
  ) {
    if (!searchParameter || !propertiesQuery) return list;
    return this.filterPipe.transform(list, propertiesQuery, searchParameter);
  }


  /**
   * filter by order
   */
  private sortBy(arrayData, orderType: 'asc' | 'desc', sortKey: string) {
    return this.sortPipe.transform(arrayData, orderType, sortKey);
  }



  clearFilter(){
    this.filteredItemsList = this._fullitemsList;

    this.filtersToRender.forEach(filter => {

      filter.options.forEach(option => {
        option.isSelected = false;
      });

      filter.selections = []
      
    });

    this._fulliconsList = [];
    this.filteredIconsList = [];

    this.filterChanged();

  //  this.onFiltered.emit(this.filteredItemsList);
  }

  filterChanged() {


    this.filteredItemsList = this._fullitemsList.filter(item => {
      let isMatch = false

      for ( const filter of this.filtersToRender){
        isMatch = false

        if (!filter.selections || filter.selections.length == 0) { isMatch = true }
        else {
          filter.selections.forEach(option => {
            if (item[filter.queryBy] == option.key) {
              isMatch = true;
            }
          });
        }

        if(!isMatch)
        {break}

      }

      return isMatch

    });



    this.onFiltered.emit(this.filteredItemsList);
  }













}
