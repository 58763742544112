<div class="cardContent" [ngClass]="{'cardContent--lateralView': horizontalView}">
  <div class="cardContent--newWidh">
    <app-new-control-flight-hours [thisMachine]="data"></app-new-control-flight-hours>
  </div>
  <!-- <div class="cardContent--newWidh">
    <div class="hoursToday" [ngClass]="{'hoursToday--lateralView': horizontalView}">
      <div class="hoursToday__tittle">
        <span>HORAS VOLADAS HOY</span>
        <span>{{ +data?.totalHoursToday | f2rtHour: 'decimals' }}</span>
      </div> 
      <div class="hoursToday__subtittle">
        <i class="if2rt-update"></i>
        <span>CICLOS</span>
      </div>
      <div class="hoursToday__content">
        <div class="hoursToday__content__item">
          <div class="center">{{ +data.useHistoryAugmenteds[0].cycleEnd | f2rtHour: 'decimals' }}</div>
          <div class="hoursToday__content__item__tittle">Encendido/Apagado</div>
        </div>
        <div class="hoursToday__content__item">
          <div class="center">{{ +data?.useHistoryAugmenteds[0].cycleLandingEnd | f2rtHour: 'decimals' }}</div>
          <div class="hoursToday__content__item__tittle">Aterrizajes</div>
        </div>
        <div class="hoursToday__content__item">
          <div class="center">{{ +data?.totalHoursUse | f2rtHour: 'decimals' }}</div>
          <div class="hoursToday__content__item__tittle">Horas Totales</div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="cardContent--newWidh">
    <div class="serviceTable">
      <table class="table">
        <thead>
          <tr class="serviceTable__rowColored">
            <td class="tittletable">SERVICIO</td>
            <td class="tittletable" colspan="3">HORAS</td>
          </tr>
          <tr>
            <td></td>
            <td>CUMPLIDAS</td>
            <td>VENCE</td>
            <td>FALTAN</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let maintHistory of data.maintHistories.slice(0,3)">
            <td>{{ maintHistory.maintCmpActivity.maintCmpActName }}</td>
            <td class="text-number">{{ maintHistory.hoursUseStart | f2rtHour: 'decimals' }}</td>
            <td class="text-number">{{ maintHistory.hoursUseNext | f2rtHour: 'decimals' }}</td>
            <td class="text-number" [ngClass]="{'text-number__timeStyle': ((maintHistory.hoursUseNext - data.totalHoursUse)<=5)}">{{ (maintHistory.hoursUseNext - data.totalHoursUse) | f2rtHour: 'decimals' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->
  <div class="cardContent--newWidh" [ngClass]="{'cardContent--verticalView': !horizontalView}">
    <new-app-status-annotations [thisUseHistoryAugmented]="data.controlleruse" [changeStyleTableView]="horizontalView" [changeStyle]="true"></new-app-status-annotations>
  </div>
  <!-- <div class="cardContent--newWidh" [ngClass]="{'cardContent--verticalView': !horizontalView}">
    <app-status-annotations [thisUseHistoryAugmented]="data.useHistoryAugmenteds[1]" [changeStyleTableView]="horizontalView"></app-status-annotations>
  </div> -->
</div>
