import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Machine } from 'src/app/models/class/machine';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from "moment";
import { ControllerPlan } from 'src/app/models/class/controllerPlan';
import { ControllerUsed } from 'src/app/models/class/controllerUsed';

@Component({
  selector: 'app-new-control-flight-hours',
  templateUrl: './new-control-flight-hours.component.html',
  styleUrls: ['./new-control-flight-hours.component.sass']
})

export class NewControlFlightHoursComponent implements OnInit,OnChanges {

  @Input() thisMachine: Machine;

  public dateSelectFilter: string[] = []

  public dateRange = {
    startDay: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    endDay: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
    firtDayI: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    finishDayF: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    dayNow: new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T"),
  };

  constructor(
    private _alert: MensajesService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.thisMachine){
      this.dateSelectFilter = (this._sharedService.getDateSelect() != undefined ? this._sharedService.getDateSelect().length > 0 ? this._sharedService.getDateSelect() : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")] : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")])

      this.thisMachine.hoursAsignedMonth = this.sumHoursAsignedMonth(this.thisMachine.controllerplan)
      this.thisMachine.hoursFlewMonth = this.sumHoursUsedMonth(this.thisMachine.controllerplan,this.thisMachine.controlleruse) 
      this.thisMachine.hoursDays = this.sumHoursUsedDay(this.thisMachine.controlleruse)
    }
  }
  

  sumHoursAsignedMonth(controllerplan: ControllerPlan[]) {
    // let tempo_data = controllerplan.filter(item => {
    //   return this._alert.date_without_UTC(item.datestart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") 
    //   && this._alert.date_without_UTC(item.dateend, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")
    //   && item.controllerid == 1 // 1 = Control de horas de vuelos
    // })   
    let hours: number = 0
    controllerplan?.forEach((data) => {
      hours = Number(hours) + Number(data.value)      
      data.controllerplanchanges?.forEach((element) => {
        if (element.status != "Eliminado") {
          if (element.status == "Adición" &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")) {
            hours = Number(hours) + Number(element.value);
          } else if (element.status == "Disminución" &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") >= this._alert.date_without_UTC(data.datestart, "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") <= this._alert.date_without_UTC(data.dateend, "YYYY-MM-DD")) {
            hours = Number(hours) + Number(element.value);
          }
        }
      })
    })
    return hours;
  }

  sumHoursUsedMonth(controllerplan: ControllerPlan[], controllerUsed: ControllerUsed[]) {
    let datas = controllerplan.filter(item => {
      return this._alert.date_without_UTC(item.datestart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") 
      && this._alert.date_without_UTC(item.dateend, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")
      && item.controllerid == 1 // 1 = Control de horas de vuelos
    })
    
    let hours: number = 0
    datas.forEach(d => {
      let tempo_data = controllerUsed.filter(item => {
        return this._alert.date_without_UTC(d.datestart, "YYYY-MM-DD") <= this._alert.date_without_UTC(item.datereport, "YYYY-MM-DD") 
        && this._alert.date_without_UTC(d.dateend, "YYYY-MM-DD") >= this._alert.date_without_UTC(item.datereport, "YYYY-MM-DD")
        && item.controllerid == 1 // 1 = Control de horas de vuelos
      })
      tempo_data.forEach((item) => {
        if(item.controllerid == 1){ // 1 =  control de horas de vuelos
          hours += Number(item.value) * 1;
        }
      });
    })

    if (datas.length == 0) {
      let tempo_data = controllerUsed.filter(item => {
        var firtDayI = new Date(new Date(this.dateSelectFilter[0]).getFullYear(), new Date(this.dateSelectFilter[0]).getMonth(), 1).toISOString()
        var finishDayF = new Date(new Date(this.dateSelectFilter[0]).getFullYear(), new Date(this.dateSelectFilter[0]).getMonth() + 1, 0).toISOString()
        return this._alert.date_with_UTC(new Date(item.datereport), "YYYY-MM-DD") >= this._alert.date_without_UTC(new Date(firtDayI), "YYYY-MM-DD") && this._alert.date_without_UTC(item.datereport, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(finishDayF), "YYYY-MM-DD")
      })
      tempo_data.forEach((item) => {
        hours += Number(item.value) * 1;
      });
    }
    return hours;
  }

  sumHoursUsedDay(controllerUsed: ControllerUsed[]) {
    let hours: number = 0
    let tempo_data = controllerUsed.filter(item => {
      return this.compareDate(this._alert.date_without_UTC(item.datereport, "DD MMMM YYYY"), this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "DD MMMM YYYY")) == 0
      && item.controllerid == 1 // 1 = Control de horas de vuelos
    })
    tempo_data.forEach((item) => {
        hours += Number(item.value) * 1;
    });
    return hours;
  }
  
  compareDate(dateTimeA: string, dateTimeB: string) {
    let value = 0 // A y B Es igual
    if (dateTimeA > dateTimeB) { // A es mayor
      value = 1
    }
    else if (dateTimeA < dateTimeB) { // B es mayor
      value = -1
    }
    return value;
  }

}
