import { Component, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';

// Services
import { MachineService } from '../../../services/machine.service';

import { Airport } from '../../../models/class/airport';
import { Departament } from '../../../models/class/departament';
import { take } from 'rxjs/operators';
import { Machine } from '../../../models/class/machine';
import { Region } from 'src/app/models/class/region';

@Component({
    selector: 'app-modal-pernocta',
    templateUrl: './pernocta.component.html',
    styleUrls: ['./pernocta.component.sass']
})
export class PernoctaComponent {

    public textFilterPernota: string = '';
    public listShowAirposts: Airport[] = [];
    public listShowDepartaments: Departament[] = [];
    public listShowRegions: Region[] = [];


    private listDepartaments: Departament[] = [];
    private listAirposts: Airport[] = [];
    private listRegions: Region[] = [];

    @Input() currentPernocta: { airport: Airport, departament: Departament };

    @Output() filterPerocta = new EventEmitter<{ airport: Airport, departament: Departament }>();
    @Output() stateModalPernota = new EventEmitter<boolean>();


    constructor(
        private _machineService: MachineService,
    ) {
        this.getAllAirposts();
        this.getAllDepartaments();



    }



    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentPernocta.currentValue) {
            if (changes.currentPernocta.currentValue.airport)
                this.textFilterPernota = changes.currentPernocta.currentValue.airport.nameAirport
            else if (changes.currentPernocta.currentValue.departament)
                this.textFilterPernota = changes.currentPernocta.currentValue.departament.regionName
        }

        // console.log(changes.currentPernocta.currentValue);
        // You can also use categoryId.previousValue and 
        // categoryId.firstChange for comparing old and new values

    }

    getAllAirposts(): void {
        this._machineService.getAllAirport().pipe(take(1)).subscribe(
            data => {
                this.listAirposts = data;
                let allAirport: Airport = new Airport();
                allAirport.id = -1;
                allAirport.nameAirport = 'Todas';
                this.listAirposts.unshift(allAirport);
            }
        );
    }

    getAllDepartaments(): void {
        this._machineService.getAllDepartament().pipe(take(1)).subscribe(
            data => {
                this.listDepartaments = data;
                let allDepartament: Departament = new Departament();
                allDepartament.id = -1;
                allDepartament.regionName = 'Todas';
                this.listDepartaments.unshift(allDepartament);
            }
        );
    }





    filterListPernota(): void {
        this.listShowAirposts = this.listAirposts.filter(a => {
            const regex = new RegExp(this.textFilterPernota.trim(), 'gi');
            let nameAirport = this.accentFold(a.nameAirport) + ' | ' + this.accentFold(a.IATA) + ' | ' + this.accentFold(a.OACI);
            return nameAirport.match(regex);
        });
        this.listShowDepartaments = this.listDepartaments.filter(d => {
            const regex = new RegExp(this.textFilterPernota.trim(), 'gi');
            return this.accentFold(d.regionName).match(regex);
        });

    }
    accentFold(inStr) {
        if (inStr)
            return inStr.replace(
                /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
                function (str, a, c, e, i, n, o, s, u, y, ae) {
                    if (a) return 'a';
                    if (c) return 'c';
                    if (e) return 'e';
                    if (i) return 'i';
                    if (n) return 'n';
                    if (o) return 'o';
                    if (s) return 's';
                    if (u) return 'u';
                    if (y) return 'y';
                    if (ae) return 'ae';
                }
            );
    }


    selectItemPernota(airport: Airport, departament: Departament, region: Region): void {
        if (airport)
            this.textFilterPernota = airport.nameAirport
        else
            this.textFilterPernota = departament.regionName


        this.filterPerocta.emit({ airport: airport, departament: departament });
        this.stateModalPernota.emit(false);

        this.listShowDepartaments = [];
        this.listShowAirposts = [];
    }

    changeStateModalPernota() {
        this.stateModalPernota.emit(false);
    }

}