<div class="license__item" *ngIf="typeView == 0">
  <div class="header">
    {{ license.title }}
  </div>

  <div class="body">
    <div class="container-fluid">
      <div class="row">
        <div class="information-left col-sm-12 col-md-8">
          <div class="information__item">
            <span class="title__item">Institución</span>
            <span class="value__item">{{ license.institute }}</span>
          </div>
          <div class="mt-3 information__item">
            <span class="title__item">Fechas</span>
            <div class="container-date">
              <div class="center date__item">
                <span class="value-date">{{ license.dateIssued | time }}</span>
                <span class="value-text">Obtención</span>
              </div>
              <div class="center date__item">
                <span class="value-date">{{ license.dateExpire | time }}</span>
                <span class="value-text">Expiración</span>
              </div>
            </div>
          </div>
          <div class="mt-3 information__item">
            <span class="title__item">Observaciones</span>
            <p class="text-observations">
              {{ license.descript }}
            </p>
          </div>
        </div>
        <div class="information-right col-sm-12 col-md-4">
          <div class="mt-3 license">
            <div
              class="action-preview"
              (click)="changeStatePreview(true)"
              data-toggle="modal"
              data-target="#modal-xl"
            ></div>

            <object
              id="my-node"
              width="100%"
              height="100px"
              type="application/pdf"
              [data]="linkLicense | urlSaniter"
              style="overflow: hidden; width: 100%; height: 99%"
            ></object>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="center footer">
    <span class="text-footer">Editar</span>
  </div>-->
</div>

<div class="license__item_2" *ngIf="typeView == 1">
  <div class="header">
    {{ license.title }}
  </div>

  <div class="body">
    <div class="information-left">
      <div class="information__item">
        <div class="container-date">
          <div class="center date__item">
            <span class="value-text">Vence</span>
            <span class="value-date">{{ license.dateExpire | time }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="information-right">
      <div class="license">
        <i
          class="icon if2rt-download icon-down action-preview"
          (click)="changeStatePreview(true)"
        ></i>
      </div>
    </div>
  </div>
</div>

<div
  class="center container-modal-preview"
  [ngClass]="{ activated: stateModalPreview }"
  (click)="closeModalPreview($event)"
>
  <div class="content-preview">
    <object
      id="my-node"
      width="100%"
      height="100%"
      type="application/pdf"
      [data]="linkLicense | urlSaniter"
      style="overflow: hidden; width: 100%; height: 99%"
    ></object>
  </div>
</div>

<!--
<button type="button" class="btn btn-default" data-toggle="modal" data-target="#modal-xl">
  Launch Extra Large Modal
</button>-->

<div class="modal fade" id="modal-xl">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Documento / Certificado / Licencia</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content-preview">
          <object
            id="my-node"
            width="100%"
            height="100%"
            type="application/pdf"
            [data]="linkLicense | urlSaniter"
            style="overflow: hidden; width: 100%; height: 99%"
          ></object>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
