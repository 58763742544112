import { Command } from "./command";

export class FleetFamily {

	/*1*/ 	public id: number;
	/*2*/ 	public fleetFamilyName: string;
	/*3*/ 	public fleetFamilyAcronim: string;
	/*4*/ 	public comandid: number;
	/*5*/ 	public fleetfamilyicono: string;
	
	public Command: Command

	constructor(f ?: any) {
		this.id = f ? f.id : null;
		this.fleetFamilyName = f ? f.fleetFamilyName : null;
		this.fleetFamilyAcronim = f ? f.fleetFamilyAcronim : null;

		this.comandid = f ? f.comandid : null;
		this.fleetfamilyicono = f ? f.fleetfamilyicono : null;

		this.Command = f ? f.Command : null;
	}

}
