import { Component, OnInit, Input } from '@angular/core';
import { take } from 'rxjs/operators';

// Models
import { RoleMachine } from './../../../models/class/roleMachine';
import { Fleet } from './../../../models/class/fleet';
import { Ata } from './../../../models/class/ata';
import { SystemType } from './../../../models/class/systemType';

// Services
import { SharedService } from './../../../services/shared.service';
import { MachineService } from './../../../services/machine.service';
import { ServiceService } from './../../../services/service.service';
import { FleetFamily } from 'src/app/models/class/fleetFamily';
import { Manufacture } from 'src/app/models/class/manufacture';
import { TypeMsm } from 'src/app/models/Interfaces/modalMessage';

@Component({
  selector: 'app-new-resource',
  templateUrl: './new-resource.component.html',
  styleUrls: ['./new-resource.component.sass']
})
export class NewResourceComponent implements OnInit {

  @Input() stateModal: boolean;
  @Input() resourceId: number; // 1 Disposal, 2 Manual, 3 InfraestructureType, 4 spart, 5 skill, 6 tool

  public listRoleMachine: RoleMachine[] = [];
  public listAtaMachine: Ata[] = [];
  public listFleetMachine: Fleet[] = [];
  public listSystemMachine: SystemType[] = [];

  public disposal: { name: string, role: {id: number, name: string}, description: string } = { name: '', role: {id: null, name: ''}, description: '' };
  public errorsDispoal: { name: boolean, role: boolean, description: boolean } = { name: false, role: false, description: false };


  public manual: { name: string, reference: string, file: File, link: string, role: {id: number, name: string}, description: string, fleetFamily: { id: number, name: string }, fleet: { id: number, name: string }, manufacture: { id: number, name: string }  } = { name: '', reference: '', file: null, link: '', role: {id: null, name: ''}, description: '', fleetFamily: { id: null, name: '' }, fleet: { id: null, name: '' }, manufacture: { id: null, name: '' }  };
  public errorsManual: { name: boolean, reference: boolean, link: boolean, role: boolean, description: boolean } = { name: false, reference: false, link: false, role: false, description: false };
  public fleets: Fleet[] = [];
  public fleetsFiltered: Fleet[] = [];
  public roleMachines: RoleMachine[] = [];
  public fleetFamilies: FleetFamily[] = [];
  public manufacturers: Manufacture[] = [];



  public infrastructure: { name: string, description: string } = { name: '', description: '' };
  public errorsInfrastructure: { name: boolean, description: boolean } = { name: false, description: false };

  public staffSkill: { name: string, description: string, role: {id: number, name: string} } = { name: '', description: '', role: {id: null, name: ''} };
  public errorsStaffSkill: { name: boolean, description: boolean, role: boolean } = { name: false, description: false, role: false };

  public sparePart: { name: string, number: number, description: string, role: {id: number, name: string}, ata: {id: number, name: string}, fleet: {id: number, name: string}, system: {id: number, name: string} } = { name: '', number: null, description: '', role: {id: null, name: ''}, ata: {id: null, name: ''}, fleet: {id: null, name: ''}, system: {id: null, name: ''} };
  public errorsSparePart: { name: boolean, number: boolean, description: boolean, role: boolean, ata: boolean, fleet: boolean, system: boolean } = { name: false, number: false, description: false, role: false, ata: false, fleet: false, system: false };

  public toolType: { name: string, description: string } = { name: '', description: '' };
  public errorsToolType: { name: boolean, description: boolean } = { name: false, description: false };

  constructor(
    private _sharedService: SharedService,
    private _machineService: MachineService,
    private _serviceService: ServiceService
  ) { }

  ngOnInit() {
    this.initData();
  }

/*********************************************** Get Data ***************************/
  initData(): void{
    this.getAllRoleMachine();
    this.getAllFleetMachine();
    this.getAllAtaMachine();
    this.getAllSystemMachine();
    if(!this.resourceId) {
      this.resourceId = 1;
    }
  }

  getAllRoleMachine(): void{
    this._machineService.getAllRoleMachine().pipe(take(1)).subscribe( data => { this.listRoleMachine = data; } );
  }

  getAllFleetMachine(): void{
    this._machineService.getAllFleet().pipe(take(1)).subscribe( data => { this.listFleetMachine = data; } );
  }

  getAllAtaMachine(): void{
    this._machineService.getAllAta().pipe(take(1)).subscribe( data => { this.listAtaMachine = data; } );
  }

  getAllSystemMachine(): void{
    this._machineService.getAllSystem().pipe(take(1)).subscribe( data => { this.listSystemMachine = data; } );
  }

/***************************************** Funciones de Emitter **********************************/

  closeModalEvent(e): void{
    if(e.target.className == 'center container-modal activated'){
      this._serviceService.emitterNewResource({state: false, typeResource: null, data: null});
    }
  }

/****************************************** Funciones para Disposal *********************************************/
  verifyFormDisposal(): boolean{
    if(this.verifyNameDisposal() && this.verifyRoleDisposal() && this.verifyDescritionDisposal()){
      return true;
    }else{
      return false;
    }
  }

  verifyNameDisposal(): boolean{
    if(this.disposal.name.trim() != ''){
      this.errorsDispoal.name = false;
      return true;
    }else{
      this.errorsDispoal.name = true;
      return false;
    }
  }


  verifyRoleDisposal(): boolean{
    if(this.disposal.role.id){
      this.errorsDispoal.role = false;
      return true;
    }else{
      this.errorsDispoal.role = true;
      return false;
    }
  }

  verifyDescritionDisposal(): boolean{
    if(this.disposal.description.trim() != ''){
      this.errorsDispoal.description = false;
      return true;
    }else{
      this.errorsDispoal.description = true;
      return false;
    }
  }

  saveDisposal(): void{
    if(this.verifyFormDisposal()){
      this._sharedService.changeStateModalLoader(true);
      let info: {
        nameDisposal: string,
        descript: string,
        roleMachineID: number
      } = {
        nameDisposal: this.disposal.name,
        descript: this.disposal.description,
        roleMachineID: this.disposal.role.id
      };
      this._serviceService.saveDisposal(info).pipe(take(1)).subscribe(
        data => {
          this._serviceService.emitterNewResource({state: false, typeResource: 1, data});
          this._sharedService.changeStateModalLoader(false);
          this.resetDataDisposal();
        },
        err => {
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  resetDataDisposal(): void{
    this.disposal = { name: '', role: {id: null, name: ''}, description: '' };
    this.errorsDispoal = { name: false, role: false, description: false };
  }



/****************************************** Funciones para Manual *********************************************/
  verifyFormManual(): boolean{
    if(this.verifyNameManual() && this.verifyReferenceManual() && this.verifyLinkManual() && this.verifyRoleManual() && this.verifyDescritionManual()){
      return true;
    }else{
      return false;
    }
  }

  verifyNameManual(): boolean{
    if(this.manual.name.trim() != ''){
      this.errorsManual.name = false;
      return true;
    }else{
      this.errorsManual.name = true;
      return false;
    }
  }

  verifyReferenceManual(): boolean{
    if(this.manual.reference.trim() != ''){
      this.errorsManual.reference = false;
      return true;
    }else{
      this.errorsManual.reference = true;
      return false;
    }
  }

  verifyLinkManual(): boolean{
    if(this.manual.link.trim() != ''){
      this.errorsManual.link = false;
      return true;
    }else{
      this.errorsManual.link = true;
      return false;
    }
  }

  verifyRoleManual(): boolean{
    if(this.manual.role.id){
      this.errorsManual.role = false;
      return true;
    }else{
      this.errorsManual.role = true;
      return false;
    }
  }

  verifyDescritionManual(): boolean{
    if(this.manual.description.trim() != ''){
      this.errorsManual.description = false;
      return true;
    }else{
      this.errorsManual.description = true;
      return false;
    }
  }

  saveManual(): void{
    if(this.verifyFormManual()){
      this._sharedService.changeStateModalLoader(true);
      this._serviceService.uploadFile(this.manual.file,'attachments/manualresource/upload').then( (data: any) => {
        if(data && data.result && data.result.files && data.result.files.file && data.result.files.file[0]){
          let info: {
            manualName: string,
            manualRef: string,
            manualLink: string,
            manualDescript: string,
            roleMachineID: number,
            fleetID:number,
          } = {
            manualName: this.manual.name,
            manualRef: this.manual.reference,
            manualLink: 'attachments/licencesinfo/download/' + data.result.files.file[0].name,
            manualDescript: this.manual.description,
            roleMachineID: this.manual.role.id,
            fleetID: this.manual.fleet.id
          };
          this._serviceService.saveManual(info).pipe(take(1)).subscribe(
            data => {
              this._serviceService.emitterNewResource({state: false, typeResource: 2, data});
              this._sharedService.changeStateModalLoader(false);
              this.resetDataManual();
            },
            err => {
              this._sharedService.changeStateModalLoader(false);
            }
          );
        }
      });
    }
  }

  setFile(e: any): void{
    if(e.target && e.target.files[0]){
      let ext: any = this._sharedService.getExtensionFile(e.target.files[0].name);
      if(ext && ext[0] == 'pdf'){
        this.manual.file = e.target.files[0];
        this.manual.link = e.target.files[0].name;
      }else{
        this._sharedService.changeStateModalMessage({typeMsm: TypeMsm.Error, titleMsm: 'Error!', textMsm: 'Archivo no valido.', textFooter: 'Para obtener más información, comuníquese con su administrador de sistema.'});
      }
    }
  }

  resetDataManual(): void{
    this.manual = { name: '', reference: '', file: null, link: '', role: {id: null, name: ''}, description: '', fleetFamily: { id: null, name: '' }, fleet: { id: null, name: '' }, manufacture: { id: null, name: '' }  };
    this.errorsManual = { name: false, reference: false, link: false, role: false, description: false };
  }


  getFleetsByRoleMacID(roleMachineID: number): void {
    this._sharedService.changeStateModalLoader(true);
    let filter: string = `{"include":["fleetFamily","manufacture"]}`;
    this._machineService.getAllFleetsByRoleMachine(roleMachineID, filter).pipe(take(1)).subscribe(data => {
      this.fleets = data;
      this.fleetFamilies = [];
      this.manufacturers = [];

      let map: any = new Map();
      let mapMan: any = new Map();
      for (const item of this.fleets.map(x => x.fleetFamily)) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          this.fleetFamilies.push(item);
        }
        for (const manufacturer of this.fleets.map(x => x.manufacture)) {
          if (!mapMan.has(manufacturer.id)) {
            mapMan.set(manufacturer.id, true);
            this.manufacturers.push(manufacturer);
          }
        }
      }

      this.fleetFamilies.sort(function (a, b) {
        return a.fleetFamilyName.localeCompare(b.fleetFamilyName);
      });
      this.manufacturers.sort(function (a, b) {
        return a.manufactureName.localeCompare(b.manufactureName);
      });
      this.fleets.sort(function (a, b) {
        return a.fleetName.localeCompare(b.fleetName);
      });

      this._sharedService.changeStateModalLoader(false);
    });
  }

  filterFleet() {
    this.fleetsFiltered = this.fleets.filter(x => x.roleMachineID == this.manual.role.id && x.manufactureID == this.manual.manufacture.id && x.fleetFamilyID == this.manual.fleetFamily.id);
  }


/****************************************** Funciones para Infraestructure *********************************************/
  verifyFormInfraestructure(): boolean{
    if(this.verifyNameInfraestructure() && this.verifyDescritionInfraestructure()){
      return true;
    }else{
      return false;
    }
  }

  verifyNameInfraestructure(): boolean{
    if(this.infrastructure.name.trim() != ''){
      this.errorsInfrastructure.name = false;
      return true;
    }else{
      this.errorsInfrastructure.name = true;
      return false;
    }
  }

  verifyDescritionInfraestructure(): boolean{
    if(this.infrastructure.description.trim() != ''){
      this.errorsInfrastructure.description = false;
      return true;
    }else{
      this.errorsInfrastructure.description = true;
      return false;
    }
  }

  saveInfraestructure(): void{
    if(this.verifyFormInfraestructure()){
      this._sharedService.changeStateModalLoader(true);
      let info: {
        infrastructureName: string,
        infrastructureDescript: string
      } = {
        infrastructureName: this.infrastructure.name,
        infrastructureDescript: this.infrastructure.description
      };
      this._serviceService.saveInfraestructure(info).pipe(take(1)).subscribe(
        data => {
          this._serviceService.emitterNewResource({state: false, typeResource: 3, data});
          this._sharedService.changeStateModalLoader(false);
          this.resetDataInfraestructure();
        },
        err => {
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  resetDataInfraestructure(): void{
    this.infrastructure = { name: '', description: '' };
    this.errorsInfrastructure = { name: false, description: false };
  }

/****************************************** Funciones para StaffSkill *********************************************/
  verifyFormStaffSkill(): boolean{
    if( this.verifyNameStaffSkill() && this.verifyRoleStaffSkill() && this.verifyDescritionStaffSkill() ){
      return true;
    }else{
      return false;
    }
  }

  verifyNameStaffSkill(): boolean{
    if(this.staffSkill.name.trim() != ''){
      this.errorsStaffSkill.name = false;
      return true;
    }else{
      this.errorsStaffSkill.name = true;
      return false;
    }
  }

  verifyDescritionStaffSkill(): boolean{
    if(this.staffSkill.description.trim() != ''){
      this.errorsStaffSkill.description = false;
      return true;
    }else{
      this.errorsStaffSkill.description = true;
      return false;
    }
  }


  verifyRoleStaffSkill(): boolean{
    if(this.staffSkill.role.id){
      this.errorsStaffSkill.role = false;
      return true;
    }else{
      this.errorsStaffSkill.role = true;
      return false;
    }
  }

  saveStaffSkill(): void{
    if(this.verifyFormStaffSkill()){
      this._sharedService.changeStateModalLoader(true);
      let info: {
        staffSkillName: string,
        staffSkillDescript: string,
        roleMachineID: number
      } = {
        staffSkillName: this.staffSkill.name,
        staffSkillDescript: this.staffSkill.description,
        roleMachineID: this.staffSkill.role.id
      };
      this._serviceService.saveStaffSkill(info).pipe(take(1)).subscribe(
        data => {
          this._serviceService.emitterNewResource({state: false, typeResource: 4, data});
          this._sharedService.changeStateModalLoader(false);
          this.resetDataStaffSkill();
        },
        err => {
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  resetDataStaffSkill(): void{
    this.staffSkill = { name: '', description: '', role: {id: null, name: ''} };
    this.errorsStaffSkill = { name: false, description: false, role: false };
  }

/****************************************** Funciones para SparePart *********************************************/
  verifyFormSparePart(): boolean{
    if( this.verifyNameSparePart() && this.verifyNumberSparePart() && this.verifyRoleSparePart() && this.verifyAtaSparePart() && this.verifyFleetSparePart() && this.verifySystemSparePart() && this.verifyDescritionSparePart() ){
      return true;
    }else{
      return false;
    }
  }

  verifyNameSparePart(): boolean{
    if(this.sparePart.name.trim() != ''){
      this.errorsSparePart.name = false;
      return true;
    }else{
      this.errorsSparePart.name = true;
      return false;
    }
  }

  verifyNumberSparePart(): boolean{
    if(this.sparePart.number){
      this.errorsSparePart.number = false;
      return true;
    }else{
      this.errorsSparePart.number = true;
      return false;
    }
  }

  verifyRoleSparePart(): boolean{
    if(this.sparePart.role.id){
      this.errorsSparePart.role = false;
      return true;
    }else{
      this.errorsSparePart.role = true;
      return false;
    }
  }

  verifyAtaSparePart(): boolean{
    if(this.sparePart.ata.id){
      this.errorsSparePart.ata = false;
      return true;
    }else{
      this.errorsSparePart.ata = true;
      return false;
    }
  }

  verifyFleetSparePart(): boolean{
    if(this.sparePart.fleet.id){
      this.errorsSparePart.fleet = false;
      return true;
    }else{
      this.errorsSparePart.fleet = true;
      return false;
    }
  }

  verifySystemSparePart(): boolean{
    if(this.sparePart.system.id){
      this.errorsSparePart.system = false;
      return true;
    }else{
      this.errorsSparePart.system = true;
      return false;
    }
  }

  verifyDescritionSparePart(): boolean{
    if(this.sparePart.description.trim() != ''){
      this.errorsSparePart.description = false;
      return true;
    }else{
      this.errorsSparePart.description = true;
      return false;
    }
  }

  saveSparePart(): void{
    if(this.verifyFormSparePart()){
      this._sharedService.changeStateModalLoader(true);
      let info: {
        partName: string,
        partNumber: number,
        partDescript: string,
        roleMachineID: number,
        fleetID: number,
        systemTypeID: number,
        ataTypeID: number,
      } = {
        partName: this.sparePart.name.trim(),
        partNumber: this.sparePart.number,
        partDescript: this.sparePart.description.trim(),
        roleMachineID: this.sparePart.role.id,
        fleetID: this.sparePart.fleet.id,
        systemTypeID: this.sparePart.system.id,
        ataTypeID: this.sparePart.ata.id,
      };
      this._serviceService.saveSparePart(info).pipe(take(1)).subscribe(
        data => {

          this._machineService.newPartNumber({partNumber: info.partNumber, sparePartID:data.id  }).pipe(take(1)).subscribe(newPart=>{});

          this._serviceService.emitterNewResource({state: false, typeResource: 5, data});
          this._sharedService.changeStateModalLoader(false);
          this.resetDataSparePart();
        },
        err => {
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  resetDataSparePart(): void{
    this.sparePart = { name: '', number: null, description: '', role: {id: null, name: ''}, ata: {id: null, name: ''}, fleet: {id: null, name: ''}, system: {id: null, name: ''} };
    this.errorsSparePart = { name: false, number: false, description: false, role: false, ata: false, fleet: false, system: false };
  }

/****************************************** Funciones para ToolType *********************************************/
  verifyFormToolType(): boolean{
    if( this.verifyNameToolType() && this.verifyDescritionToolType() ){
      return true;
    }else{
      return false;
    }
  }

  verifyNameToolType(): boolean{
    if(this.toolType.name.trim() != ''){
      this.errorsToolType.name = false;
      return true;
    }else{
      this.errorsToolType.name = true;
      return false;
    }
  }


  verifyDescritionToolType(): boolean{
    if(this.toolType.description.trim() != ''){
      this.errorsToolType.description = false;
      return true;
    }else{
      this.errorsToolType.description = true;
      return false;
    }
  }

  saveToolType(): void{
    if(this.verifyFormToolType()){
      this._sharedService.changeStateModalLoader(true);
      let info: {
        toolName: string,
        toolDescript: string
      } = {
        toolName: this.toolType.name.trim(),
        toolDescript: this.toolType.description.trim()
      };
      this._serviceService.saveToolType(info).pipe(take(1)).subscribe(
        data => {
          this._serviceService.emitterNewResource({state: false, typeResource: 6, data});
          this._sharedService.changeStateModalLoader(false);
          this.resetDataToolType();
        },
        err => {
          this._sharedService.changeStateModalLoader(false);
        }
      );
    }
  }

  resetDataToolType(): void{
    this.toolType= { name: '', description: '' };
    this.errorsToolType = { name: false, description: false };
  }

}
