import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaintHistory } from 'src/app/models/class/maintHistory';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.sass']
})
export class WorkOrderComponent implements OnInit {

  @Input() thisMaintHistory: MaintHistory[];

  constructor(
    private _router: Router,
  ) { }

  ngOnInit(): void {
  }

  goToEditWorkPackage(): void {
    let url = `/home/services/assign/${this.thisMaintHistory[0].workpackageID}`;
    this._router.navigate([url]);
  }

  getTime(init, finish) {
    var days;
    var totalDays;
    var daysNumber;
    var percentage;
    if (init != null || finish != null) {
      let dateFinish = new Date(finish).getTime();
      let dateInit = new Date(init).getTime();
      let currentDate = new Date().getTime();
      if (currentDate >= dateFinish) {
        totalDays = 100;
        days = 100;
        daysNumber = (dateFinish - dateInit)/(1000 * 60 * 60 * 24);
        percentage = 100;
      } else {
        var timeBetween = dateFinish - dateInit;
        var timeBetweenCurrent = currentDate - dateInit;
        totalDays = timeBetween/(1000 * 60 * 60 * 24);
        days = timeBetweenCurrent/(1000 * 60 * 60 * 24);
        percentage = (days * 100) / totalDays;
        daysNumber = totalDays
      }
    }
    return {days: days, percentage: percentage, totalDays: totalDays, daysNumber:daysNumber};
  }


  fromDateToNow(date) {
    var date1 = new Date(date);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Number(Number(Difference_In_Time / (1000 * 3600 * 24)).toFixed(0));
    return Difference_In_Days > 0 ? Difference_In_Days-1 : 0
  }

  fixDate(date) {
    let date02 = new Date(date);
   // date02.setDate(date02.getDate() + 1);
    return date02;
  }
}
