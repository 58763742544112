import { Component, OnInit, Input } from '@angular/core';
import { Machine } from 'src/app/models/class/machine'

@Component({
  selector: 'app-blue-card-machine',
  templateUrl: './blue-card-machine.component.html',
  styleUrls: ['./../machine-info.component.sass', './blue-card-machine.component.sass']
})
export class BlueCardMachineComponent implements OnInit {

  @Input() data: Machine;
  @Input() horizontalView: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
