import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Machine } from 'src/app/models/class/machine'

@Component({
  selector: 'app-new-green-card-machine',
  templateUrl: './new-green-card-machine.component.html',
  styleUrls: ['./../new-machine-info.component.sass', './new-green-card-machine.component.sass']
})
export class NewGreenCardMachineComponent implements OnInit {

  @Input() data: Machine;
  @Input() horizontalView: boolean = false;

  constructor(
    public sharedService: SharedService,
  ) {}

  ngOnInit() {
  }

}
