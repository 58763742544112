<router-outlet></router-outlet>

<!--*******************+  Incio de Modal Loader *****************-->
<div
  class="fullScreen center"
  [ngClass]="{ 'fullScreen--activated': modalLoaderState }"
>
  <app-loader *ngIf="modalLoaderState"></app-loader>
</div>
<!--*******************+  Fin de Modal Loader *****************-->

<!--*******************+  Incio de Modal Mesages *****************-->
<div
  class="fullScreen center"
  (click)="closeModal()"
  [ngClass]="{ 'fullScreen--activated': modalMessageState }"
>
  <app-message *ngIf="modalMessageState" [messageData]="dataMsm"></app-message>
</div>
<!--*******************+  Fin de Modal Mesages *****************-->

<!-- Cargador loading -->
<!-- <ngx-ui-loader
	bgsColor = "#80ba27"
	bgsOpacity = 0.5
	bgsPosition = "bottom-right"
	bgsSize = 60
	bgsType = "three-strings"
	blur = 4
	delay = 0
	fastFadeOut = true
	fgsColor = "#80ba27"
	fgsPosition = "center-center"
	fgsSize = 100
	fgsType = "three-strings"
	gap = 24
	logoPosition = "center-center"
	logoSize = 120
	logoUrl = "../../../../assets/Logo F2RT RGB_Vertical_Bluesvg.svg"
	masterLoaderId = "master"
	overlayBorderRadius = "0"
	overlayColor = "rgba(40 40 40 0.8)"
	pbColor = "#80ba27"
	pbDirection = "ltr"
	pbThickness = 3
	hasProgressBar = true
	text = ""
	textColor = "#FFFFFF"
	textPosition = "center-center"
	maxTime = -1
	minTime = 300
></ngx-ui-loader> -->

<!-- Cargador loading -->
<ngx-ui-loader
  bgsColor="#80ba27"
  bgsOpacity="0.5"
  bgsPosition="bottom-right"
  bgsSize="60"
  bgsType = "cube-grid"
  fgsType = "cube-grid"
></ngx-ui-loader>

