<ng-container *ngIf="viewFullInfo && machine?.typeState != undefined">
  <div class="machine-info-container" [ngClass]="{'machine-info-container-middle': modifyWitdh}">
    <div class="card-machine" [ngClass]="{'card-machine--horizontalView': horizontalView}">
      <app-new-card-hearder 
        [thisMachine]="machine"
        [hideData]="hideData"
        [viewOnlyHeaders]="viewOnlyHeaders" 
        [typeView]="typeView" 
        (statusModalMaintenance)="activateModalMaintenance($event)"
        (showUseHistoryData)="showHideHistiy($event)"
        (showInfoCards)="showHideCards($event)"
        (infoMachine)="getMachineToGeoLocalizar($event)"
      ></app-new-card-hearder>
      <ng-container *ngIf="!hideData">
        <div class="card-machine__card-container" >
          <div class="card-machine__card-info" *ngIf="!viewOnlyHeaders" [ngClass]="{'card-machine__card-info--scroll': scrollCard}">
            <app-new-green-card-machine  [data]="machine" [horizontalView]="horizontalView" *ngIf="machine?.typeState?.colorGroup == '1'"></app-new-green-card-machine>
            <app-new-blue-card-machine   [data]="machine" [horizontalView]="horizontalView" *ngIf="machine?.typeState?.colorGroup == '2'"></app-new-blue-card-machine>
            <app-new-yellow-card-machine [data]="machine" [horizontalView]="horizontalView" *ngIf="machine?.typeState?.colorGroup == '3'"></app-new-yellow-card-machine>
            <app-new-red-card-machine    [data]="machine" [horizontalView]="horizontalView" *ngIf="machine?.typeState?.colorGroup == '4'"></app-new-red-card-machine>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Historicos -->
    <!-- <div class="historyCard" *ngIf="showHistory">
      <app-hour-use-history [thisMachine]="data"></app-hour-use-history>
    </div> -->

  </div>
</ng-container>


<!-- Mantenimiento -->
<!-- <ng-container *ngIf="viewFullInfo">
  <app-full-info-machine [data]="data" (statusModalMaintenance)="activateModalMaintenance($event)"></app-full-info-machine>
</ng-container> -->
