import { Ata } from './ata';
import { TypeFail } from './typeFail';
import { FailState } from './failState';
import { SystemType } from './systemType';
import { FailReportTask } from './failReportTask';

export class FailReport {

	/*1*/ 	public id: number;
	/*2*/ 	public maintTaskHistoryID: number;
	/*3*/ 	public machineID: number;
	/*4*/ 	public maintHistoryID: number;
	/*5*/ 	public typeMelID: number;
	/*6*/ 	public typeFailID: number;
	/*7*/ 	public systemTypeID: number;
	/*8*/ 	public failStateID: number;
	/*9*/ 	public dateStar: Date;
	/*10*/ 	public dateEnd: Date;
	/*11*/ 	public duration: number;
	/*12*/ 	public userExecID: number;
	/*13*/ 	public descript: string;
	/*14*/ 	public ataTypeID: number;
	/*15*/ 	public subAtaID: number;
	/*16*/ 	public zoneMachineID: number;
	/*17*/ 	public isAproved: boolean;
	/*18*/ 	public userReportedID: number;
	/*19*/ 	public roundsAvailable: string;
	/*20*/ 	public accCode: string;
	/*21*/ 	public maintenanceLevel: string;
	/*22*/ 	public isClose: boolean;

	/*23*/ 	public ataType: Ata;
	/*24*/ 	public failState: FailState;
	/*25*/ 	public systemType: SystemType;
	/*26*/ 	public typeFail: TypeFail;
	/*27*/ 	public failReportTasks ?: FailReportTask[];


	constructor(u ?: any) {
		this.id = u ? u.id : null;
		this.maintTaskHistoryID = u ? u.maintTaskHistoryID : null;
		this.machineID = u ? u.machineID : null;
		this.maintHistoryID = u ? u.maintHistoryID : null;
		this.typeMelID = u ? u.typeMelID : null;
		this.typeFailID = u ? u.typeFailID : null;
		this.systemTypeID = u ? u.systemTypeID : null;
		this.failStateID = u ? u.failStateID : null;
		this.dateStar = u ? u.dateStar : null;
		this.dateEnd = u ? u.dateEnd : null;
		this.duration = u ? u.duration : null;
		this.userExecID = u ? u.userExecID : null;
		this.descript = u ? u.descript : null;
		this.ataTypeID = u ? u.ataTypeID : null;
		this.subAtaID = u ? u.subAtaID : null;
		this.zoneMachineID = u ? u.zoneMachineID : null;
		this.isAproved = u ? u.isAproved : null;
		this.userReportedID = u ? u.userReportedID : null;
		this.roundsAvailable = u ? u.roundsAvailable : null;
		this.accCode = u ? u.accCode : null;
		this.maintenanceLevel = u ? u.maintenanceLevel : null;
		this.isClose = u ? u.isClose : null;

		this.ataType = u ? u.ataType : null;
		this.failState = u ? u.failState : null;
		this.systemType = u ? u.systemType : null;
		this.typeFail = u ? u.typeFail : null;
	}
}
