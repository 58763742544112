import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { HttpService } from './http.service';

// Clases
import { TypeState } from './../models/class/typeState';
import { DailyReport } from './../models/class/dailyReport';

@Injectable({
  providedIn: 'root'
})
export class DailyReportService {

  constructor(
    private _httpService: HttpService
  ) { }


  // Funciones Propias
  getAllDailyReport(commandID: number, unitID: number, date: any): Observable<DailyReport[]> {

    let whereClause = '';

    if (unitID != 0)
      whereClause = `"where":{"fixedUnitID":${unitID}},`;

    let url: string = `Commands/${commandID}/DailyUseHistories?filter={"where": {"dateReport": "${date}"},
    "include":[{"relation":  "machine", "scope": { ${whereClause} "include": [
      {"relation": "maintHistories", "scope": {"order": "id DESC", "include":["maintCmpActivity"] }},
      {"relation": "typeState", "scope": { "include": {"relation": "stateName", "scope":  {"where":{"commandID":${commandID}} } }}},
     "fleet", "unit", "airport","departament"]}}]}`;
    return this._httpService.httpGet(url);
  }

  getDailyReport(unitID: number, id: number): Observable<DailyReport> {
    let url: string = `DailyUseHistories/${id}?filter={"include":[{"relation":  "machine", "scope": {
      "include": [
        {"relation": "typeState", "scope": { "include": {"relation": "stateName", "scope":  {"where":{"commandID":${unitID}} } }}},
       "fleet", "unit", "departament"]}}]}`;
    return this._httpService.httpGet(url);
  }

  updateReport(id: number, data: DailyReport): Observable<DailyReport> {
    let url: string = 'DailyUseHistories/' + id;
    return this._httpService.httpPatch(url, data);
  }

  getTypeStates(filter?: string): Observable<TypeState[]> {

    let url: string = 'TypeStates';
    if (filter)
      url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

}
