<div class="component-card">
  <div class="component-card__content component-card__content--left">
    <div class="component-label">
      <div class="component-label__title">ATA</div>
      <div class="component-label__descript" *ngIf="cmpData.ataType">{{cmpData.ataType.ataName.split(":")[0]}}</div>
      <div class="component-label__descript" *ngIf="!cmpData.ataType">N/A</div>
    </div>
    <div class="component-label">
      <div class="component-label__title">Typo</div>
      <div class="component-label__descript" *ngIf="cmpData.subAta">{{cmpData.subAta.subAtaName}}</div>
      <div class="component-label__descript" *ngIf="!cmpData.subAta">N/A</div>
    </div>
    <div class="component-label component-label--tooltip">
      <div class="component-label__title">PN</div>
      <div class="component-label__descript" *ngIf="cmpData.partNumber">{{cmpData.partNumber.split(",")[0]}}</div>
      <div class="component-label__descript" *ngIf="!cmpData.partNumber">N/A</div>
      <div class="tooltip-tab">
        <div class="tooltip-tab__text">
          {{cmpData.partNumber}}
        </div>
      </div>
    </div>
    <div class="component-label">
      <div class="component-label__title">S/N</div>
      <div class="component-label__descript" *ngIf="cmpData.serialNumber">{{cmpData.serialNumber}}</div>
      <div class="component-label__descript" *ngIf="!cmpData.serialNumber">N/A</div>
    </div>
  </div>
  <div class="component-card__division"></div>
  <div class="component-card__content component-card__content--center">
    <div class="contadores-content contadores-content--left">
      <div class="contador-label">
        <div class="contador-label__title">TSN</div>
        <div class="contador-label__descript">
          {{getCountDays(today, cmpData.dateFabric)}} - 
          {{cmpData.totalHoursUse ? cmpData.totalHoursUse : "—"}} - 
          {{cmpData.totalCycleUse ? cmpData.totalCycleUse : "—"}} - 
          {{cmpData.totalLandingUse ? cmpData.totalLandingUse : "—"}}
        </div>
      </div>
      <div class="contador-label">
        <div class="contador-label__title">TSLi</div>
        <div class="contador-label__descript">
          {{getCountDays(today, cmpData.machineHistories[0].dateStart)}} - 
          {{getSubstract(cmpData.totalHoursUse, cmpData.machineHistories[0].hoursStart)}} - 
          {{getSubstract(cmpData.totalCycleUse, cmpData.machineHistories[0].cycleStart)}} - 
          {{getSubstract(cmpData.totalLandingUse, cmpData.machineHistories[0].cycleLandingStart)}} 
        </div>
      </div>
      <div class="contador-label">
        <div class="contador-label__title">TSO</div>
        <div class="contador-label__descript">
          {{getCountDays(today, cmpData.dateOverHaul)}} - 
          {{getSubstract(cmpData.totalHoursUse, cmpData.overHaulHoursUse)}} - 
          {{getSubstract(cmpData.totalCycleUse, cmpData.overHaulCycleUse)}} - 
          {{getSubstract(cmpData.totalLandingUse, cmpData.overHaulLandingUse)}} 
        </div>
      </div>
    </div>
    <div class="contadores-content contadores-content--right">
      <div class="contadores-content__issues">
        <div class="contadores-content__issue contadores-content__issue--title" >CiclosC</div>
        <div class="contadores-content__issue contadores-content__issue--title">RinesC</div>
        <div class="contadores-content__issue contadores-content__issue--title">Fórmula</div>
      </div>
      <ng-container *ngIf="!editCard; else editCardTemplate">
        <div class="contadores-content__issues">
          <div class="contadores-content__issue contadores-content__issue--text">{{getSumValues(cmpData.CurrentCycleCustom, cmpData.TotalCycleCustom)}}</div>
          <div class="contadores-content__issue contadores-content__issue--text">{{getSumValues(cmpData.CurrentRinCustom, cmpData.TotalRinCustom)}}</div>
          <div class="contadores-content__issue contadores-content__issue--text">{{cmpData.FormulaCycle ? cmpData.FormulaCycle : 0}}</div>
        </div>
        <div class="contadores-content__issues">
          <div class="contadores-content__issue contadores-content__issue--text">{{getNameById(machines, cmpData.machineID, "machine")}}</div>
          <div class="contadores-content__issue contadores-content__issue--text">{{getNameById(componentsList, cmpData.componentID, "component")}}</div>
        </div>
      </ng-container>
      <ng-template #editCardTemplate>
        <div class="contadores-content__issues">
          <input class="contadores-content__issue contadores-content__issue--input" type="number" [(ngModel)]="editedComponent.CurrentCycleCustom"/>
          <input class="contadores-content__issue contadores-content__issue--input" type="number" [(ngModel)]="editedComponent.CurrentRinCustom"/>
          <input class="contadores-content__issue contadores-content__issue--input" type="number" [(ngModel)]="editedComponent.FormulaCycle"/>
        </div>
        <div class="contadores-content__issues">
          <select class="contadores-content__issue contadores-content__issue--select" [(ngModel)]="editedComponent.machineID">
            <option *ngFor="let machine of machines" [ngValue]="machine.id">{{machine.callSing}}</option>
          </select>
          <select class="contadores-content__issue contadores-content__issue--select" [(ngModel)]="editedComponent.componentID">
            <option *ngFor="let component of componentsList" [ngValue]="component.id">{{component.serialNumber}}</option>
          </select>
        </div>
      </ng-template>
      <div class="contadores-content__buttons">
        <a class="component-card-button component-card-button--left" *ngIf="editCard" (click)="editCardChange()">Cancelar</a>
        <a class="component-card-button component-card-button--empty" *ngIf="!editCard"></a>
        <a class="component-card-button component-card-button--middle" (click)="editCard ? saveCmpChanges() : editCardChange()">{{editCard ? "Guardar" : "Editar"}}</a>
        <a class="component-card-button component-card-button--right" (click)="activateModalMaintenance()">Más</a>
      </div>
    </div>
  </div>
  <div class="component-card__content component-card__content--right">
    <div class="component-card__services">
      <ng-container *ngIf="cmpData.maintHistories.length != 0">
        <div class="service-label" *ngFor="let maintHistory of cmpData.maintHistories">
          <div class="service-label__item service-label__item--name">{{maintHistory.maintCmpActivity.maintCmpActName}}</div>
          <div class="service-label__item service-label__item--faltan">{{(maintHistory.hoursUseNext - cmpData.totalHoursUse - cmpData.currentHoursUse).toFixed(2)}}</div>
          <div class="service-label__item service-label__item--ultimo">10-7-20</div>
        </div>
      </ng-container>
      <div class="no-services" *ngIf="cmpData.maintHistories.length == 0">No hay servicios</div>
    </div>
  </div>
</div>