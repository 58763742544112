<div class="center container-modal" [ngClass]="{'activated': stateModal}" (click)="closeModalEvent($event)">

  <div class="content-modal">

    <div class="header-form">
      <img src="https://storage.googleapis.com/f2rt-assets/Iconos/Logo_F2RT-negro.svg" class="logo-f2rt">
      <a class="text-title-header" *ngIf="resourceId == 1">Crear Disposal</a>
      <a class="text-title-header" *ngIf="resourceId == 2">Crear Manual</a>
      <a class="text-title-header" *ngIf="resourceId == 3">Crear InfraestructureType</a>
      <a class="text-title-header" *ngIf="resourceId == 4">Crear StaffSkill</a>
      <a class="text-title-header" *ngIf="resourceId == 5">Crear SparePart</a>
      <a class="text-title-header" *ngIf="resourceId == 6">Crear TollType</a>
    </div>

    <form class="form" *ngIf="resourceId == 1">
      <!-- Inicio Formulario para Disposal-->
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="disposal.name" (blur)="verifyNameDisposal()"
            (keyup)="verifyNameDisposal()" [ngClass]="{'error': errorsDispoal.name}" autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <div class="content_select" [ngClass]="{'error': errorsDispoal.role}">
            <span class="text-select" *ngIf="!disposal.role.id">Tipo de Máquina</span>
            <span class="text-select" *ngIf="disposal.role.id">{{disposal.role.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let role of listRoleMachine"
                (click)="disposal.role.id = role.id; disposal.role.name = role.roleMacName; errorsDispoal.role = false">
                {{role.roleMacName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="disposal.description"
            (blur)="verifyDescritionDisposal()" (keyup)="verifyDescritionDisposal()"
            [ngClass]="{'error': errorsDispoal.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveDisposal()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para Disposal-->

    <form class="form" *ngIf="resourceId == 2">
      <!-- Inicio Formulario para Manual-->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 center form-group form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="manual.name" (blur)="verifyNameManual()"
            (keyup)="verifyNameManual()" [ngClass]="{'error': errorsManual.name}" autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 center form-group form-group">
          <input type="text" class="form-control" name="reference" [(ngModel)]="manual.reference"
            (blur)="verifyReferenceManual()" (keyup)="verifyReferenceManual()"
            [ngClass]="{'error': errorsManual.reference}" autocomplete="off" required>
          <label class="label-placeholder">Referencia</label>
        </div>
      </div>


      <div class="row mt-3">

        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <label class="label-placeholder" *ngIf="previewSave">Tipo Máquina</label>
          <span class="text-preview" *ngIf="previewSave">{{manual.role.name}}</span>
          <div *ngIf="!previewSave" class="content_select" [ngClass]="{'error': errorsManual.role}">
            <span class="text-select" *ngIf="!manual.role.id">Tipo Máquina</span>
            <span class="text-select" *ngIf="manual.role.name">{{manual.role.name}}</span>
            <i class="fa fa-chevron-down icon-down" *ngIf="!previewSave"></i>
          </div>
          <div class="container-hover" *ngIf="!previewSave">
            <div class="content-hover scroll">
              <div class="select__item" *ngFor="let roleMachine of listRoleMachine"
                (click)="manual.role.id = roleMachine.id; manual.role.name=roleMachine.roleMacName; manual.manufacture.id=null; manual.manufacture.name='';  getFleetsByRoleMacID(roleMachine.id)">
                {{roleMachine.roleMacName}}</div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <label class="label-placeholder" *ngIf="previewSave">Fabricante</label>
          <span class="text-preview" *ngIf="previewSave">{{manual.manufacture.name  | uppercase }}</span>
          <div *ngIf="!previewSave" class="content_select" [ngClass]="{'error': errorsManual.manufacture}">
            <span class="text-select" *ngIf="!manual.role.id">Fabricante</span>
            <span class="text-select" *ngIf="manual.role.name">{{manual.manufacture.name  | uppercase }}</span>
            <i class="fa fa-chevron-down icon-down" *ngIf="!previewSave"></i>
          </div>
          <div class="container-hover" *ngIf="!previewSave">
            <div class="content-hover scroll">
              <div class="select__item" *ngFor="let manufacturer of manufacturers"
                (click)="manual.manufacture.id = manufacturer.id; manual.manufacture.name=manufacturer.manufactureName; filterFleet()">
                {{manufacturer.manufactureName  | uppercase }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <label class="label-placeholder" *ngIf="previewSave">Familia</label>
          <span class="text-preview" *ngIf="previewSave">{{manual.fleetFamily.name  | uppercase }}</span>
          <div *ngIf="!previewSave" class="content_select">
            <span class="text-select" *ngIf="!manual.fleetFamily.id">Familia</span>
            <span class="text-select" *ngIf="manual.fleetFamily.name">{{manual.fleetFamily.name  | uppercase }}</span>
            <i class="fa fa-chevron-down icon-down" *ngIf="!previewSave"></i>
          </div>
          <div class="container-hover" *ngIf="!previewSave">
            <div class="content-hover scroll">
              <div class="select__item" *ngFor="let fleetFamily of fleetFamilies"
                (click)="manual.fleetFamily.id = fleetFamily.id; manual.fleetFamily.name=fleetFamily.fleetFamilyName ;manual.fleet.id=null; manual.fleet.name='';  filterFleet()">
                {{fleetFamily.fleetFamilyName | uppercase}}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <label class="label-placeholder" *ngIf="previewSave">Flota</label>
          <span class="text-preview" *ngIf="previewSave">{{manual.fleet.name  | uppercase }}</span>
          <div *ngIf="!previewSave" class="content_select" [ngClass]="{'error': errorsManual.fleet}">
            <span class="text-select" *ngIf="!manual.fleet.id">Flota</span>
            <span class="text-select" *ngIf="manual.fleet.name">{{manual.fleet.name  | uppercase }}</span>
            <i class="fa fa-chevron-down icon-down" *ngIf="!previewSave"></i>
          </div>
          <div class="container-hover" *ngIf="!previewSave">
            <div class="content-hover scroll">
              <div class="select__item" *ngFor="let fleet of fleetsFiltered"
                (click)="manual.fleet.id = fleet.id; manual.fleet.name=fleet.fleetName">
                {{fleet.fleetName  | uppercase  }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12  center form-file">
          <a class="center btn-file"> Subir archivo <input type="file" class="input_file" (change)="setFile($event)"
              accept="application/pdf"> </a>
          <a class="text-file">{{manual.link}}</a>
          <span class="text-info-file">Archivo tipo .pdf. Mac Size 1MB</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="manual.description"
            (blur)="verifyDescritionManual()" (keyup)="verifyDescritionManual()"
            [ngClass]="{'error': errorsManual.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveManual()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para Manual-->

    <form class="form" *ngIf="resourceId == 3">
      <!-- Inicio Formulario para Infraestructure-->
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="infrastructure.name"
            (blur)="verifyNameInfraestructure()" (keyup)="verifyNameInfraestructure()"
            [ngClass]="{'error': errorsInfrastructure.name}" autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="infrastructure.description"
            (blur)="verifyDescritionInfraestructure()" (keyup)="verifyDescritionInfraestructure()"
            [ngClass]="{'error': errorsInfrastructure.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveInfraestructure()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para Infraestructure-->

    <form class="form" *ngIf="resourceId == 4">
      <!-- Inicio Formulario para StaffSkill-->
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="staffSkill.name"
            (blur)="verifyNameStaffSkill()" (keyup)="verifyNameStaffSkill()"
            [ngClass]="{'error': errorsStaffSkill.name}" autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <div class="content_select" [ngClass]="{'error': errorsStaffSkill.role}">
            <span class="text-select" *ngIf="!staffSkill.role.id">Tipo de Máquina</span>
            <span class="text-select" *ngIf="staffSkill.role.id">{{staffSkill.role.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let role of listRoleMachine"
                (click)="staffSkill.role.id = role.id; staffSkill.role.name = role.roleMacName; errorsStaffSkill.role = false">
                {{role.roleMacName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="staffSkill.description"
            (blur)="verifyDescritionStaffSkill()" (keyup)="verifyDescritionStaffSkill()"
            [ngClass]="{'error': errorsStaffSkill.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveStaffSkill()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para StaffSkill-->

    <form class="form" *ngIf="resourceId == 5">
      <!-- Inicio Formulario para SparePart-->
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="sparePart.name"
            (blur)="verifyNameSparePart()" (keyup)="verifyNameSparePart()" [ngClass]="{'error': errorsSparePart.name}"
            autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group form-help">
          <i class="icon if2rt-help-solid icon-list" placement="top" ngbTooltip="Parte Número"> </i>
          <input type="text" class="form-control" name="numero" [(ngModel)]="sparePart.number"
            (blur)="verifyNumberSparePart()" (keyup)="verifyNumberSparePart()"
            [ngClass]="{'error': errorsSparePart.number}" autocomplete="off" required>
          <label class="label-placeholder help">P/N</label>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <div class="content_select" [ngClass]="{'error': errorsSparePart.role}">
            <span class="text-select" *ngIf="!sparePart.role.id">Tipo de Máquina</span>
            <span class="text-select" *ngIf="sparePart.role.id">{{sparePart.role.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let role of listRoleMachine"
                (click)="sparePart.role.id = role.id; sparePart.role.name = role.roleMacName; errorsSparePart.role = false; getFleetsByRoleMacID(role.id)">
                {{role.roleMacName}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <div class="content_select" [ngClass]="{'error': errorsSparePart.ata}">
            <span class="text-select" *ngIf="!sparePart.ata.id">Ata</span>
            <span class="text-select" *ngIf="sparePart.ata.id">{{sparePart.ata.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let ata of listAtaMachine"
                (click)="sparePart.ata.id = ata.id; sparePart.ata.name = ata.ataName; errorsSparePart.ata = false">
                {{ata.ataName}}</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group">
          <div class="content_select" [ngClass]="{'error': errorsSparePart.fleet}">
            <span class="text-select" *ngIf="!sparePart.fleet.id">Flota</span>
            <span class="text-select" *ngIf="sparePart.fleet.id">{{sparePart.fleet.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let fleet of fleets"
                (click)="sparePart.fleet.id = fleet.id; sparePart.fleet.name = fleet.fleetName; errorsSparePart.fleet = false">
                {{fleet.fleetName}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 form-group">
          <div class="content_select" [ngClass]="{'error': errorsSparePart.system}">
            <span class="text-select" *ngIf="!sparePart.system.id">Sistema</span>
            <span class="text-select" *ngIf="sparePart.system.id">{{sparePart.system.name}}</span>
            <i class="fa fa-chevron-down icon-down"></i>
          </div>
          <div class="container-hover">
            <div class="content-hover">
              <div class="select__item" *ngFor="let system of listSystemMachine"
                (click)="sparePart.system.id = system.id; sparePart.system.name = system.systemName; errorsSparePart.system = false">
                {{system.systemName}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="sparePart.description"
            (blur)="verifyDescritionSparePart()" (keyup)="verifyDescritionSparePart()"
            [ngClass]="{'error': errorsSparePart.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveSparePart()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para SparePart-->

    <form class="form" *ngIf="resourceId == 6">
      <!-- Inicio Formulario para ToolType-->
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" name="name" [(ngModel)]="toolType.name" (blur)="verifyNameToolType()"
            (keyup)="verifyNameToolType()" [ngClass]="{'error': errorsToolType.name}" autocomplete="off" required>
          <label class="label-placeholder">Nombre</label>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 form-group">
          <label class="label">Descripción</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="toolType.description"
            (blur)="verifyDescritionToolType()" (keyup)="verifyDescritionToolType()"
            [ngClass]="{'error': errorsToolType.description}" required></textarea>
        </div>
      </div>
      <div class="center row mt-3">
        <a class="btn-save" (click)="saveToolType()">Confirmar</a>
      </div>
    </form> <!-- Fin Formulario para SparePart-->

  </div>

</div>