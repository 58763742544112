import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Machine } from 'src/app/models/class/machine'
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-yellow-card-machine',
  templateUrl: './new-yellow-card-machine.component.html',
  styleUrls: ['./../new-machine-info.component.sass','./new-yellow-card-machine.component.sass']
})
export class NewYellowCardMachineComponent implements OnInit {

  @Input() horizontalView: boolean = false;

  public machineItem: Machine;
  @Input() set data(item: Machine) {
    this.machineItem = item;
    this.machineItem.maintHistories = item.maintHistories.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0))
  }

  public infoTab:boolean = true;
  public serviceTabs = [
    {name: 'TASKCARD', id: 1, descript: 'Listas de tareas para cada una de las inspecciones o servicios.'},
    {name: 'TBO', id: 2, descript: '(Time Beteween Overhaul) Tiempo entre cada uno de los servicios mayores (Overhaul).'},
    {name: 'HARDTIME', id: 3, descript: 'Componente o pieza que se debe cambiar a determinado tiempo (horas, fecha).'},
    {name: 'TECHNICAL FEED', id: 5, descript: 'Publicaciones técnicas. Informes o documentacion de actualizaciones o recomendaciones, del fabricante o autoridades de vuelo nacionales o internacionales.'},
    {name: 'CUSTOM', id: 6, descript: 'Reportes de anotaciones que no son tan urgentes, que vienen de inspecciones anteriores.'},
    {name: 'ANOTACIONES', id: 4, descript: 'Publicaciones técnicas. Informes o documentacion de actualizaciones o recomendaciones, del fabricante o autoridades de vuelo nacionales o internacionales.'}
  ];
  public serviceTab: number;

  constructor(
    public sharedService: SharedService
  ) {
    this.serviceTab = this.serviceTabs[0].id;
  }

  ngOnInit() {
  }

  changeInfoTab(tab){
    this.infoTab = tab;
  }

  changeServiceTab(tab){
    this.serviceTab = tab;
  }

  /*-------------------------------Fin de Funciones Propias-------------------*/
  filterServicesByServiceTypeID(serviceTypeID: number) {
    if (this.machineItem.WorkPackages.length > 0)
      return this.machineItem.WorkPackages[0].MaintHistories.filter(
        (x) => x.maintCmpActivity.serviceTypeID === serviceTypeID
      );
    else return []
  }

}
