// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';

// import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc'
// import { SharedService } from '../shared.service';
// import { filter } from 'rxjs/operators';

// const authConfig: AuthConfig = {
//   issuer: "https://accounts.google.com",
//   strictDiscoveryDocumentValidation: false,
//   clientId: "68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com",
//   redirectUri: window.location.origin + "/public/verification",
//   scope: "openid profile email",
//   //showDebugInformation: true,

//   // useSilentRefresh: true,
//   // silentRefreshTimeout: 5000,
//   // timeoutFactor: 0.75,
//   // sessionChecksEnabled: true,
//   // clearHashAfterLogin: false,
// }

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGoogleService {

//   constructor(private oauthService: OAuthService, private _router: Router) {
//     this.oauthService.configure(authConfig)
//     this.oauthService.setupAutomaticSilentRefresh()
//     this.oauthService.loadDiscoveryDocumentAndTryLogin();

//     // Manejar eventos de OAuth
//     this.oauthService.events
//       .pipe(filter(e => e.type === 'token_received'))
//       .subscribe(() => {
//         console.log('Token received');
//       });

//     this.oauthService.events
//       .pipe(filter(e => e.type === 'session_terminated' || e.type === 'session_error'))
//       .subscribe(() => {
//         console.log('Session terminated or error');
//         this.oauthService.initLoginFlow();  // Iniciar el flujo de login si la sesión se termina
//       });

//     // Suscribirse a eventos de errores
//     this.oauthService.events.subscribe(e => {
//       if (e instanceof OAuthErrorEvent) {
//         console.error('OAuthErrorEvent:', e);
//       } else {
//         console.warn('OAuthEvent:', e);
//         if(e.type == "token_expires"){
//           //this._router.navigate(["/"])
//         }
//       }
//     });
//   }

//   initLogin() {
//     /*this.initOAuth().then((isLoggedIn: boolean) => {
//       if (isLoggedIn) {
//         console.log('User is logged in');
//       } else {
//         console.log('User is not logged in');
//         //this._router.navigate(["/"])
//         //this._shared.logOutUserWithoutGoogle()
//       }
//     }).catch(error => {
//       console.log("Error ->",error)
//       this._router.navigate(["/"])
//     });*/
//   }

//   public initOAuth(): Promise<boolean> {
//     return this.oauthService.loadDiscoveryDocumentAndTryLogin();
//   }

//   login() {
//     this.oauthService.initLoginFlow()
//   }

//   logout() {
//     this.oauthService.logOut()
//   }

//   getProfile() {
//     return this.oauthService.getIdentityClaims()
//   }

//   getToken() {
//     return this.oauthService.getIdToken()
//   }

// }


// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
// import { filter } from 'rxjs/operators';

// const authConfig: AuthConfig = {
//   issuer: 'https://accounts.google.com',
//   strictDiscoveryDocumentValidation: false,
//   clientId: '68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com',
//   redirectUri: window.location.origin + '/public/verification',
//   postLogoutRedirectUri: window.location.origin,  // Añadir la URL de redirección después del logout
//   scope: 'openid profile email',
//   // showDebugInformation: true,
// };

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGoogleService {
//   constructor(private oauthService: OAuthService, private _router: Router) {
//     this.oauthService.configure(authConfig);
//     this.oauthService.setupAutomaticSilentRefresh();
//     this.oauthService.loadDiscoveryDocumentAndTryLogin();

//     // Manejar eventos de OAuth
//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'token_received'))
//       .subscribe(() => {
//         //console.log('Token received');
//       });

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'session_terminated' || e.type === 'session_error'))
//       .subscribe(() => {
//         //console.log('Session terminated or error');
//         this.oauthService.initLoginFlow(); // Iniciar el flujo de login si la sesión se termina
//       });

//     // Suscribirse a eventos de errores
//     this.oauthService.events.subscribe((e) => {
//       if (e instanceof OAuthErrorEvent) {
//         //console.error('OAuthErrorEvent:', e);
//       } else {
//         //console.warn('OAuthEvent:', e);
//         if (e.type === 'token_expires') {
//           // this._router.navigate(['/'])
//         }
//       }
//     });
//   }

//   initLogin() {
//     /*this.initOAuth().then((isLoggedIn: boolean) => {
//       if (isLoggedIn) {
//         console.log('User is logged in');
//       } else {
//         console.log('User is not logged in');
//         // this._router.navigate(['/'])
//         // this._shared.logOutUserWithoutGoogle()
//       }
//     }).catch(error => {
//       console.log("Error ->",error)
//       this._router.navigate(['/'])
//     });*/
//   }

//   public initOAuth(): Promise<boolean> {
//     return this.oauthService.loadDiscoveryDocumentAndTryLogin();
//   }

//   login() {
//     this.oauthService.initLoginFlow();
//   }

//   logout() {
//     // Cerrar sesión en OAuthService
//     this.oauthService.logOut();

//     // Limpiar el almacenamiento local y de sesión
//     this.clearLocalStorage();

//     // Cerrar sesión en la cuenta de Google
//     this.logoutGoogleAccount();
//   }

//   clearLocalStorage() {
//     // Limpia cualquier dato relacionado con la autenticación del almacenamiento local
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('id_token');
//     localStorage.removeItem('refresh_token');
//     sessionStorage.removeItem('access_token');
//     sessionStorage.removeItem('id_token');
//     sessionStorage.removeItem('refresh_token');
//   }

//   logoutGoogleAccount() {
//     // Redirigir al usuario a la URL de logout de Google
//     window.location.href = 'https://accounts.google.com/Logout';
//   }

//   getProfile() {
//     return this.oauthService.getIdentityClaims();
//   }

//   getToken() {
//     return this.oauthService.getIdToken();
//   }
// }


// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
// import { filter } from 'rxjs/operators';

// const authConfig: AuthConfig = {
//   issuer: 'https://accounts.google.com',
//   strictDiscoveryDocumentValidation: false,
//   clientId: '68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com',
//   redirectUri: window.location.origin + '/public/verification',
//   postLogoutRedirectUri: window.location.origin,  // Añadir la URL de redirección después del logout
//   scope: 'openid profile email',
//   // showDebugInformation: true,
// };

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGoogleService {
//   constructor(private oauthService: OAuthService, private _router: Router) {
//     this.oauthService.configure(authConfig);
//     this.oauthService.setupAutomaticSilentRefresh();
//     this.oauthService.loadDiscoveryDocumentAndTryLogin();

//     // Manejar eventos de OAuth
//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'token_received'))
//       .subscribe(() => {
//         console.log('Token received');
//       });

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'session_terminated' || e.type === 'session_error'))
//       .subscribe(() => {
//         console.log('Session terminated or error');
//         this.oauthService.initLoginFlow(); // Iniciar el flujo de login si la sesión se termina
//       });

//     // Suscribirse a eventos de errores
//     this.oauthService.events.subscribe((e) => {
//       if (e instanceof OAuthErrorEvent) {
//         console.error('OAuthErrorEvent:', e);
//       } else {
//         console.warn('OAuthEvent:', e);
//         if (e.type === 'token_expires') {
//           // this._router.navigate(['/'])
//         }
//       }
//     });
//   }

//   initLogin() {
//     /*this.initOAuth().then((isLoggedIn: boolean) => {
//       if (isLoggedIn) {
//         console.log('User is logged in');
//       } else {
//         console.log('User is not logged in');
//         // this._router.navigate(['/'])
//         // this._shared.logOutUserWithoutGoogle()
//       }
//     }).catch(error => {
//       console.log("Error ->",error)
//       this._router.navigate(['/'])
//     });*/
//   }

//   public initOAuth(): Promise<boolean> {
//     return this.oauthService.loadDiscoveryDocumentAndTryLogin();
//   }

//   login() {
//     this.oauthService.initLoginFlow();
//   }

//   logout() {
//     // Revocar el token y cerrar sesión
//     this.oauthService.revokeTokenAndLogout();
//     // Limpiar el almacenamiento local y de sesión
//     this.clearLocalStorage();
//     // Redirigir a la página de inicio después de cerrar sesión
//     this._router.navigate(['/']);
//   }

//   clearLocalStorage() {
//     // Limpia cualquier dato relacionado con la autenticación del almacenamiento local
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('id_token');
//     localStorage.removeItem('refresh_token');
//     sessionStorage.removeItem('access_token');
//     sessionStorage.removeItem('id_token');
//     sessionStorage.removeItem('refresh_token');
//   }

//   getProfile() {
//     return this.oauthService.getIdentityClaims();
//   }

//   getToken() {
//     return this.oauthService.getIdToken();
//   }

//   isTokenValid(): boolean {
//     // const accessToken = this.oauthService.getAccessToken();
//     // return accessToken && !this.oauthService.hasValidAccessToken();
//     this.printTokenExpiration()
//     const accessToken = this.oauthService.getAccessToken();
//     return !!accessToken && this.oauthService.hasValidAccessToken();
//   }

//   printTokenExpiration() {
//     // const expirationDate = this.oauthService.getAccessTokenExpiration();
//     // console.log('Token expiration:', expirationDate);

//     //const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     //const expirationDate = new Date(expirationTimestamp);
//     // console.log('Token expiration:', expirationDate.toLocaleString());

//     const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     const expirationDate = new Date(expirationTimestamp);
//     console.log('Token expiration:', expirationDate.toLocaleString());
//     const currentTime = new Date();
//     const timeRemaining = expirationDate.getTime() - currentTime.getTime();

//     const seconds = Math.floor(timeRemaining / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);

//     console.log(`Token expires in ${hours} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`);
//   }
// }

// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
// import { filter } from 'rxjs/operators';

// // import axios from 'axios';

// const authConfig: AuthConfig = {
//   issuer: 'https://accounts.google.com',
//   strictDiscoveryDocumentValidation: false,
//   clientId: '68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com',
//   redirectUri: window.location.origin + '/public/verification',
//   postLogoutRedirectUri: window.location.origin,  // Añadir la URL de redirección después del logout
//   scope: 'openid profile email',
//   useSilentRefresh: true,  // Asegúrate de habilitar el refresh silencioso
//   silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html', // Redirecciona a un archivo HTML para el refresh silencioso
//   //timeoutFactor: 0.75, // Tiempo antes de la expiración para iniciar el refresh
//   showDebugInformation: true,  // Habilitar para depuración si es necesario
//   requireHttps: true, // O false si quieres permitir HTTP
//   timeoutFactor: 0.9,  // Inicia el refresh antes de la expiración
//   sessionChecksEnabled: true,  // Habilita comprobaciones de sesión
// };

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGoogleService {
//   private tokenCheckInterval: any; // To store the interval ID
//   private tokenRefreshThreshold = (1000 * 60) * 55; // 5 minutes (in milliseconds)

//   constructor(private oauthService: OAuthService, private _router: Router) {
//     this.oauthService.configure(authConfig);
//     this.oauthService.setupAutomaticSilentRefresh();
//     this.oauthService.loadDiscoveryDocumentAndTryLogin();

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'token_received' || e.type === 'token_expires'))
//       .subscribe((event) => {
//         if (event.type === 'token_received') {
//           console.log('Token received');
//         } else if (event.type === 'token_expires') {
//           console.log('Token is about to expire');
//           this.handleTokenExpiration();
//         }
//       });

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'session_terminated' || e.type === 'session_error'))
//       .subscribe(() => {
//         console.log('Session terminated or error');
//         this.logout(); // Optionally logout if session is terminated
//       });

//     // Handle OAuth errors
//     this.oauthService.events.subscribe((e) => {
//       if (e instanceof OAuthErrorEvent) {
//         console.log('OAuthErrorEvent:', e);
//       }
//     });

//     // Manejar eventos de OAuth
//     // this.oauthService.events
//     //   .pipe(filter((e) => e.type === 'token_received'))
//     //   .subscribe(() => {
//     //     console.log('Token received');
//     //   });

//     // this.oauthService.events
//     //   .pipe(filter((e) => e.type === 'session_terminated' || e.type === 'session_error'))
//     //   .subscribe(() => {
//     //     console.log('Session terminated or error');
//     //     this.oauthService.initLoginFlow(); // Iniciar el flujo de login si la sesión se termina
//     //   });

//     // // Suscribirse a eventos de errores
//     // this.oauthService.events.subscribe((e) => {
//     //   if (e instanceof OAuthErrorEvent) {
//     //     console.log('OAuthErrorEvent:', e);
//     //   } else {
//     //     //console.warn('OAuthEvent:', e);
//     //     if (e.type === 'token_expires') {
//     //       console.log('Token is about to expire');
//     //       this.handleTokenExpiration();
//     //     }
//     //   }
//     // });

//     // Start the token verification interval
//     this.startTokenCheckInterval();
//   }

//   ngOnDestroy() {
//     // Clear the interval when the service is destroyed
//     if (this.tokenCheckInterval) {
//       clearInterval(this.tokenCheckInterval);
//     }
//   }

//   private startTokenCheckInterval() {
//     // Check the token every minute
//     this.tokenCheckInterval = setInterval(() => {
//       this.handleTokenExpiration();
//     }, 60 * 1000); // 60 seconds
//   }

//   private handleTokenExpiration() {
//     const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     const currentTime = new Date().getTime();
//     const timeRemaining = expirationTimestamp - currentTime;
//     console.log("============================",timeRemaining, this.tokenRefreshThreshold)
//     if (timeRemaining <= this.tokenRefreshThreshold) {
//       // const refreshToken = this.oauthService.getRefreshToken();
//       // console.log('Estado del token',refreshToken);
//       // if (!refreshToken) {
//       //   console.error('No refresh token available.');
//       //   this.logout();
//       //   return;
//       // }
//       console.log('Refreshing token...');
//       this.silentRefresh();
//     } else {
//       //console.log(`Token expires in ${timeRemaining / 1000} seconds.`);
//       this.printTokenExpiration()
//     }
//   }

//   private refreshToken() {
//     this.oauthService.refreshToken()
//       .then(() => {
//         console.log('Token refreshed successfully');
//       })
//       .catch(error => {
//         console.log('Error refreshing token:', error);
//         this.logout()
//       });
//   }

//   private silentRefresh() {
//     this.oauthService.silentRefresh()
//       .then(() => {
//         console.log('Silent refresh successful');
//       })
//       .catch((error) => {
//         if (error === 'interaction_required') {
//           console.warn('User interaction is required to refresh token.');
//           this.login(); // Prompt the user to log in again
//         } else {
//           console.error('Silent refresh failed:', error);
//           this.logout(); // Logout if the refresh fails
//         }
//       });
//   }

//   initLogin() {
//     /*this.initOAuth().then((isLoggedIn: boolean) => {
//       if (isLoggedIn) {
//         console.log('User is logged in');
//       } else {
//         console.log('User is not logged in');
//         // this._router.navigate(['/'])
//         // this._shared.logOutUserWithoutGoogle()
//       }
//     }).catch(error => {
//       console.log("Error ->",error)
//       this._router.navigate(['/'])
//     });*/
//   }

//   public initOAuth(): Promise<boolean> {
//     return this.oauthService.loadDiscoveryDocumentAndTryLogin();
//   }

//   login() {
//     this.oauthService.initLoginFlow();
//   }

//   logout() {
//     // Revocar el token y cerrar sesión
//     this.oauthService.revokeTokenAndLogout();
//     // Limpiar el almacenamiento local y de sesión
//     this.clearLocalStorage();
//     // Clear the interval when the service is destroyed
//     if (this.tokenCheckInterval) {
//       clearInterval(this.tokenCheckInterval);
//     }
//     // Redirigir a la página de inicio después de cerrar sesión
//     this._router.navigate(['/']);
//   }

//   clearLocalStorage() {
//     // Limpia cualquier dato relacionado con la autenticación del almacenamiento local
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('id_token');
//     localStorage.removeItem('refresh_token');
//     sessionStorage.removeItem('access_token');
//     sessionStorage.removeItem('id_token');
//     sessionStorage.removeItem('refresh_token');
//   }

//   getProfile() {
//     return this.oauthService.getIdentityClaims();
//   }

//   async getToken() {
//     return this.oauthService.getIdToken();
//   }

//   isTokenValid(): boolean {
//     this.printTokenExpiration()
//     const accessToken = this.oauthService.getAccessToken();
//     return !!accessToken && this.oauthService.hasValidAccessToken();
//   }

//   printTokenExpiration() {
//     const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     const expirationDate = new Date(expirationTimestamp);
//     console.log('Token expiration:', expirationDate.toLocaleString());
//     const currentTime = new Date();
//     const timeRemaining = expirationDate.getTime() - currentTime.getTime();

//     const seconds = Math.floor(timeRemaining / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);

//     console.log(`Token expires in ${hours} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`);
//   }

// }





import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { IndexService } from '../index.service';

const authConfig: AuthConfig = {
  issuer: "https://accounts.google.com",
  strictDiscoveryDocumentValidation: false,
  clientId: "68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com",
  redirectUri: window.location.origin + "/public/verification",
  scope: "openid profile email",
  //showDebugInformation: true,

  // useSilentRefresh: true,
  // silentRefreshTimeout: 5000,
  // timeoutFactor: 0.75,
  // sessionChecksEnabled: true,
  // clearHashAfterLogin: false,
}

@Injectable({
  providedIn: 'root',
})
export class AuthGoogleService {

  constructor(private oauthService: OAuthService,
    private _router: Router
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // this.oauthService.setupAutomaticSilentRefresh();
  }

  initLogin() {
    // Inicia el flujo de inicio de sesión
    this.oauthService.initLoginFlow();
  }

  logout() {
    try {
      if (this.isTokenValid()) {
        // Revoca el token y cierra sesión
        this.oauthService.revokeTokenAndLogout();
      }
    } catch (e) { }
    this.clearLocalStorage();
    this._router.navigate(['/']);
  }

  clearLocalStorage() {
    // Limpia cualquier dato relacionado con la autenticación del almacenamiento local
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
  }

  async getToken() {
    // Obtiene el ID token actual
    return this.oauthService.getIdToken();
  }

  private isTokenValid(): boolean {
    const accessToken = this.oauthService.getAccessToken();
    return !!accessToken && this.oauthService.hasValidAccessToken();
  }

  // getProfile() {
  //   // Obtiene el perfil del usuario
  //   return this.oauthService.getIdentityClaims();
  // }

  // async getAccessToken(){
  //   // Obtiene el AccessToken actual
  //   return this.oauthService.getAccessToken();
  // }
}













// import { Injectable, OnDestroy } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
// import { filter } from 'rxjs/operators';

// const authConfig: AuthConfig = {
//   issuer: 'https://accounts.google.com',
//   strictDiscoveryDocumentValidation: false,
//   clientId: '68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com',
//   redirectUri: window.location.origin + '/public/verification',
//   postLogoutRedirectUri: window.location.origin,
//   //responseType: 'code', // Usar flujo de código
//   scope: 'openid profile email', // Incluye offline_access para obtener el refresh token
//   useSilentRefresh: true,
//   silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
//   requireHttps: true,
//   timeoutFactor: 0.02,
//   sessionChecksEnabled: true,
//   showDebugInformation: true,
//   // customQueryParams: {
//   //   //access_type: 'offline', // Para obtener un refresh token
//   //   prompt: 'consent', // Asegurar el refresh token
//   // },
// };

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGoogleService implements OnDestroy {
//   private tokenCheckInterval: any;
//   private tokenRefreshThreshold = 55 * 60 * 1000; // 5 minutos en milisegundos

//   constructor(private oauthService: OAuthService, private _router: Router) {
//     this.oauthService.configure(authConfig);
//     this.oauthService.setupAutomaticSilentRefresh();
//     this.oauthService.loadDiscoveryDocumentAndTryLogin();

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'token_received' || e.type === 'token_expires'))
//       .subscribe((event) => {
//         if (event.type === 'token_received') {
//           console.log('Token received');
//         } else if (event.type === 'token_expires') {
//           console.log('Token is about to expire');
//           this.handleTokenExpiration();
//         }
//       });

//     this.oauthService.events
//       .pipe(filter((e) => e.type === 'session_terminated' || e.type === 'session_error'))
//       .subscribe(() => {
//         console.log('Session terminated or error');
//         this.logout();
//       });

//     this.oauthService.events.subscribe((e) => {
//       if (e instanceof OAuthErrorEvent) {
//         console.error('OAuthErrorEvent:', e);

//         if (e.type === 'silent_refresh_timeout') {
//           console.warn('Silent refresh timed out.');
//           this.manualTokenRefresh();
//         }
//       }
//     });

//     //this.startTokenCheckInterval();
//   }

//   private startTokenCheckInterval() {
//     this.tokenCheckInterval = setInterval(() => {
//       this.checkTokenExpiration();
//     }, 60 * 1000); // Cada 60 segundos
//   }

//   private checkTokenExpiration() {
//     const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     const currentTime = new Date().getTime();
//     const timeRemaining = expirationTimestamp - currentTime;

//     if (timeRemaining <= this.tokenRefreshThreshold) {
//       console.log('Token is close to expiring, attempting silent refresh...');
//       this.handleTokenExpiration();
//     } else {
//       console.log('Token is still valid.');
//       this.printTokenExpiration();
//     }
//   }

//   private handleTokenExpiration() {
//     this.silentRefresh();
//   }

//   private silentRefresh() {
//     this.oauthService.silentRefresh()
//       .then(() => {
//         console.log('Silent refresh successful');
//       })
//       .catch((error) => {
//         if (error === 'interaction_required') {
//           console.warn('User interaction is required to refresh token.');
//           this.manualTokenRefresh();
//         } else {
//           console.error('Silent refresh failed:', error);
//           this.logout();
//         }
//       });
//   }

//   private manualTokenRefresh() {
//     console.log('Manual token refresh required.');
//     this.oauthService.initLoginFlow();
//   }

//   initLogin() {
//     this.oauthService.initLoginFlow();
//   }

//   logout() {
//     console.log("Cerrar sesión")
//     this.oauthService.revokeTokenAndLogout();
//     this.clearLocalStorage();
//     if (this.tokenCheckInterval) {
//       clearInterval(this.tokenCheckInterval);
//     }
//     this._router.navigate(['/']);
//   }

//   clearLocalStorage() {
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('id_token');
//     localStorage.removeItem('refresh_token');
//     sessionStorage.removeItem('access_token');
//     sessionStorage.removeItem('id_token');
//     sessionStorage.removeItem('refresh_token');
//   }

//   getProfile() {
//     return this.oauthService.getIdentityClaims();
//   }

//   async getToken() {
//     return this.oauthService.getIdToken();
//   }

//   async getAccessToken() {
//     return this.oauthService.getAccessToken();
//   }

//   async getRefreshToken() {
//     return this.oauthService.getRefreshToken()
//   }

//   isTokenValid(): boolean {
//     this.printTokenExpiration();
//     const accessToken = this.oauthService.getAccessToken();
//     return !!accessToken && this.oauthService.hasValidAccessToken();
//   }

//   printTokenExpiration() {
//     const expirationTimestamp = this.oauthService.getAccessTokenExpiration();
//     const expirationDate = new Date(expirationTimestamp);
//     console.log('Token expiration:', expirationDate.toLocaleString());
//     const currentTime = new Date();
//     const timeRemaining = expirationDate.getTime() - currentTime.getTime();

//     const seconds = Math.floor(timeRemaining / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);

//     console.log(`Token expires in ${hours} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`);
//   }

//   ngOnDestroy() {
//     if (this.tokenCheckInterval) {
//       clearInterval(this.tokenCheckInterval);
//     }
//   }
// }
