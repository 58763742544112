import { Countries } from "./countries";
import { Region } from "./region";

export class Departament {

	/*1*/ 	public id: number;
	/*2*/ 	public regionCode: number;
	/*3*/ 	public regionName: string;
	/*4*/ 	public countryID: number;
	/*5*/ 	public latitude: string;
	/*6*/ 	public longitude: string;
	public original_name_region: string

	public country?: Countries
	public region?: Region

	constructor(d ?: any) {
		this.id = d ? d.id : null;
		this.regionCode = d ? d.regionCode : null;
		this.regionName = d ? d.regionName : null;
		this.countryID = d ? d.countryID : null;
		this.latitude = d ? d.latitude : null;
		this.longitude = d ? d.longitude : null;
	}

}
