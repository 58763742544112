import { UserSkill } from './userSkill';

export class StaffSkill {

	/*1*/ 	public id: number;
	/*2*/ 	public staffSkillName: string;
	/*3*/ 	public staffSkillDescript: string;
	/*4*/ 	public roleMachineID: number;
	/*5*/ 	public userSkills: UserSkill[];

	/*6*/ 	public selectedAmount: number;
	/*7*/ 	public resourceID: number;
	/*8*/ 	public edit: boolean;
	/*9*/ 	public isAsigned?: boolean;

	constructor(s ?: any) {
		this.id = s ? s.id : null;
		this.staffSkillName = s ? s.staffSkillName : null;
		this.staffSkillDescript = s ? s.staffSkillDescript : null;
		this.roleMachineID = s ? s.roleMachineID : null;
		this.userSkills = s && s.userSkills ? new Array<UserSkill>(s.userSkills) : null;
		this.selectedAmount = 1;
		this.resourceID = 0;
	}
}
