import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.sass']
})
export class MultiselectComponent implements OnInit {

  @Output() returnList = new EventEmitter();

  @Input() data: { title: string, listItems: { id: number, value: string }[], listItemsSelects: { id: number, value: string }[] };

  public listItemsShow: { id: number, value: string }[] = [];
  public listItemsSelect: { id: number, value: string }[] = [];

  public textFilter: string = '';

  constructor() {
  }

  ngOnInit() {
    this.listItemsShow = this.data.listItems;
    this.listItemsSelect = this.data.listItemsSelects;
  }


/**************** Fucniones Propias ******************/

  verifySelectItem(id: number): boolean {
    for(let item of this.listItemsSelect) {
      if(item.id == id) return true;
    }
    return false;
  }

  selectItem( item: { id: number, value: string } ): void{
    let result: boolean = true;
    this.listItemsSelect.forEach(t => {
      if(item.id == t.id) result = false;
    });
    if(result) this.listItemsSelect.push(item);
    else this.removeItem(item.id);
    this.returnList.emit( {list: this.listItemsSelect} );
    // this.error.specialty = false;
  }

  removeItem(id: number): void{
    this.listItemsSelect.forEach((item, i) => {
      if(item.id == id){
        this.listItemsSelect.splice(i, 1);
        this.returnList.emit( {list: this.listItemsSelect} );
      }
    });
  }

  filterList(): void {
    let listFilter: any[] = this.data.listItems;
    const text: string = this.textFilter.trim();
    if(this.textFilter != ''){
      listFilter = listFilter.filter(
        item => item.text.indexOf(text) != -1 || item.text.toLocaleLowerCase().indexOf(text) != -1 || item.text.toLocaleUpperCase().indexOf(text) != -1
      );
    }
    this.listItemsShow = listFilter;
  }

}
