import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { SharedModule } from "./components/shared/shared.module";

// Componentes Propios
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { ChartsModule } from "ng2-charts";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { ToastrModule } from 'ngx-toastr';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule
} from 'ngx-ui-loader';
import { ApiInterceptor } from "./pages/interceptors/api.interceptor";

// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
// import {
//   GoogleLoginProvider,
//   FacebookLoginProvider
// } from '@abacritt/angularx-social-login';

import { OAuthModule } from 'angular-oauth2-oidc'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    ChartsModule,
    HttpClientModule,
    NgbModule,
    // ToastrModule.forRoot(), // ToastrModule added
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right', // Posición de los toasts
      preventDuplicates: true, // Evita duplicados
    }),
    NgxUiLoaderModule,
    //SocialLoginModule,
    OAuthModule.forRoot()
  ],
  providers: [
    DatePipe,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: ApiInterceptor, 
      multi: true 
    },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           '68528419432-mb4ovgbbfah7g5o46fmj3a5et1q26odv.apps.googleusercontent.com'
    //         )
    //       },
    //       // {
    //       //   id: FacebookLoginProvider.PROVIDER_ID,
    //       //   provider: new FacebookLoginProvider('clientId')
    //       // }
    //     ],
    //     onError: (err) => {
    //       console.error("Error key google",err);
    //     }
    //   } as SocialAuthServiceConfig,
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
