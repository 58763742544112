<ul class="loader">
  <li class="loader__center"></li>
  <li class="loader__item loader__item--1"></li>
  <li class="loader__item loader__item--2"></li>
  <li class="loader__item loader__item--3"></li>
  <li class="loader__item loader__item--4"></li>
  <li class="loader__item loader__item--5"></li>
  <li class="loader__item loader__item--6"></li>
  <li class="loader__item loader__item--7"></li>
  <li class="loader__item loader__item--8"></li>
</ul>