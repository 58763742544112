import { Machine } from './machine';
import { StateName } from './stateName';
export class TypeState {

	/*1*/ 	public id: number;
	/*2*/ 	public typeStateName: string;
	/*3*/ 	public colorState: string;
	/*4*/ 	public colorGroup?: number;
	/*5*/ 	public stateName?: StateName;
	/*6*/ 	public commandID?: number;
	/*7*/ 	public stateNames?: StateName;
	/*8*/ 	public machines?: Machine[];
	/*9*/ public order: number
	/*10*/ public typestateacronim: string;
	/*11*/ public view: boolean = true;

	public totalmachines?: number;
	constructor(s?: any) {
		this.id = s ? s.id : null;
		this.typeStateName = s ? s.typeStateName : null;
		this.colorState = s ? s.colorState : null;
		this.colorGroup = s ? s.colorGroup : null;
		this.stateName = s ? s.stateName : null;
		this.commandID = s ? s.commandID : null;
		this.order = s ? s.order : null;
		this.typestateacronim = s ? s.typestateacronim : null;
	}

}
