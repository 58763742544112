import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Command } from '../models/class/command';
import { RoleMachine } from '../models/class/roleMachine';
import { Machine } from '../models/class/machine';
import { take } from 'rxjs/operators';
import { Service } from '../models/class/service';
import { Departament } from '../models/class/departament';


@Injectable({
  providedIn: 'root'
})
export class CommandService {

  constructor(
    private _httpService: HttpService
  ) { }


  // Funciones Propias
  getCommandFilters(comandID: number,filter: string): Observable<Command> {
    const url: string = `Commands/${comandID}?filter=`  
    return this._httpService.httpGet(url + filter).pipe(take(1));
  }

  getRoleMachinesFilters(filter: string): Observable<RoleMachine[]> {
    const url: string = `RoleMachines?filter=`
    return this._httpService.httpGet(url + filter).pipe(take(1))
  }

  getAllMachines(comandID: number, filter: string): Observable<Machine[]> {
    const url: string = `Commands/${comandID}/Machines?filter=` 
    return this._httpService.httpGet(url + filter).pipe(take(1));
  }

  getAllServices(comandID: number, filter: string): Observable<Service[]> {
    const url: string = `Commands/${comandID}/MaintCmpActivities?filter=`
    return this._httpService.httpGet(url + filter).pipe(take(1));
  }

  getAllDepartament(): Observable<Departament[]> {
    const url: string = "Departaments";
    return this._httpService.httpGet(url).pipe(take(1));
  }

}
