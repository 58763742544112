import { Component, OnInit, Input } from '@angular/core';
import { ModalMessage, TypeMsm } from 'src/app/models/Interfaces/modalMessage';


// Services
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.sass']
})
export class MessageComponent implements OnInit {

  @Input() messageData: ModalMessage;

  TypeMsm = TypeMsm;
  constructor(
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
  }


  /*-------------------------------Fin de Funciones Propias-------------------*/

  cancel(): void {
    this._sharedService.changeDataModalMessage({ result: 'cancelled' });
  }
  continue(): void {
    this._sharedService.changeDataModalMessage({ result: 'confirmed' });
  }

}
