<div class="workOrders">
  <div class="workOrders__tittle" (click)="showOrder = !showOrder">
    <div>ORDEN DE TRABAJO: {{ thisMaintHistory[0]?.WorkPackage?.workPackageNumber }}
      <i class="if2rt-edit" (click)="goToEditWorkPackage()"></i>
    </div>
    <div class="workOrders__tittle__icon">
      <i class="if2rt-arrow"></i>
    </div>
  </div>
  <div class="workOrders__details" *ngIf="showOrder">
    <app-work-order [thisMaintHistory]="thisMaintHistory"></app-work-order>
    <div class="content-container-mttos" *ngFor="let item of thisMaintHistory">
      <div class="content-container-mttos-tittle">{{ item.maintCmpActivity.maintCmpActName }}</div>
      <div class="content-container-mttos-data">
        <div class="content-container-mttos-data-initial">
          <div class="content-container-mttos-data-initial-container">
            <span class="content-container-mttos-data-initial-container-tittle">CUMPLIDAS</span>
            <span class="content-container-mttos-data-initial-container-tittleInfo">{{ item.hoursUseStart | f2rtHour: 'decimals' }}</span>
          </div>
          <div class="content-container-mttos-data-initial-container">
            <span class="content-container-mttos-data-initial-container-tittle">VENCE</span>
            <span class="content-container-mttos-data-initial-container-tittleInfo">{{ item.hoursUseNext | f2rtHour: 'decimals' }}</span>
          </div>
        </div>
      </div>
      <app-completion-percentage [thisMaintHistory]="item"></app-completion-percentage>
    </div>
  </div>
</div>
