import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-modal',
  templateUrl: './header-modal.component.html',
  styleUrls: ['./header-modal.component.sass']
})
export class HeaderModalComponent implements OnInit {

  @Input() title: string;
  @Output() isClose = new EventEmitter();

  constructor(
  ) { }

  ngOnInit() {

  }

  emitClose() { // You can give any function name
    this.isClose.emit(true);
  }

/*------------------------------------- Funciones Propias -------------------------------------*/


}
