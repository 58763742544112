<ng-container *ngIf="loadingData" >
  <div class="loader-container">

    <app-loader class="loader"></app-loader>
  </div>
</ng-container>

<ng-container *ngIf="!loadingData">



<div class="info-service-container" *ngIf="maintHistories?.length>0">

  <div class="tab-selector">
    <div class="tab__item" (click)="itemSelected='hour'" [ngClass]="{'active': itemSelected == 'hour'?true:false}">Horas
    </div>
    <div class="tab__item" (click)="itemSelected='cycle'" [ngClass]="{'active': itemSelected == 'cycle'?true:false}">
      Ciclos </div>
    <div class="tab__item" (click)="itemSelected='landing'"
      [ngClass]="{'active': itemSelected == 'landing'?true:false}">Aterrizaje </div>
    <div class="tab__item" (click)="itemSelected='day'" [ngClass]="{'active': itemSelected == 'day'?true:false}">Días
    </div>
  </div>

  <div class="tab-content">
    <table>
      <thead>
        <tr>
          <th>VENCE</th>
          <th>CUMPLIDAS</th>
          <th>REMANENTE</th>
        </tr>
      </thead>
      <tbody *ngIf="itemSelected=='hour'">
        <tr *ngFor="let maintHistory of maintHistories">
          <td>{{
            maintHistory?.hoursUseStart * 1 + maintHistory?.maintCmpActivity?.frequencyHoursUse * 1 | number}}</td>
          <td>
            {{maintHistory?.hoursUseStart * 1 | number }}
          </td>
          <td   [ngClass]="{ 'text-danger': ( (maintHistory?.hoursUseStart * 1 + maintHistory?.maintCmpActivity?.frequencyHoursUse * 1 -maintHistory?.machine?.totalHoursUse * 1) < 10 ) }" >
            {{
            maintHistory?.hoursUseStart * 1 +
            maintHistory?.maintCmpActivity?.frequencyHoursUse * 1 -
            maintHistory?.machine?.totalHoursUse * 1 | number
            }}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="itemSelected=='cycle'">
        <tr *ngFor="let maintHistory of maintHistories">
          <td>{{
            maintHistory?.cycleUseStart * 1 + maintHistory?.maintCmpActivity?.frequencyCycleUse * 1 | number}}</td>
          <td>
            {{maintHistory?.cycleUseStart * 1 | number }}
          </td>
          <td>
            {{
            maintHistory?.cycleUseStart * 1 +
            maintHistory?.maintCmpActivity?.frequencyCycleUse * 1 -
            maintHistory?.machine?.totalCycleUse * 1 | number
            }}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="itemSelected=='day'">
        <tr *ngFor="let maintHistory of maintHistories">
          <td>{{
            maintHistory?.daysUseStart * 1 + maintHistory?.maintCmpActivity?.frequencyDaysUse * 1 | number}}</td>
          <td>
            {{maintHistory?.daysUseStart * 1 | number }}
          </td>
          <td>
            {{
            maintHistory?.daysUseStart * 1 +
            maintHistory?.maintCmpActivity?.frequencyDaysUse * 1 -
            maintHistory?.machine?.totalDayUse * 1 | number
            }}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="itemSelected=='landing'">
        <tr *ngFor="let maintHistory of maintHistories">
          <td>{{
            maintHistory?.landingUseStart * 1 + maintHistory?.maintCmpActivity?.frequencyLandingUse * 1 | number}}</td>
          <td>
            {{maintHistory?.landingUseStart * 1 | number }}
          </td>
          <td>
            {{
            maintHistory?.landingUseStart * 1 +
            maintHistory?.maintCmpActivity?.frequencyLandingUse * 1 -
            maintHistory?.machine?.totalLangingUse * 1 | number
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>


</div>


<div class="no-info-container" *ngIf="maintHistories?.length==0">
SIN INFORMACIÓN
</div>

</ng-container>