import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'urlSaniter'
})
export class UrlSaniter implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    value = value.replace('SafeValue must use [property]=binding:', '');
    if(value.toLocaleLowerCase().indexOf('watch?v=') != -1){
      value = value.replace('watch?v=', 'embed/');
    }else{
      value = this.replaceAll(value,'youtu.be', 'youtube.com/embed');
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

	replaceAll( text, busca, reemplaza ){
		while (text.toString().indexOf(busca) != -1)
		text = text.toString().replace(busca,reemplaza);
		return text;
	}

}
