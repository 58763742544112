import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class Time implements PipeTransform {

  constructor() {}

  transform(value: any, args?: any): any {
    let date: Date = new Date(value);
    value = `${date.getDate() + 1}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return value;
  }

}
