import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

// Services
import { HttpService } from './http.service';


// Models
import { MaintTask } from './../models/class/maintTask';
import { Service } from './../models/class/service';
import { Manual } from './../models/class/manual';
import { MaintResource } from './../models/class/maintResource';
import { SparePart } from './../models/class/sparePart';
import { Disposal } from './../models/class/disposal';
import { ToolType } from '../models/class/toolType';
import { StaffSkill } from './../models/class/staffSkill';
import { Infraestructure } from './../models/class/infrastructure';
import { TaskResource } from './../models/class/taskResource';
import { InventoryDisposal } from '../models/class/inventoryDisposal';
import { InventorySparePart } from '../models/class/inventorySparePart';
import { MaintTaskHistory } from '../models/class/maintTaskHistory';
import { UnitHistory } from '../models/class/unitHistory';
import { MaintHistory } from '../models/class/maintHistory';
import { MaintState } from '../models/class/maintState';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private newResource$ = new Subject<any>();

  constructor(
    private _httpService: HttpService
  ) { }


  /*********************************** Funciones de Emitter ************************************/
  getValueNewResource(): Observable<any> {
    return this.newResource$.asObservable();
  }

  emitterNewResource(value: any): void {
    this.newResource$.next(value);
  }

  /*********************************** Funciones Propias ***********************************/

  getMaintStates(filter?: string): Observable<MaintState[]> {
    let url: string = "maintStates";
    if (filter)
      url += `?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  get(filter?: string): Observable<Service[]> {
    let url: string;
    url = `MaintCmpActivities?filter=${filter == undefined ? "" : filter}`;
    return this._httpService.httpGet(url);
  }

  getAllServices(filter?: string): Observable<Service[]> {
    let url: string;
    if (filter)
      url = `MaintCmpActivities?filter=${filter}`;
    else
      url = 'MaintCmpActivities?filter={"include":["systemType", "ataType","maintResources"]}';
    return this._httpService.httpGet(url);
  }

  getAllServicesByFleetID(fleeID: number): Observable<Service[]> {
    let url: string = `Fleets/${fleeID}/maintCmpActivities?filter={   "include":["fleet","systemType", "ataType","maintResources"]}`;
    return this._httpService.httpGet(url);
  }
  getAllServicesBySystemAndAtaType(systemTypeID?: number, ataTypeID?: number): Observable<Service[]> {
    var filter = ``;
    if (systemTypeID != 0 && ataTypeID != 0)
      filter = `, "where":{"systemTypeID": ${systemTypeID} , "ataTypeID": ${ataTypeID} }`;
    else if (systemTypeID != 0 && ataTypeID == 0)
      filter = `, "where":{"systemTypeID": ${systemTypeID}  }`;
    else if (systemTypeID == 0 && ataTypeID != 0)
      filter = `, "where":{"ataTypeID": ${ataTypeID}  }`;
    let url: string = `MaintCmpActivities?filter={"include":["systemType", "ataType","maintResources"] ${filter}}`;
    return this._httpService.httpGet(url);
  }

  getDataServices(id: number): Observable<Service> {
    let url: string = `MaintCmpActivities/${id}`;
    return this._httpService.httpGet(url);
  }

  getResourceService(id: number): Observable<MaintResource[]> {
    let url: string = `MaintResources?filter={"where":{"maintCmpActivityID": ${id}}}`;
    return this._httpService.httpGet(url);
  }

  getResourceServicePoli(id: number): Observable<MaintResource[]> {
    let url: string = `MaintCmpActivities/${id}/maintResources?filter={"include":["resource"]}`;
    return this._httpService.httpGet(url);
  }

  // getResource(data: any): Observable<MaintResource>{
  //   let url: string = `MaintResources?filter={"where": {"typeResourceID": data.typeResourceID, "maintCmpActivityID": data.maintCmpActivityID, "resourceID": data.resourceID}}`;
  //   return this._httpService.httpGet(url);
  // }

  saveResourceService(data: any): Observable<MaintResource> {
    let url: string = `MaintResources`;
    return this._httpService.httpPost(url, data);
  }

  editResourceService(id: number, data: any): Observable<MaintResource> {
    let url: string = `MaintResources/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  getTaskResources(id?: number): Observable<TaskResource[]> {
    let url: string = `TaskResourses`;
    if (id) url += `?filter={"where":{"maintTaskID": ${id}}}`;
    return this._httpService.httpGet(url);
  }

  getMaintHistories(filter?: string): Observable<MaintHistory[]> {
    let url: string = `MaintHistories`;
    if (filter) url += `?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  saveService(data: any): Observable<Service> {
    let url: string = 'MaintCmpActivities';
    return this._httpService.httpPost(url, data);
  }

  getAllMaintTask(id: number): Observable<MaintTask[]> {
    let url: string = `MaintTasks?filter={"where": {"maintCmpActivityID": "${id}"}}`;
    return this._httpService.httpGet(url);
  }

  getMaintTask(filter: string): Observable<MaintTask[]> {
    let url: string = `MaintTasks`;
    if (filter) url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getMaintTaskHistories(filter: string): Observable<MaintTaskHistory[]> {
    let url: string = `maintTaskHistories`;
    if (filter) url += `?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getAllMaintResourcesByServiceIDAndTypeResorceID(MaintCmpActivityID: number, filter: string): Observable<MaintResource[]> {
    let url: string = `MaintCmpActivities/${MaintCmpActivityID}/maintResources?filter=${filter}`;
    return this._httpService.httpGet(url);
  }
  getInventoryDisposalByID(id: number, filter?: string): Observable<InventoryDisposal> {
    let url: string;
    if (filter) url = `InventoryDisposals/${id}?filter=${filter}`;
    else
      url = `InventoryDisposals/${id}`;
    return this._httpService.httpGet(url);
  }

  updateInventoryDisposalByID(id: number, data): Observable<InventoryDisposal> {
    let url: string = `InventoryDisposals/${id}`;
    return this._httpService.httpPatch(url, data);
  }
  deleteInventoryDisposalByID(id: number): Observable<InventoryDisposal> {
    let url: string = `InventoryDisposals/${id}`;
    return this._httpService.httpDelete(url);
  }
  updateInventorySparePartByID(id: number, data): Observable<InventorySparePart> {
    let url: string = `InventorySpareParts/${id}`;
    return this._httpService.httpPatch(url, data);
  }
  deleteInventorySparePartByID(id: number): Observable<InventorySparePart> {
    let url: string = `InventorySpareParts/${id}`;
    return this._httpService.httpDelete(url);
  }
  updateInventoryToolPartByID(id: number, data): Observable<InventorySparePart> {
    let url: string = `InventoryToolParts/${id}`;
    return this._httpService.httpPatch(url, data);
  }


  saveMaintTask(data: any): Observable<MaintTask> {
    let url: string = `MaintTasks`;
    return this._httpService.httpPost(url, data);
  }

  editMaintTask(id: number, data: any): Observable<MaintTask> {
    let url: string = `MaintTasks/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  getAllManual(filter?: string): Observable<Manual[]> {
    let url: string;
    if (filter) url = `Manuals?filter=${filter}`;
    else url = `Manuals`;
    return this._httpService.httpGet(url);
  }
  getManualByID(id: number, filter?: string): Observable<Manual> {
    let url: string;
    if (filter) url = `Manuals/${id}?filter=${filter}`;
    else url = `Manuals/${id}`;
    return this._httpService.httpGet(url);
  }
  deleteManualByID(id: number): Observable<Manual> {
    let url: string = `Manuals/${id}`;
    return this._httpService.httpDelete(url);
  }
  getAllManualCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `Manuals/count?where=${filter}`;
    else url = `Manuals/count`;
    return this._httpService.httpGet(url);
  }
  getBuildingsCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `/Buildings/count?where=${filter}`;
    else url = `Buildings/count`;
    return this._httpService.httpGet(url);
  }
  getInventoryDisposalsCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `InventoryDisposals/count?where=${filter}`;
    else url = `InventoryDisposals/count`;
    return this._httpService.httpGet(url);
  }
  getUsersCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `users/count?where=${filter}`;
    else url = `users/count`;
    return this._httpService.httpGet(url);
  }
  getInventorySparePartCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `InventorySpareParts/count?where=${filter}`;
    else url = `InventorySpareParts/count`;
    return this._httpService.httpGet(url);
  }
  getInventoryToolPartCount(filter?: string): Observable<any> {
    let url: string;
    if (filter) url = `InventoryToolParts/count?where=${filter}`;
    else url = `InventoryToolParts/count`;
    return this._httpService.httpGet(url);
  }



  getMaintStaffByID(idService: number): Observable<MaintResource[]> {
    // var idRecibido = 4;//Luis me lo debe pasar. TODO
    /* {"where": {"typeResourceID": "StaffSkill"}, "include": ["staffSkills","typeMeasure"]} */
    let url: string = `MaintCmpActivities/${idService}/maintResources?filter={"where": {"typeResourceID": "StaffSkill"},
    "include": ["typeMeasure",
    {"relation":"staffSkills", "scope": { "include": [
      {"relation":"userSkills", "scope": { "include": ["user"]}}

    ]}}
  ]
  }`;
    console.log('esta es la URL: ', url);
    return this._httpService.httpGet(url);
  }


  getAllDisposalOfMaintTask(id: number): Observable<Disposal[]> {
    let url: string = `MaintTasks/${id}/disposals`;
    return this._httpService.httpGet(url);
  }

  saveDisposal(data: any): Observable<Disposal> {
    let url: string = 'Disposals';
    return this._httpService.httpPost(url, data);
  }

  getAllManualOfMaintTask(id: number): Observable<Manual[]> {
    let url: string = `MaintTasks/${id}/manuals`;
    return this._httpService.httpGet(url);
  }

  saveManual(data: any): Observable<Manual> {
    let url: string = 'Manuals';
    return this._httpService.httpPost(url, data);
  }
  patchManual(data: any): Observable<Manual> {
    let url: string = 'Manuals';
    return this._httpService.httpPatch(url, data);
  }

  getAllInfraestructureOfMaintTask(id: number): Observable<Infraestructure[]> {
    let url: string = `MaintTasks/${id}/infraStructureTypes`;
    return this._httpService.httpGet(url);
  }

  saveInfraestructure(data: any): Observable<Infraestructure> {
    let url: string = 'InfrastructureTypes';
    return this._httpService.httpPost(url, data);
  }

  getAllSparePartOfMaintTask(id: number): Observable<SparePart[]> {
    let url: string = `MaintTasks/${id}/spareParts`;
    return this._httpService.httpGet(url);
  }

  saveSparePart(data: any): Observable<SparePart> {
    let url: string = 'SpareParts';
    return this._httpService.httpPost(url, data);
  }

  getAllStaffSkillOfMaintTask(id: number): Observable<StaffSkill[]> {
    let url: string = `MaintTasks/${id}/staffSkills`;
    return this._httpService.httpGet(url);
  }

  getAllStaffSkillsWithoutLeaderAndInspector(ids: number[]): Observable<StaffSkill[]> {
    let url: string = `staffSkills?filter={"where":{"id":{"nin":[3,6]}},"include":{"userSkills":{"relation":"user","scope":{"where":{"subUnitID":{"inq":[${ids}]}}}}}}`;
    return this._httpService.httpGet(url);
  }

  saveStaffSkill(data: any): Observable<StaffSkill> {
    let url: string = 'StaffSkills';
    return this._httpService.httpPost(url, data);
  }

  getAllToolTypeOfMaintTask(id: number): Observable<ToolType[]> {
    let url: string = `MaintTasks/${id}/toolTypes`;
    return this._httpService.httpGet(url);
  }

  saveToolType(data: any): Observable<ToolType> {
    let url: string = 'ToolTypes';
    return this._httpService.httpPost(url, data);
  }

  saveTaskResource(data: any): Observable<TaskResource[]> {
    let url: string = 'TaskResourses';
    return this._httpService.httpPost(url, data);
  }

  editResource(id: number, data: any): Observable<TaskResource> {
    let url: string = `TaskResourses/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  deleteResource(id: number): Observable<TaskResource> {
    let url: string = `TaskResourses/${id}`;
    return this._httpService.httpDelete(url);
  }

  uploadFile(file, url): Promise<{}> {
    return this._httpService.sendFile(file, url);
  }
  deleteFile(url): Promise<{}> {
    return this._httpService.deleteFile(url);
  }

}

