import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Models
import { License } from './../../../models/class/license';


@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.sass']
})
export class LicenseComponent implements OnInit {

  // Variables
  @Input() license: License;
  @Input() typeView?: number;
  public linkLicense: any;

  public stateModalPreview: boolean = false;

  constructor(
    private _sanitizer: DomSanitizer
  ) {

    if (this.typeView==null || this.typeView == undefined)
      this.typeView = 0;
  }

  ngOnInit() {


    this.getLink();



  }

  /*------------------------------------- Funciones Propias -------------------------------------*/

  changeStatePreview(type: boolean): void {
    //this.stateModalPreview = type;
  }

  closeModalPreview(e: any): void {
    if (e.target.className == 'center container-modal-preview activated') this.changeStatePreview(false);
  }

  getLink(): void {
    this.linkLicense = 'https://f2rt-avi.appspot.com/api/' + this.license.link + '?#scrollbar=0&toolbar=0&navpanes=0';
    //console.log(this.linkLicense.changingThisBreaksApplicationSecurity);
  }

}
