<div class="statusAnnotations">
  <div class="statusAnnotations__tittle">
    <i class="if2rt-alert-circle"></i>
    <span>OBSERVACIONES</span>
  </div>
  <div [ngClass]="{'changeStyleLast': changeStyle, 'statusAnnotations__container': changeStyleTableView}">
    <div class="statusAnnotations__stateObservation">
      <span>{{this.endUse?.comment | lowerCaseFirstCapital}}</span>
    </div>
    <div class="statusAnnotations__stateName">
      <small [ngStyle]="{'color': '#'+this.endUse?.typeState?.colorState}">Anotación en estado {{this.endUse?.typeState?.typeStateName}}</small>
    </div>
  </div>
  <div class="statusAnnotations__stateDate">
    <small>Fecha de Reporte {{ this._alert.date_without_UTC(this.endUse?.datereport,"DD/MM/yyyy") }}</small>
  </div>
</div>