import { Departament } from "./departament";


export class Airport {

	/*1*/ 	public id: number;
    /*2*/ 	public nameAirport: string;
    /*3*/   public IATA: string;
    /*4*/   public regionID: number;
    /*5*/   public typeAirportID: number;
    /*6*/   public localization: string;
    /*7*/   public latitude: string;
	/*8*/   public longitude: string;
	/*9*/   public OACI: string;

	public departament?: Departament;

	constructor(a ?:any) {
		this.id = a ? a.id : null;
		this.nameAirport = a ? a.nameAirport : null;
		this.IATA = a ? a.IATA : null;
		this.regionID = a ? a.regionID : null;
		this.typeAirportID = a ? a.typeAirportID : null;
		this.localization = a ? a.localization : null;
		this.latitude = a ? a.latitude : null;
		this.longitude = a ? a.longitude : null;
		this.OACI = a ? a.OACI : null;

	}

}
