<div class="hourUseCard">
  <div class="hourUseCard__header" [ngStyle]="{'background-color': thisMachine.typeState.colorState? '#'+ thisMachine.typeState.colorState : '#548DD4'  }">
    <i class="if2rt-back hourUseCard__header--icon"></i>
    <span>HISTORIAL DE VUELO</span>
  </div>
  <div class="hourUseCard__content">
    <div class="hourUseHistory">
      <div class="hourUseHistory__tittle">
        <div class="hourUseHistory__tittle__item">
          <i class="if2rt-clock"></i>
          <span>Hrs. voladas</span>
        </div>
        <div class="hourUseHistory__tittle__item">
          <i class="if2rt-update"></i>
          <span>Ciclos</span>
        </div>
        <div class="hourUseHistory__tittle__item">
          <i class="if2rt-landings"></i>
          <span>Landing</span>
        </div>
        <div class="hourUseHistory__tittle__item hourUseHistory__tittle__item--last">
          <i class="if2rt-date"></i>
          <span>Fecha</span>
        </div>
      </div>
      <div *ngFor="let item of thisMachine.useHistoryAugmenteds; let i = index">
        <div *ngIf="allDataToUpdate[i]['editData']; else noEditUseHistory" class="hourUseHistory__data">
          <div class="hourUseHistory__data__item">
            <input [(ngModel)]="allDataToUpdate[i]['hoursUse']" type="number">
          </div>
          <div class="hourUseHistory__data__item">
            <input [(ngModel)]="allDataToUpdate[i]['cycleUse']" type="number">
          </div>
          <div class="hourUseHistory__data__item">
            <input [(ngModel)]="allDataToUpdate[i]['cycleLandingUse']" type="number">
          </div>
          <div class="hourUseHistory__data__item hourUseHistory__data__item--last">
            <i class="if2rt-check-alone" (click)="updateUseHistoryData(i)"></i>
            <i class="if2rt-close" (click)="allDataToUpdate[i]['editData'] = !allDataToUpdate[i]['editData']"></i>
          </div>
        </div>
        <ng-template #noEditUseHistory>
          <div class="hourUseHistory__data">
            <div class="hourUseHistory__data__item">
              <span>{{ item.hoursUse ? item.hoursUse : '0' }}</span>
            </div>
            <div class="hourUseHistory__data__item">
              <span>{{ item.cycleUse ? item.cycleUse : '0' }}</span>
            </div>
            <div class="hourUseHistory__data__item">
              <span>{{ item.cycleLandingUse ? item.cycleLandingUse : '0' }}</span>
            </div>
            <div class="hourUseHistory__data__item hourUseHistory__data__item--date">
              <span>{{ item.dateReport | date:'mediumDate' }}</span>
            </div>
            <div class="hourUseHistory__data__item hourUseHistory__data__item--icons">
              <i class="if2rt-edit" (click)="allDataToUpdate[i]['editData'] = !allDataToUpdate[i]['editData']"></i>
              <i class="if2rt-delete" (click)="confirmDeleteUseHistory(i)"></i>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="newUseHistory">
      <div class="newUseHistory__row">
        <div class="newUseHistory__row__columnInput">
          <i class="if2rt-clock"></i>
          <span>Horas</span>
          <input [(ngModel)]="newUseHistory.hoursUse" type="number">
        </div>
        <div class="newUseHistory__row__columnInput">
          <i class="if2rt-update"></i>
          <span>Ciclos</span>
          <input [(ngModel)]="newUseHistory.cycleUse" type="number">
        </div>
        <div class="newUseHistory__row__columnInput">
          <i class="if2rt-landings"></i>
          <span>Landings</span>
          <input [(ngModel)]="newUseHistory.cycleLandingUse" type="number">
        </div>
      </div>
      <div class="newUseHistory__row">
        <div>
          <i class="if2rt-state"></i>
          <span>Estado</span>
        </div>
        <div class="newUseHistory__row__columnSelect">
          <select class="form-control" name="typeStateID" #typeStateID [(ngModel)]="newUseHistory.typeStateID" required>
            <option value=0 disabled> Estado</option>
            <option *ngFor="let typeState of typeStates" [ngValue]=typeState.id>{{typeState.typeStateName}}</option>
          </select>
        </div>
      </div>
      <div class="newUseHistory__row">
        <div>
          <i class="if2rt-unit"></i>
          <span>Unidad</span>
        </div>
        <div class="newUseHistory__row__columnSelect">
          <select class="form-control" name="unitID" #unitID [(ngModel)]="newUseHistory.unitID" required>
            <option value=0 disabled> Unidad</option>
            <option *ngFor="let unit of units" [ngValue]=unit.id>{{unit.unitName}}</option>
          </select>
        </div>
      </div>
      <div class="newUseHistory__row">
        <div>
          <i class="if2rt-location-card"></i>
          <span>Ubicación</span>
        </div>
        <div class="newUseHistory__row__columnSelect">
          <app-modal-pernocta  [currentPernocta]="{airport:thisMachine.airport, departament:thisMachine.departament}"
            (filterPerocta)="setFilterOptionPernocta($event)">
          </app-modal-pernocta>
        </div>
      </div>
      <div class="newUseHistory__row newUseHistory__row--obserbation">
        <div class="newUseHistory__row__uploadFile">
          <div>
            <i class="if2rt-alert-circle historyCard-header-icon historyCard-header-icon__spacing historyCard-header-icon__modify"></i>
            <span>Observaciones</span>
          </div>
          <div class="newUseHistory__row__uploadFile__btn">
            <a>
              <i class="if2rt-upload"></i>
              <input type="file" class="input_file" (change)="setFile($event)" accept="application/pdf">
            </a>
            <div class="newUseHistory__row__uploadFile__btn__fileName" *ngIf="aditionalFile.link != ''">
              <span>{{ aditionalFile.link }}</span>
            </div>
          </div>
        </div>
        <textarea [(ngModel)]="newUseHistory.comentReport" name="textArea" id="textArea" rows="3"></textarea>
      </div>
      <div class="newUseHistory__btn">
        <button class="btn" (click)="isFileToUpload()">Guardar</button>
      </div>
    </div>
  </div>
</div>
