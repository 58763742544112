import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Machine } from 'src/app/models/class/machine'

@Component({
  selector: 'app-green-card-machine',
  templateUrl: './green-card-machine.component.html',
  styleUrls: ['./../machine-info.component.sass', './green-card-machine.component.sass']
})
export class GreenCardMachineComponent implements OnInit {

  @Input() data: Machine;
  @Input() horizontalView: boolean = false;

  constructor(
    public sharedService: SharedService,
  ) {}

  ngOnInit() {
    console.log(this.data);
    
  }

}
