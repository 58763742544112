import { Component, OnInit, Input, AfterContentChecked, OnChanges, Output, EventEmitter } from '@angular/core';
import { Build } from 'src/app/models/class/build';
import { MachineService } from 'src/app/services/machine.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var Taggd: any;
@Component({
  selector: 'app-building-info',
  templateUrl: './building-info.component.html',
  styleUrls: ['./building-info.component.sass']
})
export class BuildingInfoComponent implements OnInit, AfterContentChecked, OnChanges {

  @Input() buildID: number;
  @Output() dataOutput = new EventEmitter();

  public building: Build;
  public loadedMaps: boolean = false;
  public showContent: boolean = false;
  public urlHttp: string = environment.baseUrl //environment.production ==0 ? environment.baseUrlProduction : environment.production ==1 ? environment.baseUrlTest : environment.baseUrlLocalhost;
  constructor(
    private _machineService: MachineService,
  ) { }

  ngOnInit() {
    this.extractTheData();

  }

  ngOnChanges() {
    this.loadedMaps = false;
    this.showContent=false;
    $(document).ready(function () {
      $('#label_avaliable').remove();
      $('#areasBuild_building').selectAreas('destroy');

    });
    this.ngOnInit();
  }
  ngAfterContentChecked() {
    if (this.loadedMaps == true) {
      $('#areasBuild_building').selectAreas({
        // onChanged: debugQtyAreas,
        width: 500,
        overlayOpacity: 0,
        allowEdit: false,
        allowMove: false,
        allowResize: false,
        allowSelect: false
      });

      this.loadedMaps = false;
    }


  }


  /*-------------------------------Incio de Funciones Propias-------------------*/
  extractTheData() {

    let filter = `{ "include": [
      {"relation":"buildSlots", "scope": { "order": "slotNumber ASC", "include": [
        {"relation":"machine", "scope": { "include": ["fleet"]}},
        {"relation":"buildInfrastructures", "scope": { "include": ["infrastructureType"]}}
       ]}
      }]} `;

    this._machineService.getBuildingByID(this.buildID, filter).pipe(take(1)).subscribe(data => {
      this.building = data;
      this.dataOutput.emit(data);
      this.showContent = true;
    });
    var cx = this;
    setTimeout(function () {
      console.log('hide');
      cx.loadAreas();
    }, 3000);

  }
  /*-------------------------------Fin de Funciones Propias-------------------*/



  loadAreas() {
    this.loadedMaps = true;
    var cx = this;
    $(document).ready(function () {

      cx.building.buildSlots.forEach(slot => {
        var dataSlot = JSON.parse(slot.areaMap);
        var image = document.getElementById('areasBuild_building');
        var width = $('#areasBuild_building').width();
        var height = $('#areasBuild_building').height();
        // console.log("imagen de dimension x " + width + " x " + height)
        // console.log("dimension tag x " + Number(dataSlot.x / width) + " x " + Number(dataSlot.y / height))
        var taggd = new Taggd(image);
        // taggd.clear();
        var text = 'Posición ' + Number(slot.slotNumber + 1);
        var attributes = {};
        if (slot.machineID) {
          attributes = {
            id: 'slot_selected',
            class: 'slot_selected'
          };
          text += ' Ocupado por ' + slot.machine.callSing;
          var d1 = document.getElementById('label_icons_building');
          d1.insertAdjacentHTML('afterbegin', `
          <div id="label_avaliable" style="position: relative;  z-index:3;">
          <div class="contenedor-imagen" style="opacity: 0.5;position: absolute;cursor: default;background-color: #FF7272;width: ${dataSlot.width}px;height: ${dataSlot.height}px;left: ${dataSlot.x}px;top: ${dataSlot.y}px;z-index: 5;font-size: 7vw; color: #F03737;">
          <div class="icon if2rt-${slot.machine.fleet.fleetIco}" style="position: absolute;cursor: default; left: 5%;top: 50%;z-index: 8;font-size: 5vw; color: #F03737;"></div>
          </div>
          </div>
          `);
        }
        taggd.setTags([
          new Taggd.Tag({
            x: (dataSlot.x / width) + 0.05,
            y: (dataSlot.y / height) + 0.05
          }, text, attributes)

        ]);
        var areaOptions = {
          x: dataSlot.x,
          y: dataSlot.y,
          width: dataSlot.width,
          height: dataSlot.height,
          id: slot.id
        };
        // console.log("Add a new area: ", areaOptions);
        $('#areasBuild_building').selectAreas('add', areaOptions);

      });
    });


  }



}
