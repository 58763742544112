import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs-compat';
import { Machine } from 'src/app/models/class/machine';
import { UnitHistory } from 'src/app/models/class/unitHistory';
import { UseHistoryAugmented } from 'src/app/models/class/useHistoryAugmented';
import { SortPipe } from 'src/app/pipes/sort.pipe';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from "moment";
import { ControllerUsed } from 'src/app/models/class/controllerUsed';
const today = new Date();

@Component({
  selector: 'app-new-card-hearder',
  templateUrl: './new-card-hearder.component.html',
  styleUrls: ['./new-card-hearder.component.sass']
})
export class NewCardHearderComponent implements OnInit, OnChanges {

  @Output() statusModalMaintenance: EventEmitter<number> = new EventEmitter<number>();
  @Output() showUseHistoryData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showInfoCards: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() infoMachine: EventEmitter<Machine> = new EventEmitter<Machine>();
  @Input() thisMachine: Machine;
  @Input() hideData: boolean = true;
  @Input() viewOnlyHeaders: boolean = false;
  @Input() typeView: number;

  private machines: Machine[] = [];
  private subscriptionMachines: Subscription;
  public pendingSave: boolean = false;
  public showUseHistory: boolean = false;
  private subscriptionOnHoverRemote: Subscription;
  private subscriptionCloseAllHistories: Subscription;
  public callSingOnHoverSelected: string = '';
  public styleOnRemoteHover: boolean = false;
  public totalHoursUsed: number = 0
  public multiDatas = []

  public dateSelectFilter: string[] = []

  public dateRange = {
    startDay: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    endDay: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
    firtDayI: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    finishDayF: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    dayNow: new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T"),
  };

  public endUse: ControllerUsed

  constructor(
    private _router: Router,
    public _sharedService: SharedService,
    private _sortPipe: SortPipe,
    private _alert: MensajesService
  ) { }

  ngOnInit(): void {
    

    // this.viewOnlyHeaders == true ? this.hideData = false : ''
    // this.subscriptionMachines = this._sharedService.sharedMultiDailyHist.subscribe(data => {
    //   if (data.machines) {
    //     this.machines = data.machines
    //   }
    // });
    // if (this.typeView == 6) {
    //   this.subscriptionOnHoverRemote = this._sharedService.sharedStyleByRemoteHover.subscribe(data => {
    //     this.callSingOnHoverSelected = data;
    //     if (this.thisMachine.callSing == this.callSingOnHoverSelected) {
    //       this.styleOnRemoteHover = true;
    //       this.hideData = false;
    //     } else this.hideData = true;
    //     this.showInfoCards.emit(this.hideData);
    //   });
    //   this.subscriptionCloseAllHistories = this._sharedService.sharedCloseAllHistories.subscribe(data => {
    //     this.closeAllHistories();
    //   });
    // }

    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.thisMachine){
      this.dateSelectFilter = (this._sharedService.getDateSelect() != undefined ? this._sharedService.getDateSelect().length > 0 ? this._sharedService.getDateSelect() : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")] : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")])

      let controlleruse = this.getUsedDay(this.thisMachine.controlleruse)
      this.endUse = controlleruse[0]

      this.multiDatas = this.sumHourUsed(controlleruse)
    }
  }

  ngOnDestroy() {
    // this.subscriptionMachines.unsubscribe();
    // if (this.typeView == 6) {
    //   this.subscriptionOnHoverRemote.unsubscribe();
    //   this.subscriptionCloseAllHistories.unsubscribe();
    // }
  }

  // redirectHelicopter(machineID: number) {
  //   this._router.navigate([`home/systems/${machineID}`]);
  // }

  geolocalizar(item: Machine) {
    this.infoMachine.emit(item)
  }

  activateModalMaintenance(machineID: number): void {
    this.statusModalMaintenance.emit(machineID);
  }

  showHideUseHistory(): void {
    this.showUseHistory = !this.showUseHistory;
    this.showUseHistoryData.emit(true);
  }
  // closeAllHistories(): void {
  //   if (this.showUseHistory) this.showHideUseHistory();
  // }
  showHideCards(item: Machine): void {
    this.hideData = !this.hideData;
    if (!this.hideData) {
      this.geolocalizar(item)
    }
    this.showInfoCards.emit(this.hideData);
  }
  // redirectHistory(machineID: number) {
  //   this._router.navigate([`home/historical/${machineID}`]);
  // }
  // checkCard(machineId): boolean {
  //   if (this.machines.find(x => x.id == machineId)) return true;
  //   else return false;
  // }
  // toggleChange() {
  //   this._sharedService.nextBSmultiCardsSelected(this.thisMachine)
  // }

  public isUpToDate(register: string) {
    const lastregister = new Date(register)
    if (today.getFullYear() == lastregister.getFullYear())
      if (today.getMonth() == lastregister.getMonth())
        if (today.getDate() == lastregister.getDate())
          return true;
    return false;
  }

  // sumHourAsigned(items: UnitHistory[]): number{
  //   let sum = 0
  //   items.forEach((element)=>{
  //     sum += Number(element.hoursAsigned)
  //     element?.unithistorychanges?.forEach((historyAdd)=>{
  //       sum +=  Number(historyAdd.hoursasigned);
  //     })
  //   })
  //   return sum;
  // }

  sumHourUsed(items: ControllerUsed[]) {
    let sum: number = 0
    items.forEach((element) => {
      if(element.controllerid == 1){ // 1 = control de horas de vuelos
        sum += Number(element.value)*1
      }
    })
    items = this.sortBy(items, 'desc', 'dateReport');
    return [sum, items.length > 0 ? items[items.length-1]?.subunit?.subUnitAcronim : ""];
  }

  getUsedDay(controlleruse: ControllerUsed[]) {
    let tempo_data = controlleruse.filter(item => {
      return this.compareDate(this._alert.date_without_UTC(item.datereport, "YYYY-MM-DD"), this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")) == -1
    })
    return tempo_data;
  }

  compareDate(dateTimeA: string, dateTimeB: string) {
    let value = 0 // A y B Es igual
    if (dateTimeA > dateTimeB) { // A es mayor
      value = 1
    }
    else if (dateTimeA <= dateTimeB) { // B es mayor o igual
      value = -1
    }
    return value;
  }

  // Función de ordenamiento de arreglos
  private sortBy(arrayData, orderType: 'asc' | 'desc', sortKey: string) {
    return this._sortPipe.transform(arrayData, orderType, sortKey);
  }
}
