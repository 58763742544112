import { Command } from "./command";
import { ImageType } from "./imagetype";
import { Machine } from "./machine";

export class AssetStatus {

	/*1*/ 	public id: number;
	/*2*/   public statusname: string;
	/*3*/   public acronimos: string;
	/*4*/   public statuscolor: string;
	/*5*/   public comandid: number;	

	public machines?: Array<Machine>;
	public command?: Command;

	constructor(a ?:any) {
		this.id = a ? a.id : null;
		this.statusname = a ? a.statusname : null;
		this.statuscolor = a ? a.statuscolor : null;
		this.comandid = a ? a.comandid : null;
		this.acronimos = a ? a.acronimos : null;

		this.command = (a && a.imagetype) ? new Command(a.command) : null;
		this.machines = (a && a.machines) ? new Array<Machine>(a.machines) : null;
	}

}
