import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Services
import { MachineService } from './../../../services/machine.service';
import { CircularMenuService } from './../../../services/circular-menu.service';

// Models
import { Maker } from './../../../models/class/maker';
import { Fleet } from './../../../models/class/fleet';
import { SystemType } from './../../../models/class/systemType';
import { Command } from 'src/app/models/class/command';
import { SharedService } from 'src/app/services/shared.service';
import { CommandService } from 'src/app/services/command.service';
import { RoleMachine } from 'src/app/models/class/roleMachine';

declare var $:any;

@Component({
  selector: 'app-circular-menu',
  templateUrl: './circular-menu.component.html',
  styleUrls: ['./circular-menu.component.sass']
})
export class CircularMenuComponent implements OnInit, OnDestroy {

// Variables
  public subcriptionCircularMenu: Subscription;

  public animationMenu: boolean = false;

  public itemSelect: number = 1;

  public textSelect: string = 'Fabricante';

  public optionsSelect: any[] = ['rotate(0deg) translateX(-150px);'];

  // public listAllMakers: Maker[] = [];
  // public listAllFleet: Fleet[] = [];

  public listMaker: Maker[] = [];
  public maker: any = null;
  private fleets: Fleet[] = [];
  public listFleet: Fleet[] = [];
  public fleet: any = null;
  public listSystem: SystemType[] = [];
  public system: any = null;

  public listStyles: any[] = [];
  private roleMachines: RoleMachine[] = [];

  public myCommand: Command;
  constructor(
    private _circularMenuService: CircularMenuService,
    private _commandService: CommandService,
    private _sharedService: SharedService,
  ) {
    this.myCommand  = this._sharedService.getCommand();
   }

  ngOnInit() {
    this.listenerEmitter();
    this.initData();
  }

  ngOnDestroy() {
    this.subcriptionCircularMenu.unsubscribe();
  }




/****************************** Funciones Listener **********************/
  listenerEmitter(): void{
    this.subcriptionCircularMenu = this._circularMenuService.getValueReceiverCircularMenu().subscribe( value => this.receiverActionCircularMenu(value) );
  }




/*********************************** Funciones Propias Emitter ************************************/
  initData(): void{
    setTimeout(() => this.animationMenu = true, 1000);
    
    const filterCommand = {
      include: [
        {
          "commandfleets":
          {
            "fleet":
              [ "manufacture", "roleMachine"]
          }
        }
      ]
    };

    this._commandService.getCommandFilters( this.myCommand.id, JSON.stringify(filterCommand)).subscribe(data => {

      data.commandfleets.forEach(c => {
        this.fleets.push(c.fleet);
        this.listMaker.push(c.fleet.manufacture);
        this.roleMachines.push(c.fleet.roleMachine);
      });

      this.listMaker = this.fleets.map(f => f.manufacture)
        .filter((value, index, self) => self.findIndex(e => e.id == value.id) === index);
      this.roleMachines = this.fleets.map(f => f.roleMachine)
        .filter((value, index, self) => self.findIndex(e => e.id == value.id) === index);

        this.fillDataListMaker();

      const filterMachine = {
        where:
          { "id": { inq: this.roleMachines.map(e => e.id) } },
        include:
          { "roleMachineSystems": { "systemType": { "ataTypes": "subAta" } } }
      };



      this._commandService.getRoleMachinesFilters(JSON.stringify(filterMachine)).subscribe(data => {


        const roleMachineSystems = data.map(f => f.roleMachineSystems).reduce((acc, val) => acc.concat(val), []);

        this.listSystem = roleMachineSystems.map(f => f.systemType)
          .filter((value, index, self) => self.findIndex(e => e.id == value.id) === index);


        let s: SystemType = new SystemType({id: null, systemName: 'Todos', systemNumber: 1, systemIco: 'https://storage.googleapis.com/f2rt-assets/Iconos/IcoTodos-vrd.svg'});
        this.listSystem.push(s);

      })
    });

  }

  emitterActionCircularMenu(data: any): void{
    this._circularMenuService.emitterActionCircularMenu(data);
  }


  selectItem(item: number): void{
    this.animationMenu = false;
    let value: any = this.getItem(item);
    let data = {
      source: 1,
      select: this.itemSelect,
      item: value
    };
    this.emitterActionCircularMenu(data);
    if(this.itemSelect == 1){
      this.getAllFleet(value.id);
    }else if(this.itemSelect == 2){
      this.itemSelect = 3;
      this.textSelect = 'Sistema';
      this.fillDataListSystem();
    }
  }



  getAllFleet(id: number): void{
    this.listFleet = this.fleets.filter(f => {
  return f.manufactureID == id
})
this.itemSelect = 2;
this.textSelect = 'Flota';
this.fillDataListFleet();
  }


  getItem(i: number): any{
    let value: any;
    switch (this.itemSelect) {
      case 1:
        value = this.getMaker(i);
        this.maker = i;
      break;
      case 2:
        value = this.getFleet(i);
        this.fleet = i;
      break;
      case 3:
        value = this.getSystem(i);
        this.system = i;
      break;
    }
    return value;
  }

  fillDataListMaker(): void{
    this.optionsSelect = [];
    this.listMaker.forEach(m => {
      this.optionsSelect.push(m.manufactureName);
    });
    this.setStyle();
  }

  getMaker(i: number): Maker{
    return this.listMaker[i];
  }

  fillDataListFleet(): void{
    this.optionsSelect = [];
    this.listFleet.forEach(f => {
      this.optionsSelect.push(f.fleetName);
    });
    this.setStyle();
  }

  getFleet(i: number): Fleet{
    return this.listFleet[i];
  }


  fillDataListSystem(): void{
    this.optionsSelect = [];
    this.listSystem.forEach(s => {
      this.optionsSelect.push({text: s.systemName, img: s.systemIco});
    });
    this.setStyle();
  }

  getSystem(i: number): SystemType{
    return this.listSystem[i];
  }


/***************************************** Funciones Receiver *******************************/
  receiverActionCircularMenu(value: any): void{
    switch (value) {
      case 1:
        this.resetMenu();
        setTimeout(() => this.animationMenu = true, 1000);
        this.fillDataListMaker();
      break;
      case 2:
        this.itemSelect = 1;
        this.selectItem(this.maker);
      break;
      case 3:
        this.itemSelect = 2;
        this.selectItem(this.fleet);
      break;
    }
  }

  resetMenu(): void{
    this.animationMenu = false;
    this.itemSelect = 1;
    this.textSelect = 'Fabricante';
    this.optionsSelect = [];
    this.maker = null;
    this.fleet = null;
    this.system = null;
  }


/************************************************************Funciones de menu **********************************/

  setStyle(): void{
    this.listStyles = [];
    for(var i = 0, l = this.optionsSelect.length; i < l; i++) {
      var left = (37 - 70*Math.cos(-0.5 * Math.PI - 2*(1/l)*i*Math.PI)).toFixed(4) + '%';
      var top = (37 + 70*Math.sin(-0.5 * Math.PI - 2*(1/l)*i*Math.PI)).toFixed(4) + '%';
      this.listStyles.push({left, top});
    }
    setTimeout(() => this.animationMenu = true, 1000);
  }

}
