import { Component, Input, OnInit } from '@angular/core';
import { element } from 'protractor';
import { Machine } from 'src/app/models/class/machine';
import { UnitHistory } from 'src/app/models/class/unitHistory';
import { UseHistoryAugmented } from 'src/app/models/class/useHistoryAugmented';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from "moment";

@Component({
  selector: 'app-control-flight-hours',
  templateUrl: './control-flight-hours.component.html',
  styleUrls: ['./control-flight-hours.component.sass']
})
export class ControlFlightHoursComponent implements OnInit {

  @Input() thisMachine: Machine;

  public dateSelectFilter: string[] = []

  public dateRange = {
    startDay: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    endDay: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
    firtDayI: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    finishDayF: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString(),
    dayNow: new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T"),
  };

  constructor(
    private _alert: MensajesService,
    private _sharedService: SharedService
  ) {}
  
  ngOnInit(): void {
    this.dateSelectFilter = (this._sharedService.getDateSelect() != undefined ? this._sharedService.getDateSelect().length > 0 ? this._sharedService.getDateSelect() : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")] : [this.dateRange.dayNow[0] + "T" + moment(new Date()).format("HH:mm:ss")])
    
    this.thisMachine.hoursAsignedMonth = this.sumHoursAsignedMonth(this.thisMachine.unitHistories)
    this.thisMachine.hoursFlewMonth = this.sumHoursUsedMonth(this.thisMachine.unitHistories,this.thisMachine.useHistoryAugmenteds) 
    this.thisMachine.hoursDays = this.sumHoursUsedDay(this.thisMachine.useHistoryAugmenteds)
  }

  sumHoursAsignedMonth(_unitHistories: UnitHistory[]) {
    let tempo_data = _unitHistories.filter(item => {
      return this._alert.date_without_UTC(item.dateStart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") && this._alert.date_without_UTC(item.dateEnd, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")
    })    
    let hours: number = 0
    tempo_data?.forEach((data) => {
      hours = Number(hours) + Number(data.hoursAsigned)
      data.unithistorychanges?.forEach((element) => {
        if (element.status != "Eliminado") {
          if (element.status == "Adición" &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")) {
            hours = Number(hours) + Number(element.hoursasigned);
          } else if (element.status == "Disminución" &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.datestart, "YYYY-MM-DD") >= this._alert.date_without_UTC(data.dateStart, "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") &&
            this._alert.date_without_UTC(element.dateend, "YYYY-MM-DD") <= this._alert.date_without_UTC(data.dateEnd, "YYYY-MM-DD")) {
            hours = Number(hours) + Number(element.hoursasigned);
          }
        }
      })
    })
    return hours;
  }

  sumHoursUsedMonth(_unitHistories: UnitHistory[], _useHistoryAugmenteds: UseHistoryAugmented[]) {
    let datas = _unitHistories.filter(item => {
      return this._alert.date_without_UTC(item.dateStart, "YYYY-MM-DD") <= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD") && this._alert.date_without_UTC(item.dateEnd, "YYYY-MM-DD") >= this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "YYYY-MM-DD")
    })
    
    let hours: number = 0
    datas.forEach(d => {
      let tempo_data = _useHistoryAugmenteds.filter(item => {
        return this._alert.date_without_UTC(d.dateStart, "YYYY-MM-DD") <= this._alert.date_without_UTC(item.dateReport, "YYYY-MM-DD") && this._alert.date_without_UTC(d.dateEnd, "YYYY-MM-DD") >= this._alert.date_without_UTC(item.dateReport, "YYYY-MM-DD")
      })
      tempo_data.forEach((item) => {
        hours += item.hoursUse * 1;
      });
    })

    if (datas.length == 0) {
      let tempo_data = _useHistoryAugmenteds.filter(item => {
        var firtDayI = new Date(new Date(this.dateSelectFilter[0]).getFullYear(), new Date(this.dateSelectFilter[0]).getMonth(), 1).toISOString()
        var finishDayF = new Date(new Date(this.dateSelectFilter[0]).getFullYear(), new Date(this.dateSelectFilter[0]).getMonth() + 1, 0).toISOString()
        return this._alert.date_with_UTC(new Date(item.dateReport), "YYYY-MM-DD") >= this._alert.date_without_UTC(new Date(firtDayI), "YYYY-MM-DD") && this._alert.date_without_UTC(item.dateReport, "YYYY-MM-DD") <= this._alert.date_without_UTC(new Date(finishDayF), "YYYY-MM-DD")
      })
      tempo_data.forEach((item) => {
        hours += item.hoursUse * 1;
      });
    }
    return hours;
  }

  sumHoursUsedDay(_useHistoryAugmenteds: UseHistoryAugmented[]) {
    let hours: number = 0
    let tempo_data = _useHistoryAugmenteds.filter(item => {
      return this.compareDate(this._alert.date_without_UTC(item.dateReport, "DD MMMM YYYY"), this._alert.date_with_UTC(new Date(this.dateSelectFilter[0]), "DD MMMM YYYY")) == 0
    })
    tempo_data.forEach((item) => {
      hours += item.hoursUse * 1;
    });
    return hours;
  }
  
  compareDate(dateTimeA: string, dateTimeB: string) {
    let value = 0 // A y B Es igual
    if (dateTimeA > dateTimeB) { // A es mayor
      value = 1
    }
    else if (dateTimeA < dateTimeB) { // B es mayor
      value = -1
    }
    return value;
  }

}
