<div class="workingOrder">
  <div class="workingOrder__title">
    <span>ORDEN DE TRABAJO: {{ thisMaintHistory[0]?.WorkPackage?.workPackageNumber }}</span>
    <ng-container *ngIf="thisMaintHistory[0]?.WorkPackage">
      <i class="if2rt-edit" (click)="goToEditWorkPackage()"></i>
    </ng-container>
  </div>
  <div class="workingOrder__userInfo">
    <div class="workingOrder__userInfo__icon">
      <i class="if2rt-user"></i>
    </div>
    <div class="workingOrder__userInfo__info">
      <div class="workingOrder__userInfo__info__row">
        <div class="workingOrder__userInfo__info__row__tittle">Jefe de grupo:</div>
        <div class="workingOrder__userInfo__info__row__descrip">{{ thisMaintHistory.length > 0 && thisMaintHistory[0].user ? thisMaintHistory[0].user?.lastNames +" "+ thisMaintHistory[0].user?.names : ''}}</div>
      </div>
      <div class="workingOrder__userInfo__info__row">
        <div class="workingOrder__userInfo__info__row__tittle">Cel:</div>
        <div class="workingOrder__userInfo__info__row__descrip">{{ thisMaintHistory.length > 0 && thisMaintHistory[0].user ? thisMaintHistory[0].user?.telephone : ''}}</div>
      </div>
      <div class="workingOrder__userInfo__info__row">
        <div class="workingOrder__userInfo__info__row__tittle">Correo:</div>
        <div class="workingOrder__userInfo__info__row__descrip">{{ thisMaintHistory.length > 0 && thisMaintHistory[0].user ? thisMaintHistory[0].user?.email : ''}}</div>
      </div>
    </div>
  </div>
</div>
<div class="phase-dates">
  <div class="phase-dates__title-container">
    <div class="left-icon-container">
      <i class="icon if2rt-date left-icon"></i>
    </div>
    <div class="phase-dates__title">FECHAS DE FASE</div>
  </div>
  <div class="phase-dates__boxes">
    <div class="phase-dates__box">
      <div class="phase-dates__box-info">{{ fixDate(thisMaintHistory[0]?.dateInit) | date }}</div>
      <div class="phase-dates__box-title">Inicio</div>
    </div>
    <div class="phase-dates__box phase-dates__box--days">
      <div class="phase-dates__box-info">{{ fromDateToNow(thisMaintHistory[0]?.dateInit)}}</div>
      <div class="phase-dates__box-title">Días</div>
    </div>
    <div class="phase-dates__box">
      <div class="phase-dates__box-info">{{fixDate( thisMaintHistory[0]?.dateFinish )| date }}</div>
      <div class="phase-dates__box-title">Final</div>
    </div>
  </div>
</div>
