<!-- <nav class='menu'>
  <input checked='checked' class='menu-toggler' id='menu-toggler' [checked]="animationMenu" type='checkbox'>
  <div class='center circle_center'>
    <span class='text-select'>{{textSelect}}</span>
  </div>  
  <ul>
    <li class='menu-item' *ngFor='let item of optionsSelect; index as i' [ngStyle]="{'transform': listStyles[i]}">
      <a class='center' *ngIf="itemSelect != 3"  (click)="selectItem(i)">{{item}}</a>
      <img *ngIf="itemSelect == 3" src="{{item.img}}" class="img-system">
      <a *ngIf="itemSelect == 3"  (click)="selectItem(i)"> <span class="description-system">{{item.text}}</span></a>
    </li>
  </ul>
</nav>  -->

<nav class="circular-menu" *ngIf="listStyles.length > 0">

  <div class="circle" [ngClass]="{'open': animationMenu}">
    <div class="item_menu center" *ngFor='let item of optionsSelect; index as i' [ngStyle]="{'left': listStyles[i].left, 'top': listStyles[i].top}" (click)="selectItem(i)">
      <span class="text" *ngIf="itemSelect != 3">{{item}}</span>

      <i *ngIf="itemSelect == 3" [class]="'if2rt-' + item.img + ' img-system'"></i>
      <span class="description" *ngIf="itemSelect == 3">{{item.text}}</span>
    </div>
  </div>
  
  <a class="center menu-button">{{textSelect}}</a>

</nav>
