<div class="item__machine">

        <div class="info-machine-service">
          <div class="info-machine">
  
            <div class="center">MATRICULA Y FLOTA  </div>
            <div class="center"><b>{{data.callSing}}</b> | {{data.fleet.fleetName}}</div>
            <div class="space"></div>
            <!-- <div><small> Hrs. disponibles mes </small> {{(data?.unitHistories[0]?.hoursStart +data?.unitHistories[0]?.hoursAsigned -
            data?.currentHoursUse).toFixed(2)}}</div> -->
            <div class="time-resume">
              <div class="time-resume-text">
                <small> Hrs. disponibles mes </small> {{  (data?.unitHistories[0]?.hoursEnd - data.totalHoursUse).toFixed(2)   }}
              </div>
              <div class="time-resume-text">
                <small> Hrs. Actuales </small> {{data?.totalHoursUse}}
              </div>
            </div>
  
          </div>
          <!-- <div class="v-space"></div> -->
        
        </div>
        <div class="details-pernocta">
          <div class="icon-resource" [ngStyle]="{'border': data.typeState.stateName.colorState? '1px solid #'+ data.typeState.stateName.colorState : '1px solid #548DD4'  }">
            <i class="icon if2rt-{{data.fleet.fleetIco}} icon-percnota " placement="bottom" [ngStyle]="{'color': data.typeState.stateName.colorState? '#'+ data.typeState.stateName.colorState : '#548DD4'  }"></i>
          </div>
          <div class="info">
            <div class="text">Ubicación</div>
            <div class="airport">
              {{data.airport?  data.airport?.nameAirport : data.departament.regionName }}
            
            </div>
            <div class="state">
              <div class="title"> Estado </div>
              <div class="states font-weight-normal">
                {{data?.typeState?.typeStateName}}
              </div>
              <div  class="title" > Hrs. Voladas hoy </div>
              <div class="hours font-weight-normal">
                {{sharedService.toNumberFixed(data.useHistoryAugmenteds[0].hoursUse)}}
              </div>
            </div>
  
          </div>
        </div>
       
        <div class="info-cycles">
          <div class="data-cycles">
            <div class="data">
              <div class="text font-weight-bold">Encendido/Apagado</div>
              <div class="hours">
                {{data?.useHistoryAugmenteds[0]?.cycleUse}}
              </div>
            </div>
            <div class="data">
              <div class="text font-weight-bold">Aterrizajes</div>
              <div class="hours">
                {{data?.useHistoryAugmenteds[0]?.cycleLandingUse ?  data?.useHistoryAugmenteds[0]?.cycleLandingUse : '0' }}
              </div>
            </div>
  
          </div>
        </div>
        <div class="observations"  [ngStyle]="{'background-color': data.typeState.stateName.colorState? '#'+ data.typeState.stateName.colorState+'33' : '#548DD433'  }" >
         
          <div class="services-content">
            <div class="item__service">
              <div class="name"></div>
              <div class="left-hours font-weight-bold">FALTAN </div>
              <div class="last-inspection font-weight-bold">ÚLTIMA INSPECCIÓN</div>
            </div>
            <div class="item__service" *ngFor="let maintHistory of data?.maintHistories">
              <div class="name">{{maintHistory.maintCmpActivity.maintCmpActName}}</div>
              <div class="left-hours ">{{( (maintHistory.maintCmpActivity.frequencyHoursUse * 1) - data.totalhoursuse).toFixed(2)}} hrs </div>
              <div class="last-inspection ">{{maintHistory.dateInit | date :'mediumDate'}}</div>
            </div>
          </div>
          <div class="v-space"></div>
          
          <div class="observation-text" >

            {{data?.descript}}
          </div>

       
      </div>
       
      

</div>