import { User } from 'src/app/models/class/user';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { HttpService } from './http.service';


// Clases
import { Infraestructure } from './../models/class/infrastructure';
import { Build } from './../models/class/build';
import { MaintHistory } from '../models/class/maintHistory';
import { MaintResourceHistory } from '../models/class/maintResourceHistory';
import { FailReport } from '../models/class/failReport';
import { WorkPackage } from '../models/class/workPackage';


@Injectable({
  providedIn: 'root'
})
export class MaintHistoryService {

  constructor(
    private _httpService: HttpService
  ) { }

  postWorkPackage(data): Observable<WorkPackage> {
    let url: string = `WorkPackages`;
    return this._httpService.httpPost(url, data);
  }

  patchWorkPackage(id: number, data: object): Observable<WorkPackage> {
    let url: string = `WorkPackages/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  // Funciones Propias

  postMaintHistory(data): Observable<MaintHistory> {
    let url: string = `MaintHistories`;
    return this._httpService.httpPost(url, data);
  }

  patchMaintHistory(id: number, data: object): Observable<MaintHistory> {
    let url: string = `MaintHistories/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  postMaintResourceHistory(data): Observable<MaintResourceHistory> {
    let url: string = `MaintResourceHistories`;
    return this._httpService.httpPost(url, data);
  }

  deleteMaintResourceHistoryByID(id: number): Observable<MaintResourceHistory> {
    let url: string = `MaintResourceHistories/${id}`;
    return this._httpService.httpDelete(url);
  }



  getMaintHistoryById(maintHistoryId: number, filter?: string): Observable<MaintHistory> {
    let url = `MaintHistories/${maintHistoryId}`
    filter ? url += `?filter=${filter}` : ''
    return this._httpService.httpGet(url);
  }

  getWorkPackageV2ById(workPackageID: number): Observable<WorkPackage> {
    let filter = `{"include":[
      {
        "relation":"MaintHistories",
        "scope":{
          "include":[
            {"maintResourceHistories":["users",{"maintResource":"staffSkills"}]},
            "buildings",
            {
              "maintCmpActivity":"ServiceType"
            },
            {
              "relation":"machine",
              "scope": {"include":[
                "typeState",
                "dailyUseHistories",
                "fleet",
                "unit",
                "airport",
                "departament",
                {
                  "relation":"buildSlots",
                  "scope":{
                    "include":[
                      "building"
                    ]
                  }
                }
              ]}
            }
          ]
        }
      },
      {
        "relation":"machine",
        "scope": {"include":[
          "typeState",
          "dailyUseHistories",
          "fleet",
          "unit",
          "airport",
          "departament",
          {
            "relation":"buildSlots",
            "scope":{
              "include":[
                "building"
              ]
            }
          }
        ]}
      },
      "user",
      "inspector"
    ]}`;
    let url = `WorkPackages/${workPackageID}?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getWorkPackageById(workPackageID: number): Observable<WorkPackage> {
    let filter = `{"include":[{
        "relation":"MaintHistories",
        "scope":{
          "include":[
            "maintCmpActivity",
            


            {
              "relation":"machine",
              "scope": {"include":[
                "typeState",
                "dailyUseHistories",
                "fleet",
                "unit",
                "airport",
                "departament",
                {
                  "relation":"buildSlots",
                  "scope":{
                    "include":[
                      "building"
                    ]
                  }
                }
              ]}
            }



          ]
        }
      }
    ]}`;
    let url = `WorkPackages/${workPackageID}?filter=${filter}`;
    return this._httpService.httpGet(url);
  }
  maintCmpActivity

  getMaintHistories2(filter?: string): Observable<MaintHistory[]> {
    let url: string = `MaintHistories`
    filter ? url += `?filter=${filter}` : '';
    return this._httpService.httpGet(url);
  }

  getMaintHistories(machineID?: number): Observable<MaintHistory[]> {
    let url: string = machineID ? `MaintHistories?filter={"where":{"machineID": ${machineID}},"include":[
        {"relation":  "machine", "scope": { "include": ["typeState", "fleet", "unit", "airport","departament",
            {"relation":  "buildSlots", "scope": { "include": ["building"]}}
            ]}},
        "maintCmpActivity",
        "WorkPackage",
        "user",
        "inspector",
        "buildings",
        {"relation": "failReports", "scope": {   "include": [ "failReportTasks"] }},
        {"relation": "maintResourceHistories", "scope": { "where" : {"typeRealResource":"user"},  "include": [                                      
                                                                  "users"
                                                                ]
                                                    }
        },
        {"relation": "maintTaskHistories", "scope": {"include": [
                                                                  {"relation": "maintTask", "scope": { "include": ["zoneMachine"]}},
                                                                  "userExec",
                                                                  "userTest"
                                                                ]
                                                    }
        }
      ]}`
      : `MaintHistories`;
    return this._httpService.httpGet(url);
  }

  getLastMaintHistoryByMachineIDandServiceID(machineID: number, maintCmpActivityID: number, limit: number): Observable<MaintHistory[]> {
    let url: string = `MaintHistories?filter={"where":{"machineID": ${machineID} , "maintCmpActivityID": ${maintCmpActivityID} } , "order": "id DESC" , "limit":${limit} } `;
    return this._httpService.httpGet(url);
  }

  getLastMaintHistoryByComponentIDandServiceID(componentID: number, maintCmpActivityID: number, limit: number): Observable<MaintHistory[]> {
    let url: string = `MaintHistories?filter={"where":{"componentID": ${componentID} , "maintCmpActivityID": ${maintCmpActivityID} } , "order": "id DESC" , "limit":${limit} } `;
    return this._httpService.httpGet(url);
  }

  getUsersOfMaintTask(id: number): Observable<User[]> {
    let url: string = `MaintHistories/${id}/users`;
    return this._httpService.httpGet(url);
  }

  releaseResourcesByMaintHistID(mainthistoryID: number, data): Observable<any> {
    let url: string = `MaintHistories/${mainthistoryID}/releaseResources`;
    return this._httpService.httpPost(url, data);
  }

  getFailReports(machineID: number): Observable<FailReport[]> {
    let url: string = `FailReports?filter={"where":{"machineID":${machineID}},"include":["failReportTasks","userReported",
    "typeFail","failState", "ataType", "systemType", "typeMel",
    {"relation": "failReportCommands", "scope": {"include":["failCommandField"] }} 
  
  ]}`;
    return this._httpService.httpGet(url);
  }

  getSecuenceWPs(unitID: number): Observable<any> {
    let url = `secuencewps?filter={"where":{"unitID":${unitID}}}`;
    return this._httpService.httpGet(url);
  }

}
