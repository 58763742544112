import { StaffSkill } from './staffSkill';
import { Service } from './service';
//import { Component } from '@angular/compiler/src/core';
import { Machine } from './machine';
import { Unit } from './unit';
import { User } from './user';
import { SubUnit } from './subUnit';
import { MaintState } from './maintState';
import { MaintChat } from './maintChat';
import { MaintTaskHistory } from './maintTaskHistory';
import { MaintResourceHistory } from './maintResourceHistory';
import { InventoryDisposal } from './inventoryDisposal';
import { Manual } from './manual';
import { Build } from './build';
import { InventorySparePart } from './inventorySparePart';
import { InventoryToolPart } from './inventoryToolPart';
import { FailReport } from './failReport';
import { WorkPackage } from './workPackage';
import { Component } from '@angular/core';

export class MaintHistory {

	/*1*/   public id: number;
	/*2*/   public machineID: number;
	/*3*/   public componentID: number;
	/*4*/   public maintCmpActivityID: number;
	/*5*/   public dateInit: Date;
	/*6*/   public dateFinish:Date;
	/*6*/   public dateNext:Date;
	/*7*/   public hoursUseStart:number;
	/*8*/   public hoursUseNext:number;
	/*9*/   public cycleUseStart: number;
	/*10*/  public cycleUseNext: number;
	/*9*/   public landingUseStart: number;
	/*10*/  public landingUseNext: number;
	/*11*/  public maintDescript:string;
	/*12*/  public unitID:number;
	/*13*/  public subUnitID:number;
	/*14*/  public userID:number;
	/*15*/  public approval:boolean;
	/*16*/  public maintStateID:number;
	/*17*/  public progress:number;
	/*39*/  public progressTime:number;
	/*18*/  public inspectorID: number;


	/*19*/  public inspector: User;
	/*20*/  public maintCmpActivity: Service;
	/*21*/  public component: Component;
	/*22*/  public machine: Machine;
	/*23*/  public subUnit: SubUnit;
	/*24*/  public unit: Unit;
	/*25*/  public user: User;
	/*26*/  public maintState: MaintState;
	/*27*/  public maintChats: Array <MaintChat>;
	/*28*/  public maintTaskHistories: MaintTaskHistory[];
	/*29*/  public maintResourceHistories:Array<MaintResourceHistory>;
	/*30*/  public inventoryDisposals:Array<InventoryDisposal>;
	/*31*/  public manuals:Array<Manual>;
	/*32*/  public buildings:Array<Build>;
	/*33*/  public buildID: number;
	/*34*/  public users:Array<User>;
	/*35*/  public inventorySpareParts:Array<InventorySparePart>;
	/*36*/  public inventoryToolParts:Array<InventoryToolPart>;
	/*37*/  public staffSkillID:number;
		/*37*/  public workpackageID?:number;
		public daysUseStart?: number;
	public workPackageNumber?: string;
	/*38*/  public showDetails: boolean;

	public failReports?: FailReport[];
	public WorkPackage?: WorkPackage;
	

	constructor(m?: any) {
		this.id = m ? m.id : 0;
		this.maintCmpActivityID = m ? m.maintCmpActivityID : null;
		this.machineID = m ? m.machineID : null;
		this.componentID = m ? m.componentID : null;
		this.dateInit = m ? m.dateInit : null;
		this.dateFinish= m? m.dateFinish: null;
		this.userID = m ? m.userID : null;
		this.maintDescript = m ? m.maintDescript : null;
		this.approval = m ? m.approval : null;
		this.hoursUseNext = m ? m.hoursUseNext : null;
		this.hoursUseStart = m ? m.hoursUseStart : null;
		this.machine = m ? m.machine : null;
		this.maintStateID = m ? m.maintStateID : null;
		this.progress = m ? m.progress : 0;
		this.progressTime = m ? m.progressTime : 0;
		this.subUnitID = m ? m.subUnitID : null;
		this.unitID = m ? m.unitID : null;
		this.staffSkillID = m ? m.StaffSkill : null;
		this.cycleUseStart= m ? m.cycleUseStart :null;
		this.cycleUseNext= m ? m.cycleUseNext : null;
		this.buildID = m ? m.buildID : null;

		// this.inspector = m.inspector ? m.inspector : null;
		 this.maintTaskHistories = m ? m.maintTaskHistories : [];
		// this.user = m.user ? m.user : null;
	}

}
