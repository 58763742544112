<div class="statusAnnotations">
  <div class="statusAnnotations__tittle">
    <i class="if2rt-alert-circle"></i>
    <span>OBSERVACIONES</span>
  </div>
  <div [ngClass]="{'changeStyleLast': changeStyle, 'statusAnnotations__container': changeStyleTableView}">
    <div class="statusAnnotations__stateObservation">
      <span>{{this.endUse?.comentReport | lowerCaseFirstCapital}}</span>
    </div>
    <div class="statusAnnotations__stateName">
      <small [ngStyle]="{'color': '#'+this.endUse?.typeState?.colorState}">Anotación en estado {{this.endUse?.typeState?.typeStateName}}</small>
    </div>
    <div class="statusAnnotations__stateDate">
      <small>Creado el {{this.endUse?.dateReport | date: 'short'}}</small>
    </div>
  </div>
</div>